import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/metas/:label',
        createOrUpdate: '/metas/:label',
        delete: '/meta/:metaId',
    }

    /**
     * Get list of categories match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(label) {
        try {
            const response = await axios(this.replaceParams(this.paths.list, { label }));
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing meta
     *  
     * @return Promise
     */
    async createOrUpdate(label, data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.replaceParams(this.paths.createOrUpdate, { label }),
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing meta from db
     *  
     * @return Promise
     */
    async delete(metaId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { metaId })
            });
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;