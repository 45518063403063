/* eslint-disable react-hooks/rules-of-hooks */
import AddTransactionIcon from "@mui/icons-material/AddCardOutlined";
import SettleIcon from "@mui/icons-material/PaymentsOutlined";
import InvoiceSettleConfirmation from "../components/InvoiceSettleConfirmation";
import ClientTransactionsTable from "../pages/PersonDetails/TransactionsTable";
import InvoiceTransactionsTable from "../pages/InvoiceDetails/InvoiceTransactions";
import { ClientDetailsFooter, ClientDetailsOverview } from "./PersonDetails";
import { InvoiceTransactionsOverview } from "./InvoiceDetails";

import PaymentsOutlined from "@mui/icons-material/PaymentsOutlined";
import useDialogProps from "../../../utils/useDialogProps";
import ContractPaymentFormDialog from "../components/ContractPaymentFormDialog";
import ContractSettleConfirmation from "../components/ContractSettleConfirmation";
import ContractTransactionsTable from "../pages/ContractDetails/ContractTransactions";
import { ContractTransactionsOverview } from "./ContractDetails";


const hooks = {
    'People': {
        'routes.people.details': () => {
            return {
                variant: "dashboard",
                private: true,
                path: 'transactions',
                element: <ClientTransactionsTable />
            }
        },
        'PersonDetails.TabsList': ({ params }) => {
            return {
                label: 'رویدادهای مالی',
                value: `/people/${params.personId}/transactions`,
                icon: <PaymentsOutlined />
                // priority: 50,
            }
        },
        'PersonDetails.Overview.Grid': injectedParams => <ClientDetailsOverview {...injectedParams} />,
        'PersonDetails.Hooks': injectedParams => ({
            'transactionFormHook': useDialogProps('add-transaction'),
            'settleFormHook': useDialogProps('settle'),
        }),
        'PersonDetails.ActionsList': ({ client, transactionFormHook, settleFormHook }) => {

            const [transactionFormDialogProps, openTransactionFormDialog, closeTransactionformDialog] = transactionFormHook;
            const [settleFormDialogProps, openSettleFormDialog, closeSettleformDialog] = settleFormHook;


            return [{
                title: 'تراکنش جدید',
                icon: <AddTransactionIcon fontSize="inherit" />,
                onClick: () => openTransactionFormDialog(),
                buttonProps: {
                    variant: "contained"
                },
                priority: 110
            }, {
                title: 'تسویه حساب',
                icon: <SettleIcon fontSize="inherit" />,
                onClick: () => openSettleFormDialog(),
                buttonProps: {
                    variant: "contained"
                },
                priority: 120
            }]
        },
        'PersonDetails.Footer': injectedParams => <ClientDetailsFooter {...injectedParams} />,
    },
    'Invoices': {
        'routes.invoices.details': () => {
            return {
                variant: "dashboard",
                private: true,
                path: 'transactions',
                element: <InvoiceTransactionsTable />
            }
        },
        'InvoiceDetails.TabsList': ({ contractId }) => {
            return { label: 'رویدادهای مالی', value: `/invoice/${contractId}/transactions`, className: 'invoice-transactions' }
        },
        'InvoiceDetails.State': () => {
            return {
                settleDialogOpen: false,
                paymentDialogOpen: false
            }
        },
        'InvoiceDetails.PostSide': (props) => <InvoiceTransactionsOverview {...props} />,
        'InvoiceDetails.PostFooter': ({ invoice, state, setState, refetch }) => {
            return (
                <>

                    <InvoiceSettleConfirmation
                        open={state.settleDialogOpen}
                        onClose={() => setState({ ...state, settleDialogOpen: false })}
                        invoice={invoice}
                        onSettle={invoice => refetch()}
                    />
                </>
            )
        }
    },
    'Contract': {
        'routes.contracts.details': () => {
            return {
                variant: "dashboard",
                private: true,
                path: 'transactions',
                element: <ContractTransactionsTable />
            }
        },
        'ContractDetails.TabsList': ({ contractId }) => {
            return { label: 'رویدادهای مالی', value: `/contract/${contractId}/transactions`, className: 'invoice-transactions' }
        },
        'ContractDetails.State': () => {
            return {
                settleDialogOpen: false,
                paymentDialogOpen: false
            }
        },
        'ContractDetails.PostSide': (props) => <ContractTransactionsOverview {...props} />,
        'ContractDetails.PostFooter': ({ contract, state, setState, refetch }) => {
            return (
                <>
                    <ContractPaymentFormDialog
                        open={state.paymentDialogOpen}
                        onClose={() => setState({ ...state, paymentDialogOpen: false })}
                        onSubmit={() => refetch()}
                        contract={contract}
                    />
                    <ContractSettleConfirmation
                        open={state.settleDialogOpen}
                        onClose={() => setState({ ...state, settleDialogOpen: false })}
                        contract={contract}
                        onSettle={contract => refetch()}
                    />
                </>
            )
        }
    }
};

export default hooks;