import { Box, styled } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import ErrorBoundary from "../components/ErrorBoundary";
import TasksDialog from "../components/TasksDialog";
import TabBar from "../tabs/TabBar";
import useNavigateTask from "../utils/useNavigateTask";
import BottomToolbar from "./BootomToolbar";
import TopToolbar from "./TopToolbar";
import CustomizationDialog from "../components/CustomizationDialog";


const MainContent = styled('main')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    "&:before": {
        content: `""`,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
}));

const AppLayout = () => {

    // const authenticationMiddleware = useMiddleware('authentication.authenticated');
    // const ChangePasswordDialog = useComponent('Authentication.ChangePasswordDialog');
    // const WelcomeDialog = useComponent('Core.WelcomeDialog');

    const location = useLocation();
    const navigate = useNavigate();
    const navigateTask = useNavigateTask();

    const { workspace } = useAuth();

    // const { enqueueSnackbar } = useSnackbar();

    // const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    // const handleChangePassword = () => {
    //     enqueueSnackbar('رمز عبور تنظیم شد.');
    //     setChangePasswordDialogOpen(false);
    // }

    // const verifyUserPasswordAsync = async () => {
    //     const response = await authenticationMiddleware.verifyPassword();
    //     const { defined } = response;
    //     if (!defined) {
    //         setChangePasswordDialogOpen(true);
    //     }
    // }

    // useEffect(() => {
    //     verifyUserPasswordAsync();
    // }, []);

    useEffect(() => {
        document.body.style.backgroundImage = workspace.membership.options.background;

        if (workspace.membership.homePath && location.pathname === "/")
            navigate(workspace.membership.homePath, { replace: true });
    }, [workspace.membership]);


    return (
        <>
            <MainContent>
                <TopToolbar />
                <TabBar />

                <Box sx={{ flexGrow: 1, position: 'relative' }}>
                    <ErrorBoundary>
                        <Outlet />
                    </ErrorBoundary>
                </Box>

                <BottomToolbar
                    onTasksButtonClicked={() => navigate('#menu')}
                />
            </MainContent>

            {/* <WelcomeDialog open={true} /> */}

            {/* <ChangePasswordDialog
                open={changePasswordDialogOpen}
                dialogProps={{
                    disableEscapeKeyDown: true,
                    onBackdropClick: e => {
                        e.stopPropagination();
                        return false;
                    }
                }}
                onClose={() => setChangePasswordDialogOpen(false)}
                onPasswordChange={handleChangePassword}
            /> */}

            <CustomizationDialog
                open={Boolean(location.hash && location.hash === "#customize")}
                onClose={() => navigate(-1)}
            />

            <TasksDialog
                open={Boolean(location.hash && location.hash === "#menu")}
                onClose={() => navigate(-1)}
                onClickTask={(task) => navigateTask(task, { replace: true })}
            />

            {/* <InstallAppDialog /> */}
        </>
    )
}

export default AppLayout;