import persianJs from "persianjs";

const Text = ({ children }) => {
    return children ? persianJs(String(children)).englishNumber().toString() : children;
}

Text.propTypes = {
    // children: PropTypes.string
}

export default Text;