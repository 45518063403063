import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box, DialogActions,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { useEffect } from "react";

import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import ResponsiveDialog from '../../../components/ResponsiveDialog';
import TextField from "../../../components/TextField";
import useForm from '../../../utils/useForm';


const MessageStatusFormDialog = ({
    open,
    onClose,
    defaultValues = {},
    onUpdate,
}) => {

    const { inject } = useAuth();

    const middleware = useMiddleware('Customers-Club-Management.Messages');

    const initialValues = {
        workspaceId: '',
        messageId: '',
        status: '',
        description: '',
        ...Object.assign({}, ...(inject('Customers-Club-Management', 'MessageStatusForm.initialValues') || [])),
        ...defaultValues
    }

    const {
        register,
        loading,
        handleSubmit,
        reset,
        getValue,
        setValue
    } = useForm({
        initialValues,
        handlePost: data => middleware.updateStatus(data),
        onSubmit: onUpdate
    })

    useEffect(() => {
        if (open) reset(initialValues);
    }, [open]);

    const injectedHooks = Object.assign({}, ...(inject('Customers-Club-Management', 'MessageStatusForm.Hooks') || []));


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>تغییر وضعیت پیامک</Box>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Box sx={{ p: 0 }}>
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel shrink={true}>وضعیت</InputLabel>
                                    <Select
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth={true}
                                        variant="outlined" 
                                        label="وضعیت"
                                        {...register("status", ["helperText", "FormHelperTextProps"])}
                                    >
                                        {[
                                            { label: "در حال بررسی", value: "pending" },
                                            { label: "رد", value: "rejected" }, 
                                            { label: "تایید", value: "confirmed" }
                                        ].map((item, index) => (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    size="small"
                                    label="توضیحات"
                                    type="text"
                                    {...register("description")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {inject('Customers-Club-Management', 'MessageStatusForm.PostForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                </Box>
                <Divider />
                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                    >
                        ثبت مشخصات
                    </LoadingButton>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
                </DialogActions>
            </form>

            {inject('Customers-Club-Management', 'MessageStatusForm.Footer', { register, loading, getValue, setValue, reset, ...injectedHooks })}
        </ResponsiveDialog>
    )
}

export default MessageStatusFormDialog