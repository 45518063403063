import { lazy } from "react";
import Loadable from "../../components/Loadable";


const Dashboard = Loadable(lazy(() => import('./pages/Dashboard/Main')));
const SubmitVisit = Loadable(lazy(() => import('./pages/SubmitVisit/SubmitVisit')));
const Welcome = Loadable(lazy(() => import('./pages/Welcome/Welcome')));
const Birthday = Loadable(lazy(() => import('./pages/Birthday/Birthday')));
const Campaigns = Loadable(lazy(() => import('./pages/Campaigns/Main')));
const CampaignForm = Loadable(lazy(() => import('./pages/CampaignForm/Main')));
const Loyalties = Loadable(lazy(() => import('./pages/Loyalties/Main')));
const LoyaltyForm = Loadable(lazy(() => import('./pages/LoyaltyForm/Main')));

const RewardReport = Loadable(lazy(() => import('./pages/RewardReport/Main')));
const RewardForm = Loadable(lazy(() => import('./pages/RewardForm/Main')));

const DirectForm = Loadable(lazy(() => import('./pages/DirectForm/Main')));
const WheelForm = Loadable(lazy(() => import('./pages/WheelForm/Main')));
const WinnerForm = Loadable(lazy(() => import('./pages/WinnerForm/Main')));
const RetargetForm = Loadable(lazy(() => import('./pages/RetargetForm/Main')));
const NotificationForm = Loadable(lazy(() => import('./pages/NotificationForm/Main')));
const ReminderForm = Loadable(lazy(() => import('./pages/ReminderForm/Main')));
const ReferralForm = Loadable(lazy(() => import('./pages/ReferralForm/Main')));

const AddCredit = Loadable(lazy(() => import('./pages/AddCredit/Main')));


const routes = module => {
	return [
		{
			variant: "dashboard",
			path: '/dashboard',
			element: <Dashboard />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			path: '/visit',
			element: <SubmitVisit />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			path: '/welcome',
			element: <Welcome />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			path: '/birthday',
			element: <Birthday />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/campaigns',
			element: <Campaigns />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/campaigns/add',
			element: <CampaignForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/loyalty-programs',
			element: <Loyalties />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/loyalty-programs/add',
			element: <LoyaltyForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/reward',
			element: <RewardForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/reward/report',
			element: <RewardReport />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/rewards/add',
			element: <RewardForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/direct',
			element: <DirectForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/wheel',
			element: <WheelForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/winner',
			element: <WinnerForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/retarget',
			element: <RetargetForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/notification',
			element: <NotificationForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/reminder',
			element: <ReminderForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/referral',
			element: <ReferralForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/credit/add',
			element: <AddCredit />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			}
		},
	]
};

export default routes;