import { Icon as Iconify } from "@iconify/react";

const tasks = {
    manage: {
        title: 'مدیریت تیکت‌ها',
        Icon: props => <Iconify {...props} icon='solar:chat-dots-bold-duotone' />,
        path: `/tickets`,
        keywords: [],
        subtasks: []
    },
    personal: {
        title: 'تیکت‌های من',
        Icon: props => <Iconify {...props} icon='solar:headphones-round-bold-duotone' />,
        path: `/auth/tickets`,
        keywords: [],
        subtasks: []
    }
}

export default tasks;