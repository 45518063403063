import { Icon as Iconify } from "@iconify/react";


const tasks = {
    dashboard: {
        title: `داشبورد باشگاه مشتریان`,
        Icon: (props) => <Iconify {...props} icon='solar:chart-bold-duotone' />,
        path: `/dashboard`,
        keywords: [],
        default: true,
        subtasks: []
    },
    submitVisit: {
        title: `ثبت مراجعه`,
        Icon: (props) => <Iconify {...props} icon='solar:restart-circle-bold-duotone' />,
        path: `/visit`,
        keywords: [],
        default: true,
        subtasks: []
    },
    welcome: {
        title: `خوش‌آمدگویی`,
        Icon: (props) => <Iconify {...props} icon='solar:hand-shake-bold-duotone' />,
        path: `/welcome`,
        keywords: [],
        default: true,
        subtasks: []
    },
    birthday: {
        title: `تبریک تولد`,
        Icon: (props) => <Iconify {...props} icon='solar:confetti-bold-duotone' />,
        path: `/birthday`,
        keywords: [],
        default: true,
        subtasks: []
    },
    campaigns: {
        title: `ارسال گروهی`,
        Icon: (props) => <Iconify {...props} icon='solar:users-group-two-rounded-bold-duotone' />,
        path: `/campaigns`,
        keywords: [],
        default: true,
        subtasks: []
    },
    addCampaign: {
        title: `ارسال گروهی جدید`,
        Icon: (props) => <Iconify {...props} icon='solar:users-group-two-rounded-bold-duotone' />,
        path: `/campaigns/add`,
        keywords: [],
        default: true,
        subtasks: []
    },
    loyaltyPrograms: {
        title: `برنامه وفاداری`,
        Icon: (props) => <Iconify {...props} icon='solar:user-heart-bold-duotone' />,
        path: `/loyalty-programs`,
        keywords: [],
        default: true,
        subtasks: []
    },
    reward: {
        title: `جایزه`,
        Icon: (props) => <Iconify {...props} icon='solar:gift-bold-duotone' />,
        path: `/reward`,
        keywords: [],
        default: true,
        subtasks: []
    },
    rewardReport: {
        title: `گزارشات جایزه`,
        Icon: (props) => <Iconify {...props} icon='solar:gift-bold-duotone' />,
        path: `/reward/report`,
        keywords: [],
        default: true,
        subtasks: []
    },
    direct: {
        title: `لینک دایرکت`,
        Icon: (props) => <Iconify {...props} icon='solar:link-round-angle-bold-duotone' />,
        path: `/direct`,
        keywords: [],
        default: true,
        subtasks: []
    },
    wheel: {
        title: `گردونه شانس`,
        Icon: (props) => <Iconify {...props} icon='solar:wheel-bold-duotone' />,
        path: `/wheel`,
        keywords: [],
        default: true,
        subtasks: []
    },
    winner: {
        title: `برنده باش`,
        Icon: (props) => <Iconify {...props} icon='solar:ranking-bold-duotone' />,
        path: `/winner`,
        keywords: [],
        default: true,
        subtasks: []
    },
    retarget: {
        title: `هدفگیری مجدد`,
        Icon: (props) => <Iconify {...props} icon='solar:gps-bold-duotone' />,
        path: `/retarget`,
        keywords: [],
        default: true,
        subtasks: []
    },
    notification: {
        title: `اطلاع‌رسانی`,
        Icon: (props) => <Iconify {...props} icon='solar:bell-bing-bold' />,
        path: `/notification`,
        keywords: [],
        default: true,
        subtasks: []
    },
    reminder: {
        title: `یادآوری`,
        Icon: (props) => <Iconify {...props} icon='solar:alarm-play-bold-duotone' />,
        path: `/reminder`,
        keywords: [],
        default: true,
        subtasks: []
    },
    referral: {
        title: `ریفرال`,
        Icon: (props) => <Iconify {...props} icon='solar:hand-money-bold-duotone' />,
        path: `/referral`,
        keywords: [],
        default: true,
        subtasks: []
    },
    charge: {
        title: `شارژ اعتبار پیامکی`,
        Icon: (props) => <Iconify {...props} icon='solar:bolt-circle-bold-duotone' />,
        path: `/credit/add`,
        keywords: [],
        default: true,
        subtasks: []
    },
}

export default tasks;