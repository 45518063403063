import format from "date-fns-jalali/format";
import DataListBox from "../../../components/DataListBox";

export const ClientDetailsOverview = ({ person, loading }) => {
	return (
		<>
			<DataListBox
				title="باشگاه مشتریان"
				loading={loading}
				data={[
					{
						title: "اولین مراجعه",
						description: (() => {
							if (!person.references) return "-";

							return format(
								new Date(person.references[0]),
								"yyyy/mm/dd"
							);
						})(),
					},
					{
						title: "تعداد مراجعه",
						description: person.totalReferences,
					},
					{
						title: "آخرین مراجعه",
						description: (() => {
							if (!person.references) return "-";

							return format(
								new Date(
									person.references[
										person.references.length - 1
									]
								),
								"yyyy/mm/dd"
							);
						})(),
					},
				]}
			/>
		</>
	);
};
