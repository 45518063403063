import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        create: '/notifications',
        markAsRead: '/notification/:notificationId/markAsRead',
    }

    /**
     * Create a new notification
     *  
     * @return Promise
     */
    async create(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.create,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            const err = new AxiosError(error);
            if (err.response.status === 422) {
                const { status, statusText } = err.response;
                return {
                    ...err.response.data,
                    status,
                    statusText
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Create a new notification
     *  
     * @return Promise
     */
    async markAsRead(notificationId) {
        try {
            const response = await axios({
                method: 'post',
                url: this.replaceParams(this.paths.markAsRead, { notificationId }),
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;