import { Box, Button, AppBar as MuiAppBar, Toolbar, styled } from "@mui/material";
import { useComponent } from "../Application";
import IconLogo from "../components/IconLogo";
import useHideOnScroll from "../utils/useHideOnScroll";
import { useAuth } from "../auth";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    transition: theme.transitions.create(
        'border-color',
        { duration: theme.transitions.duration.enteringScreen }
    )
}));

const ToolbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

const LogoButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Ebtekar',
    fontSize: 17,
    lineHeight: 1,
    color: 'inherit',
    minWidth: 0,
    "& span": {
        color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
        // padding: 0,
    }
}));

const TopToolbar = () => {

    const { workspace } = useAuth();

    const AccountMenu = useComponent('Authentication.AccountMenu');
    const NotificationsMenu = useComponent('Notifications.NotificationsMenu');
    const WorkspacesMenu = useComponent('Core.WorkspacesMenu');

    const tabBarIsHidden = useHideOnScroll();

    return (
        <>
            <AppBar
                position="fixed"
                variant="outlined"
                color="inherit"
                elevation={0}
                component="div"
                sx={{ borderColor: !tabBarIsHidden && 'transparent' }}
            >
                <Toolbar variant="dense">
                    <ToolbarContainer>
                        <LogoButton>
                            {workspace.options['panel:top-toolbar:logo-path'] ? (
                                <img src={workspace.options['panel:top-toolbar:logo-path']} alt="" />
                            ) : (
                                <>
                                    <Box
                                        component={IconLogo}
                                        sx={{
                                            mr: 2,
                                            // mr: !isMobile && 2,
                                            color: 'primary.main',
                                            fontSize: '1.5em'
                                        }}
                                    />
                                    <Box sx={{ textAlign: 'left' }}>
                                        فــرا<span>هاب</span><br />
                                        <Box component="small" sx={{ fontSize: '.9em', fontFamily: 'Kalameh', fontWeight: 'bold' }}>پلتفرم مدیریت کسب و کار</Box>
                                    </Box>
                                </>
                            )}
                        </LogoButton>
                    </ToolbarContainer>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box display="flex" gap={theme => theme.spacing(1)}>
                        <WorkspacesMenu/>
                        <NotificationsMenu />
                        <AccountMenu />
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar variant="dense" />
        </>
    );
}

export default TopToolbar;