import { Icon as Iconify } from "@iconify/react";
import MenuBookOutlined from "@mui/icons-material/MenuBookOutlined";

const tasks = {
    dashboard: {
        title: `مدیریت مستندات`,
        Icon: props => <Iconify {...props} icon='solar:documents-bold-duotone' />,
        path: `/docs`,
        keywords: [],
        default: true,
    },
    bookDetails: (book) => ({
        title: book?.name,
        Icon: MenuBookOutlined,
        path: '/docs/'.concat(book?.id)
    })
}

export default tasks;