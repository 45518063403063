import Middleware from "../../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class InvoicesMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Invoices';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        deliver: '/invoice/:invoiceId/markDeliver',
        return: '/invoice/:invoiceId/return',
        returnings: '/invoices/reserve/returnings',
    }

    /**
     * Deliver an invoice
     *  
     * @return Promise
     */
    async deliver(invoiceId) {
        try {
            const response = await axios({
                method: 'post',
                url: this.replaceParams(this.paths.deliver, { invoiceId })
            });

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Return an invoice
     *  
     * @return Promise
     */
    async return(invoiceId, data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.replaceParams(this.paths.return, { invoiceId }),
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get list of invoices match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async returnings(args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.paths.returnings,
                    args
                )
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    //
}

export default InvoicesMiddleware;