import MainMiddleware from "./MainMiddleware";
import ManageAdsMiddleware from "./ManageAdsMiddleware";
import ManageBoardsMiddleware from "./ManageBoardsMiddleware";


const middlewares = [
    MainMiddleware,
    ManageAdsMiddleware,
    ManageBoardsMiddleware,
];

export default middlewares;