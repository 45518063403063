import { Icon as Iconify } from "@iconify/react";

const tasks = {
    manage: {
        title: `مدیریت سامانه پیامکی`,
        Icon: props => <Iconify {...props} icon='solar:chat-round-line-bold-duotone' />,
        path: `/sms/manage`,
        subtasks: []
    },
    history: {
        title: `پیامک‌های ارسال‌شده`,
        Icon: props => <Iconify {...props} icon='solar:chat-square-arrow-bold-duotone' />,
        path: `/sms/history`,
        subtasks: []
    },
    //
}

export default tasks;