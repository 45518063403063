
import InvoiceListIconOutlined from "@mui/icons-material/AssignmentOutlined";

const tasks = {
    details: (invoice) => ({
        title: `فاکتور ${invoice.number}`,
        Icon: InvoiceListIconOutlined,
        path: `/invoice/${invoice.id}`
    }),
    
    //
}

export default tasks;