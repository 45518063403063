import { Icon as Iconify } from "@iconify/react";
import { Box, Fade, IconButton, styled, SvgIcon, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import useAuth from "../auth/useAuth";
import useDebounce from "../utils/useDebounce";

const TaskIconBackdrop = styled(Box, {
    shouldForwardProp: prop => !['variant', 'size'].includes(prop)
})(({ theme, variant, size }) => ({
    position: "relative",
    "&:before": {
        content: `""`,
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backdropFilter: variant === "glass" && 'blur(10px)'
    }
}))

const TaskIconContainer = styled(Box, {
    shouldForwardProp: prop => !['variant', 'size'].includes(prop)
})(({ theme, variant, size }) => ({
    padding: theme.spacing(0.5),
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    color: theme.palette.text.primary,
    backgroundColor: variant === 'transparent' && 'rgba(255,255,255,0.3)',
    textDecoration: 'none',
    width: 100,
    minHeight: 100,
    border: '1px solid transparent',
    transition: theme.transitions.create(['color', 'background-color']),
    '.MuiSvgIcon-root': {
        transition: theme.transitions.create('color'),
    },
    "&:hover": {
        color: theme.palette.primary.main,
        '.MuiSvgIcon-root': {
            color: theme.palette.primary.main
        },
        backgroundColor: variant === 'transparent' ? 'rgba(255,255,255,0.5)' : theme.palette.action.hover,
    }
}))

const TaskTitle = styled(Typography)(({ theme }) => ({
    fontSize: '0.825rem',
    minHeight: '2.25em',
    textAlign: 'center',
    lineHeight: 1.125,
}))

const TaskIconWrapper = styled(Box)(({ theme }) => ({
    fontSize: '35px',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1.5)
}))

const PinTaskButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5)
}))

const TaskIcon = ({ Icon, title, path, titleProps = {}, onClickToggle, ...props }) => {

    const theme = useTheme();

    const { workspace } = useAuth();

    const pinned = workspace.membership.tabBarPins.includes(path);

    const [hovered, setHovered] = useState(false);
    const debouncedHovered = useDebounce(hovered, 250);

    return (
        <TaskIconBackdrop>
            <TaskIconContainer
                {...props}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <TaskIconWrapper component={Icon} />
                <TaskTitle variant="button"{...titleProps}>
                    {title}
                </TaskTitle>
                <Fade in={debouncedHovered} mountOnEnter unmountOnExit>
                    <PinTaskButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickToggle(e);
                        }}
                        size="small"
                    >
                        <SvgIcon
                            component={props => <Iconify {...props} icon={pinned ? 'solar:pin-bold-duotone' : 'solar:pin-linear'}/>}
                            fontSize="small"
                        />
                    </PinTaskButton>
                </Fade>
            </TaskIconContainer>
        </TaskIconBackdrop>
    )
}

export default TaskIcon;