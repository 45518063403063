import { Box, LinearProgress } from '@mui/material';
import React, { Suspense } from 'react';


const Loader = () => {
    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 99999
        }}>
            <LinearProgress color="primary" />
        </Box>
    );
};

const Loadable = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;