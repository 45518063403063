import { Grid, InputAdornment } from "@mui/material";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import NumberTextField from "../../../../components/NumberTextField";
import TextField from "../../../../components/TextField";

const ProductFormDialogFields = ({ control, register, errors }) => {
    return (
        <Fragment>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="موجودی کل"
                    dir="ltr"
                    variant="filled"
                    type="number"
                    required
                    error={errors.totalQuantity && true}
                    {...register("totalQuantity", { required: true, min: 1 })}
                    InputProps={{
                        inputProps: {
                            min: 1
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Controller
                    name="unitPrice"
                    control={control}
                    render={
                        ({ field }) => (
                            <NumberTextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                size="small"
                                label="فی کرایه"
                                variant="filled"
                                dir="ltr"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                }}
                                required
                                {...register("unitPrice", { required: true })}
                                error={errors.unitPrice && true}
                                {...field}
                            />
                        )
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Controller
                    name="damagePrice"
                    control={control}
                    render={
                        ({ field }) => (
                            <NumberTextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                size="small"
                                label="فی خسارت"
                                variant="filled"
                                dir="ltr"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                }}
                                required
                                {...register("damagePrice", { required: true })}
                                error={errors.damagePrice && true}
                                {...field}
                            />
                        )
                    }
                />
            </Grid>
        </Fragment>
    )
}

export default ProductFormDialogFields;