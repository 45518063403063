import Add from "@mui/icons-material/Add";
import { Box, Grid } from "@mui/material";
import TabView from "./TabView";

const tour = {
    key: 'get-started',
    steps: [{
        title: "به فراهاب خوش آمدید",
        intro: "فراهاب یک بستر مدیریتی برای کسب و کارها می باشد که امکانات پیشرفته مدیریتی را در ساده ترین روش ممکن برای صاحبین کسب و کار ارائه می‌دهد.<br/>در ادامه طی چند مرحله شما با محیط کار آشنا خواهید شد.",
    }, {
        title: "معرفی فرآیندها",
        intro: "در فراهاب همه چیز در قالب فرآیندها می‌باشد. مثل فرایند مدیریت محصولات، فرآیند ثبت فاکتور و ... که شما بسته به کاری که قصد انجام آن را دارید، به فرآیند مرتبط با آن موضوع رجوع میکنید.",
    }, {
        title: "نوار فرآیند",
        intro: "در نوار فرآیند شما میتوانید همه فرآیندها و فرآیندهای انتخابی خود شما را که به منظور دسترسی سریع انتخاب نموده‌اید، مشاهده و انتخاب کنید.",
        element: ".tasks-container",
    }, {
        title: "همه فرآیندها",
        intro: "با کلیک برروی این دکمه می‌توانید همه فرآیندهای موجود را مشاهده کنید و یا جستجو کنید.",
        element: ".all-tasks",
    }, {
        title: "نوار تب فرآیندها",
        intro: "شما می‌توانید چندین فرآیند را به صورت همزمان اجرا کنید. برای مثال زمانی بخواهید همزمان چندین فاکتور ثبت کنید، باید به ازای هر فاکتور یک تب فرآیند جدید ایجاد کنید.",
        element: ".tabs-container",
    }, {
        title: "ایجاد تب فرآیند جدید",
        intro: "با کلیک بر روی این دکمه می‌توانید یک تب فرآیند جدید ایجاد کنید.",
        element: ".new-tab",
    }, {
        title: "راهنمای فرآیند",
        intro: "با کلیک بر روی این دکمه در هر فرآیند می‌توانید با محیط آن فرآیند آشنا شوید.",
        element: ".tasks-tour",
    }, {
        title: "پشتیبانی",
        intro: "در صورتیکه در سیستم با مشکلی مواجه شده‌اید و یا اشکالی رخ داد و همچنین نظر و پیشنهادی داشتید و یا حتی قصد توسعه فرآیند و گزارشات اختصاصی کسب‌و‌کار خود را داشتید، کافیست برروی این دکمه کلیک کنید و یک تیکت برای ما ارسال کنید.",
        element: ".tickets-button",
    }, {
        title: "تمام شد!",
        intro: "تبریک میگویم! شما کار با محیط فراهاب را یاد گرفته‌اید. حال کافیست برای انجام کارهای مختلف، فرآیند مرتبط با آن را از طریق دکمه همه فرآیندها جستجو کنید. <br/> پیشنهاد می‌شود برای شروع از طریق فرآیند مدیریت محصولات، محصولاتی خود را ثبت کنید.",
        element: ".task-manage-products",
    }]
};

const NewTab = () => {
    return (
        <TabView
            title="فرآیند جدید"
            Icon={Add}
            tour={tour}
        >
            <Box sx={{ height: '100%' }}>
                <Grid container justifyContent='center' sx={{ height: '100%' }}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </TabView>
    )
}

export default NewTab;