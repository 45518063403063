import { Add } from "@mui/icons-material";
import { CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import TabsContext from "./TabsContext";


const TabsProvider = ({ children }) => {

    const [tabs, setTabs] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const location = useLocation();
    const navigate = useNavigate();


    const add = (tab) => {
        const newTabs = [...tabs, tab];
        setTabs(newTabs);
        setCurrentIndex(newTabs.length - 1);
    };

    const markCurrent = tabIndex => {
        setCurrentIndex(tabIndex);
        navigate(tabs.find((tab, index) => index === tabIndex).location)
    }

    const openInNew = _location => {
        const tab = {
            title: <Skeleton variant="text" width={100} />,
            Icon: CircularProgress,
            location: _location
        }
        add(tab);
        navigate(_location);
    }

    const update = (tabIndex, tab) => setTabs(
        tabs.map(
            (t, index) => index === tabIndex ? ({ ...t, ...tab }) : t
        )
    )
    const close = tabIndex => {
        const newTabs = tabs.filter((t, i) => i !== tabIndex);
        setTabs(newTabs);
        if (tabIndex <= currentIndex) {
            setCurrentIndex(currentIndex - 1);
            if (currentIndex - 1 > -1)
                navigate(newTabs.find((tab, index) => index === currentIndex - 1).location)
            else navigate("/")
        }
    };

    useEffect(() => {
        if (tabs.length < 1) {
            navigate(location);
        }
    }, [tabs.length]);


    return (
        <TabsContext.Provider
            value={{
                tabs,
                currentIndex,
                currentTab: tabs.find((t, i) => i === currentIndex),
                markCurrent,
                add,
                update,
                close,
                openInNew
            }}
        >
            {children}
        </TabsContext.Provider>
    )
}

export default TabsProvider;