import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const Tickets = Loadable(lazy(() => import('./pages/Tickets/Main')));
const TicketDetails = Loadable(lazy(() => import('./pages/TicketDetails/Main')));
const NewTicket = Loadable(lazy(() => import('./pages/NewTicket/Main')));
// const PublicTicket = lazy(() => import('./pages/PublicTicket/Main'));


const routes = [
    // {
    //     variant: "root",
    //     private: false,
    //     path: '/@:workspaceId/tickets',
    //     element: <PublicTicket />
    // },
    {
        variant: "dashboard",
        private: true,
        path: '/support/tickets',
        element: <Tickets scope="auth" adminWorkspace={true} />
    },
    {
        variant: "dashboard",
        private: true,
        path: '/support/tickets/new',
        element: <NewTicket adminWorkspace={true} />
    },
    {
        variant: "dashboard",
        private: true,
        path: '/support/ticket/:ticketId',
        element: <TicketDetails />
    },
    {
        variant: "dashboard",
        private: true,
        path: '/tickets',
        element: <Tickets scope="workspace" />,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole("owner") || hasPermission("ticket:view");
        }
    },
    {
        variant: "dashboard",
        private: true,
        path: '/tickets/new',
        element: <NewTicket />,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole("owner") || hasPermission("ticket:edit");
        }
    },
    {
        variant: "dashboard",
        private: true,
        path: '/auth/tickets',
        element: <Tickets scope="auth" />,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole("owner") || hasPermission("ticket:view");
        }
    },
];

export default routes;