import axios, { AxiosError } from "axios";
import Middleware from "../../../foundation/Middleware";


class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware base path
     * 
     * @var string
     */
    basePath = '/';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        getDashboardOptions: '/dashboard/options',
    }

    /**
     * Get workspace welcome message related options
     * 
     * @param {array} keys 
     * @return Promise 
     */
    async getDashboardOptions() {
        try {
            const response = await axios(this.serializePath(this.paths.getDashboardOptions));

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;