import { Icon as Iconify } from "@iconify/react";


const tasks = {
    manage: {
        title: `مدیریت نقش‌ها`,
        Icon: props => <Iconify {...props} icon='solar:user-block-rounded-bold-duotone' />,
        path: `/roles`,
        keywords: [],
    },
    // details: (person) => ({
    //     title: person?.fullName,
    //     Icon: PersonOutline,
    //     // renderIcon: ({ sx, props }) => (
    //     //     <Avatar
    //     //         {...props}
    //     //         alt={person?.fullName}
    //     //         src={person?.avatar}
    //     //         sx={{ ...sx, width: 30, height: 30 }}
    //     //     />
    //     // ),
    //     path: '/people/'.concat(person?.id)
    // })
}

export default tasks;