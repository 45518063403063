import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class SamplesMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Samples';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/contract-samples';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        details: '/:sampleId',
        createOrUpdate: '/',
        delete: '/:sampleId',
        //
    }

    /**
     * Get list of contract samples match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args));
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get contract sample details
     * 
     * @param {*} args 
     * @return Promise 
     */
    async details(sampleId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { sampleId })
            );
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing contract sample
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data,
            };
        } catch (error) {

            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing contract sample from db
     *  
     * @return Promise
     */
    async delete(sampleId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { sampleId })
            });
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default SamplesMiddleware;