import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class MembershipMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Membership';

    /**
     * The middleware name
     *
     * @var string
     */
    basePath = '/membership';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        options: '/options',
        taskbar: '/taskbar',
    }

    /**
     * Mark options
     * 
     * @param {*} args 
     * @return Promise 
     */
    async options(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.options,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Update workspace taskbar
     * 
     * @param {*} args 
     * @return Promise 
     */
    async taskbar(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.taskbar,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MembershipMiddleware;