import { InvoiceFormAddProductPreField } from "./InvoiceForm";
import { InvoiceItemsTableBodyCellsAfterTitle, InvoiceItemsTableHeadCellsAfterTitle } from "./InvoiceItemsTable";

import ClientSuppliedItems from "../pages/ClientSuppliedItems";

const hooks = {
    'Ceremonies/Reservation': {
        'InvoiceForm.AddItem.formDataState': ({ tab }) => ({
            bailer: tab && tab.state && tab.state.formData ? tab.state.formData.bailer : ''
        }),
        'InvoiceForm.AddProduct.PreField': props => <InvoiceFormAddProductPreField {...props} />,
    },
    'Invoices': {
        'InvoiceItemsTable.HeadCells.AfterTitle': props => <InvoiceItemsTableHeadCellsAfterTitle {...props} />,
        'InvoiceItemsTable.BodyCells.AfterTitle': props => <InvoiceItemsTableBodyCellsAfterTitle {...props} />,
    },
    'Clients': {
        'ClientDetails.TabsList': ({ clientId }) => {
            return { label: 'تاریخچه اقلام امانتی', value: `/client/${clientId}/supplied` }
        },
        'routes.clients.details': () => {
            return { variant: "dashboard", requireAuth: true, path: 'supplied', element: <ClientSuppliedItems /> }
        }
    }
};

export default hooks;