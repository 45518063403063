import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class TasksMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Tasks';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/tasks';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        createOrUpdate: '/',
        delete: '/:taskId',
    }

    /**
     * Get list of categories match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args, config) {
        try {
            const response = await axios({
                method: 'get',
                url: this.serializePath(this.paths.list, args),
                ...config
            });
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing category
     *  
     * @return Promise
     */
    async createOrUpdate(data, config) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data,
                ...config,
            });

            return {
                ...response.data,
            };
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing category from db
     *  
     * @return Promise
     */
    async delete(categoryId, config) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { categoryId }),
                ...config
            });
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default TasksMiddleware;