import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box, DialogActions,
    DialogTitle,
    Divider,
    Grid,
    IconButton
} from "@mui/material";
import { useEffect } from "react";
import { useMiddleware } from "../../../Application";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import TextField from "../../../components/TextField";
import useForm from "../../../utils/useForm";
import { useAuth } from "../../../auth";


const CategoryFormDialog = ({
    open,
    onClose,
    label,
    defaultValues = {},
    onCreateOrUpdate,
    requestConfig
}) => {

    const { inject } = useAuth();

    const middleware = useMiddleware('Categories.Main');

    const initialValues = {
        name: '',
        ...Object.assign({}, ...(inject('Categories', 'CategoryForm.initialValues') || [])),
        ...defaultValues
    }

    const {
        register,
        handleSubmit,
        loading,
        reset,
        getValue,
        setValue
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate({ ...data, label }, requestConfig),
        onSubmit: onCreateOrUpdate
    })

    useEffect(() => {
        if (open) reset(initialValues)
    }, [open]);

    const injectedHooks = Object.assign({}, ...(inject('Categories', 'CategoryForm.Hooks') || []));


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{defaultValues.id ? 'بروزرسانی گروه' : 'ثبت گروه جدید'}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 0 }}>
                {inject('Categories', 'CategoryForm.PreForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                <Box sx={{ p: 2 }}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth={true}
                        size="small"
                        label="نام گروه"
                        variant="filled"
                        required
                        // autoFocus
                        {...register("name")}
                    />
                </Box>
                {inject('Categories', 'CategoryForm.PostForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}                    <Divider />
                <Grid container>
                    {inject('Categories', 'CategoryForm.PostFormGrid', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                </Grid>
            </Box>
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                >
                    ثبت
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>

            {inject('Categories', 'CategoryForm.Footer', { register, loading, getValue, setValue, reset, ...injectedHooks })}
        </ResponsiveDialog>
    )
}

export default CategoryFormDialog