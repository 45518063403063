import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const TasksList = Loadable(lazy(() => import('./pages/TasksList/Main')));

const routes = [
    {
        variant: "dashboard",
        private: true,
        path: '/tasks',
        element: <TasksList />,
        authorize: ({ hasRole, hasPermission }) => {
            return hasRole("owner") || hasPermission("automation:view");
        }
    },
    //
];

export default routes;