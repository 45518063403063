import AdapterJalali from '@date-io/date-fns-jalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import "@ckeditor/ckeditor5-build-classic/build/translations/fa";
import Inventory2Outlined from "@mui/icons-material/Inventory2Outlined";
import Save from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Grid, InputAdornment, styled } from "@mui/material";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useComponent, useMiddleware } from '../../../../Application';
import TextField from "../../../../components/TextField";
import { TabView } from "../../../../tabs";
import useDialog from '../../../../utils/useDialog';
// import "./ckeditor-overrides.css";
import { DatePicker } from '@mui/x-date-pickers';
import NumberTextField from '../../../../components/NumberTextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Warning from '@mui/icons-material/Warning';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import "@ckeditor/ckeditor5-build-classic/build/translations/fa";

// const tour = {
//     key: 'contract-sample-form',
//     steps: [
//         //
//     ]
// };

const EditorWrapper = styled(Box)(({ theme }) => ({

    // '& .ck.ck-editor__editable_inline': {
    //     border: 'none'
    // }
    '& .ql-container': {
        fontSize: '1rem',
        fontFamily: theme.typography.fontFamily,
        minHeight: 400
    },
    '& .ql-editor': {
        textAlign: 'left'
    },
    '& .ql-toolbar.ql-snow .ql-formats': {
        marginRight: '15px',
        marginLeft: 0
    },
    '& .ql-snow .ql-picker-label': {
        paddingLeft: '2px',
        paddingRight: '8px'
    },
    '& .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg': {
        right: 0,
        left: 'auto'
    },
    '& .ql-snow .ql-picker': {
        float: 'left'
    },
    '& .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button': {
        float: 'left'
    },
    '& .ql-toolbar.ql-snow': {
        border: 'none',
        borderBottom: '1px solid '.concat(theme.palette.divider)
    },
    '& .ql-container.ql-snow': {
        border: 'none'
    }
}))

const transformations = client => [
    { from: "{نام مشتری}", to: client.firstName || '__________' },
    { from: "{نام خانوادگی مشتری}", to: client.lastName || '__________' },
    { from: "{نام کامل مشتری}", to: client.fullName || '_______________' },
]

const Main = () => {

    const middleware = useMiddleware('Contract.Contracts');

    const navigate = useNavigate();
    const params = useParams();


    const defaultValues = {
        sample: '',
        client: '',
        issuedAt: new Date(),
        price: ''
    }

    const [number, setNumber] = useState();
    const [formData, setFormData] = useState(defaultValues);
    const [preview, setPreview] = useState('');
    const [isCreatingOrUpdating, setCreatingOrUpdating] = useState(false);
    const [errors, setErrors] = useState({});

    const ClientSelectDialog = useComponent('People.PersonSelectDialog');
    const {
        isOpen: isClientDialogOpen,
        isOpening: isClientDialogOpening,
        open: openClientDialog,
        close: closeClientDialog,
        data: clientDialogClient
    } = useDialog();

    const SampleSelectDialog = useComponent('Contract.ContractSampleSelectDialog');
    const {
        isOpen: isSampleDialogOpen,
        isOpening: isSampleDialogOpening,
        open: openSampleDialog,
        close: closeSampleDialog,
        data: sampleDialogSample
    } = useDialog();


    const { enqueueSnackbar } = useSnackbar();

    const createOrUpdateAsync = async (e) => {
        e.preventDefault();

        setCreatingOrUpdating(true);
        const response = await middleware.createOrUpdate(formData);

        if (response.ok) {
            enqueueSnackbar(`قرارداد ${params.contractId ? 'بروزرسانی' : 'جدید ثبت'} گردید.`);
            setCreatingOrUpdating(false);
            navigate('/contracts', { replace: true });
        } else {
            const { status, errors } = response;
            if (status === 422) {
                setErrors(errors);
                setCreatingOrUpdating(false);
            }
        }
    }

    useEffect(() => {
        const loadContractAsync = async () => {
            const response = await middleware.details(params.contractId);
            if (response.ok) {
                setFormData({
                    ...response.contract,
                });
                setNumber(response.contract.number)
            }
        }

        if (params.contractId) {
            loadContractAsync();
        }
    }, [params.contractId]);

    useEffect(() => {
        const loadNumberAsync = async () => {
            const response = await middleware.getNewNumber(params.contractId);
            if (response.ok) {
                setNumber(response.number);
            }
        }

        if (!params.contractId) {
            loadNumberAsync();
        }
    }, []);

    useEffect(() => {
        if (formData.client && formData.sample) {

            let preview = formData.sample.content;
            transformations(formData.client).forEach(transform => {
                preview = preview.replace(
                    new RegExp(transform.from, "g"),
                    transform.to
                )
            })

            setPreview(preview);
        } else {
            setPreview('');
        }
    }, [formData.client, formData.sample]);


    return (
        <TabView
            title="تنظیم قرارداد"
            Icon={Inventory2Outlined}
            // tour={tour}
        >
            <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined">
                            <CardContent>

                                <Grid container spacing={2} sx={{ mb: 2 }}>

                                    <Grid item xs={4}>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth={true}
                                            variant="filled"
                                            size="small"
                                            label="شماره"
                                            type="number"
                                            dir="ltr"
                                            value={number}
                                            onChange={() => { }}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <LocalizationProvider dateAdapter={AdapterJalali}>
                                            <DatePicker
                                                mask="____/__/__"
                                                label="تاریخ تنظیم"
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            InputLabelProps={{ shrink: true }}
                                                            fullWidth={true}
                                                            variant="filled"
                                                            size="small"
                                                            {...params}
                                                        />
                                                    )
                                                }
                                                value={formData.issuedAt}
                                                onChange={(issuedAt) => setFormData({ ...formData, issuedAt })}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth={true}
                                            size="small"
                                            label="نمونه قرارداد"
                                            variant="filled"
                                            value={formData.sample ? formData.sample.name : ''}
                                            onChange={() => { }}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                            onFocus={() => openSampleDialog()}
                                            onClick={() => openSampleDialog()}
                                            onKeyDown={e => e.keyCode === 13 && openSampleDialog()}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth={true}
                                            size="small"
                                            label="مشتری"
                                            variant="filled"
                                            value={formData.client ? formData.client.fullName : ''}
                                            onChange={() => { }}
                                            inputProps={{
                                                readOnly: true
                                            }}
                                            onFocus={() => openClientDialog()}
                                            onClick={() => openClientDialog()}
                                            onKeyDown={e => e.keyCode === 13 && openClientDialog()}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <NumberTextField
                                            fullWidth={true}
                                            size="small"
                                            label="مبلغ نهایی قرارداد"
                                            dir="ltr"
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <small>ریال</small>
                                                    </InputAdornment>
                                                )
                                            }}
                                            error={Boolean(errors.unitPrice)}
                                            value={formData.price}
                                            onChange={e => setFormData({ ...formData, price: e.target.value })}
                                        />
                                    </Grid>

                                </Grid>

                                <LoadingButton
                                    loading={isCreatingOrUpdating}
                                    loadingPosition="start"
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    startIcon={<Save />}
                                    onClick={createOrUpdateAsync}
                                >
                                    ثبت
                                </LoadingButton>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card variant="outlined">
                            {Boolean(preview) ? (
                                <EditorWrapper>
                                    <ReactQuill
                                        theme="snow"
                                        value={preview}
                                        readOnly
                                        onChange={() => { }}
                                        // onChange={(newValue, delta, source) => {
                                        //     if (source === 'user') {
                                        //         setFormData({ ...formData, content: newValue })
                                        //     }
                                        // }}
                                        // placeholder="محتوای قرارداد..."
                                        modules={{
                                            toolbar: false
                                        }}
                                    />
                                </EditorWrapper>
                            ) : (
                                <Box
                                    sx={{ px: 5, py: 15, textAlign: "center" }}
                                >
                                    <Warning fontSize="large" />
                                    <p>برای مشاهده پیش‌نمایش قرارداد، مشتری و نمونه قرارداد را انتخاب کنید.</p>
                                </Box>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <ClientSelectDialog
                open={isClientDialogOpen}
                onClose={closeClientDialog}
                selectable={true}
                roles={['customer']}
                onClear={() => {
                    setFormData({ ...formData, client: '' });
                    closeClientDialog();
                }}
                onSelect={client => {
                    setFormData({ ...formData, client });
                    closeClientDialog();
                }}
                selected={formData.client}
            />

            <SampleSelectDialog
                open={isSampleDialogOpen}
                onClose={closeSampleDialog}
                selectable={true}
                onClear={() => {
                    setFormData({ ...formData, sample: '' });
                    closeSampleDialog();
                }}
                onSelect={sample => {
                    setFormData({ ...formData, sample });
                    closeSampleDialog();
                }}
                selected={formData.sample}
            />
        </TabView>
    )
}

export default Main;