import AdapterJalali from '@date-io/date-fns-jalali';
import ReturnIcon from "@mui/icons-material/DoneAll";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogTitle, Divider, Grid, InputAdornment, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import pick from "lodash/pick";
import sumBy from "lodash/sumBy";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useMiddleware } from '../../../../Application';
import NumberTextField from "../../../../components/NumberTextField";
import pricify from "../../../../utils/pricify";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const InvoiceContainer = ({ items, onItemChange }) => {

    return (

        <TableContainer
            sx={{ overflow: "auto", maxHeight: 400 }}
        >
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">کد</TableCell>
                        <TableCell>شرح محصول</TableCell>
                        <TableCell>فی خسارت</TableCell>
                        <TableCell align="center">رزرو شده</TableCell>
                        <TableCell>برگشتی از قبل</TableCell>
                        <TableCell>تعداد برگشتی</TableCell>
                        <TableCell>تعداد خسارتی</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <StyledTableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{item.item.code}</TableCell>
                            <TableCell>{item.item.title}</TableCell>
                            <TableCell>{pricify(item.item.damagePrice)}</TableCell>
                            <TableCell align="center">{item.reservedQuantity}</TableCell>
                            <TableCell align="center">{item.totalReturned}</TableCell>
                            <TableCell>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    type="number"
                                    dir="ltr"
                                    value={item.returnedQuantity}
                                    onChange={(e) => onItemChange(index, 'returnedQuantity', e.target.value)}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: item.reservedQuantity - item.totalReturned
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    type="number"
                                    dir="ltr"
                                    value={item.damagedQuantity}
                                    onChange={(e) => onItemChange(index, 'damagedQuantity', e.target.value)}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: item.reservedQuantity - item.totalReturned - item.returnedQuantity
                                        }
                                    }}
                                />
                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


const InvoiceReturnDialog = ({ open, onClose, invoice, onReturn }) => {

    const middleware = useMiddleware('ceremonies/reservation.invoices');

    const [returning, setReturning] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        items: [],
        returnedAt: new Date(),
        totalDamage: '0',
        totalPaid: '0'
    });

    useEffect(() => {
        if (invoice) {
            setFormData({
                ...formData,
                returnedAt: new Date(),
                ...(pick(invoice, ['id'])),
                items: (invoice.items || []).filter(itm => !itm.isCompletelyReturned).map(itm => ({
                    ...itm,
                    returnedQuantity: itm.reservedQuantity - itm.totalReturned,
                    damagedQuantity: 0
                }))
            })
        }
    }, [invoice])

    useEffect(() => {
        setFormData({
            ...formData,
            totalDamage: sumBy(
                formData.items,
                i => i.item.damagePrice ? i.item.damagePrice * Number(i.damagedQuantity) : 0
            )
        })
    }, [formData.items])


    const { enqueueSnackbar } = useSnackbar();

    const handleReturn = async (e) => {
        e.preventDefault();

        setReturning(true)
        const response = await middleware.return(invoice.id, formData);
        if (response.ok) {
            enqueueSnackbar('صورت‌حساب برگشت خورد.');
            setReturning(false);
            onClose();
            onReturn(response.invoice);
        }
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            component="form"
            onSubmit={handleReturn}
        >
            <DialogTitle>
                برگشت صورتحساب
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <Grid container spacing={1}>
                    <Grid item md={2}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            size="small"
                            label="شماره"
                            type="number"
                            dir="ltr"
                            inputProps={{
                                readOnly: true
                            }}
                            value={invoice?.number}
                            onChange={() => { }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <LocalizationProvider dateAdapter={AdapterJalali}>
                            <DatePicker
                                mask="____/__/__"
                                disablePast
                                label="تاریخ برگشت"
                                renderInput={
                                    (params) => (
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth={true}
                                            size="small"
                                            {...params}
                                        />
                                    )
                                }
                                value={formData.returnedAt}
                                onChange={(newValue) => setFormData({ ...formData, returnedAt: newValue })}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid container spacing={1} item md={7}>
                        <Grid item md={6}>
                            <NumberTextField
                                fullWidth={true}
                                size="small"
                                label="مبلغ خسارت"
                                dir="ltr"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                }}
                                value={formData.totalDamage}
                                onChange={(e) => setFormData({ ...formData, totalDamage: e.target.value })}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <NumberTextField
                                fullWidth={true}
                                size="small"
                                label="پرداختی"
                                dir="ltr"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>
                                }}
                                value={formData.totalPaid}
                                onChange={(e) => setFormData({ ...formData, totalPaid: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <InvoiceContainer
                items={formData.items}
                onItemChange={
                    (index, name, value) => setFormData({
                        ...formData,
                        items: formData.items.map(
                            (itm, idx) => idx === index ?
                                { ...itm, [name]: value } :
                                itm
                        )
                    })
                }
            />
            <Divider />
            <DialogActions>
                <LoadingButton
                    loading={returning}
                    loadingPosition="start"
                    startIcon={<ReturnIcon />}
                    type="submit"
                    variant="contained"
                    autoFocus
                >
                    تایید و برگشت
                </LoadingButton>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvoiceReturnDialog