import WorkspacesMenu from "./WorkspacesMenu";
import RenewBundleDialog from "./RenewBundleDialog";
import WelcomeDialog from "./WelcomeDialog";

const components = {
    WorkspacesMenu,
    RenewBundleDialog,
    WelcomeDialog,
}

export default components