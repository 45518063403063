import axios, { AxiosError } from "axios";
import Middleware from "../../../foundation/Middleware";

class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        createOrUpdate: '/transactions',
        markPaid: '/transaction/:transactionId/markPaid'
        //
    }

    /**
     * Create new or update an exsisting transaction
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Settle an exsisting transaction
     *  
     * @return Promise
     */
    async markPaid(transactionId) {
        try {
            const response = await axios.post(
                this.replaceParams(this.paths.markPaid, { transactionId })
            );
            return { ...response.data };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;