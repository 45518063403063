import Warning from "@mui/icons-material/Warning";
import {
    Box, Link as MuiLink, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination, TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useMiddleware, useTask } from "../../../../Application";
import DetailedDate from "../../../../components/DetailedDate";
import TableLoader from "../../../../components/TableLoader";
import { useTabs } from "../../../../tabs";


const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.hover : theme.palette.common.white,
    '&:focus': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ShippedInvoicesTable = () => {

    const [page, setPage] = useState(0);

    const middleware = useMiddleware('ceremonies/shipment.clients');

    const { open: openTab } = useTabs();

    const taskClientDetails = useTask('clients.details');

    const taskInvoiceDetails = useTask('invoices.details');

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [shipments, setShipments] = useState([]);

    const _loadDataAsync = async () => {
        const response = await middleware.shipped(params.clientId, { page });
        if (response.ok) {
            setShipments(response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        _loadDataAsync();
    }, [params.clientId]);


    if (loading) {
        return (
            <TableLoader />
        )
    }

    if (shipments.length < 1)
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <Warning fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )

    return (
        <Box>
            <TableContainer>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ردیف</TableCell>
                            <TableCell align="center">تاریخ رزرو</TableCell>
                            <TableCell align="center">صورت‌حساب</TableCell>
                            <TableCell width={200}>مشتری</TableCell>
                            {/* <TableCell width={150} align="center">هزینه سرویس</TableCell> */}
                            <TableCell>آدرس</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shipments.map((shipment, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center"><DetailedDate date={shipment.invoice.reservedAt}/></TableCell>
                                    <TableCell align="center">
                                        <MuiLink
                                            color="inherit"
                                            onClick={() => navigate(
                                                '/invoice/'.concat(shipment.invoice.id),
                                                { state: { prev: location } }
                                            )}
                                        >
                                            {shipment.invoice.number}
                                        </MuiLink>
                                    </TableCell>
                                    <TableCell>
                                        <MuiLink
                                            color="inherit"
                                            onClick={() => navigate(
                                                '/client/'.concat(shipment.invoice.client.id),
                                                { state: { prev: location } }
                                            )}
                                        >
                                            {shipment.invoice.client.fullName}
                                        </MuiLink>
                                    </TableCell>
                                    {/* <TableCell align="center">{pricify(relatedShipment(shipment).price)}</TableCell> */}
                                    <TableCell>{shipment.invoice.shippingAddress ? shipment.invoice.fullShippingAddress : '-'}</TableCell>
                                </StyledTableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={shipments.length}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={setPage}
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} از مجموع ${count !== -1 ? count : `بیشتر از ${to}`} ردیف`}
            />
        </Box>
    )
}

export default ShippedInvoicesTable;