import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Button, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Stack, styled, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, useMediaQuery, useTheme } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import isFunction from "lodash/isFunction";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Authorized } from "../auth";
import BottomSheet from "./BottomSheet";
import ListLoader from "./ListLoader";
import TableLoader from "./TableLoader";
import Tooltip from "./Tooltip";


const StyledTableRow = styled(TableRow, {
    shouldForwardProp: prop => !["striped", "selected", "hover", "disabled"].includes(prop)
})(({ theme, striped, hover, selected, disabled }) => ({
    '&:nth-of-type(odd)': striped && {
        backgroundColor: theme.palette.action.hover,
        color: 'red'
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    ...(hover && { cursor: "pointer" }),
    ...(selected && { backgroundColor: "rgba(0, 103, 165, 0.08)" }),
    ...(disabled && { backgroundColor: theme.palette.grey[200] })
}));

const DetailsBottomSheet = ({ open, onClose, row, definitions, renderTitle, actions = [] }) => {
    return (
        <BottomSheet open={open} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{renderTitle(row)}</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ px: 0 }}>
                <List disablePadding>
                    {definitions.map((def, index) => {

                        if (!def.render && !def.renderList && !def.field)
                            return null;

                        return (
                            <ListItem divider key={index}>
                                <ListItemText
                                    primary={def.renderList ? def.renderList(row) : (def.render ? def.render(row) : row[def.field])}
                                    secondary={def.title}
                                />
                            </ListItem>
                        )
                    })}
                </List>
                {actions.length > 0 && (
                    <List disablePadding>
                        {map(actions, (action, index) => {
                            action = isFunction(action) ? action(row) : action;

                            if(!action) return undefined;

                            return (
                                <ListItem divider disablePadding key={index}>
                                    <ListItemButton onClick={e => action.onClick(row)}>
                                        <ListItemIcon>
                                            {action.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={action.title}
                                            secondary={action.description}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                )}
            </DialogContent>
        </BottomSheet>
    )
}

const ResponsiveTable = ({
    tableProps = {},
    listProps = {},
    data,
    loading,
    definitions,
    renderListItemChildren,
    renderSheetTitle = row => { },
    paginationProps,
    actions = [],
    tableRowProps = {},
    listItemProps = {},
    infiniteScrollProps
}) => {

    const theme = useTheme()
    const isResponsive = useMediaQuery(theme.breakpoints.down('sm'));

    const [bottomSheetProps, setBottomSheetProps] = useState({ open: false, row: {} });

    const [sentryRef] = useInfiniteScroll(infiniteScrollProps);


    if (loading) {
        return isResponsive ? (<ListLoader length={10} />) : (<TableLoader length={10} />)
    }

    if (data.length < 1)
        return (
            <Box sx={{ px: 5, py: 15, textAlign: "center" }}>
                <SvgIcon component={Icon} icon="solar:notes-minimalistic-bold-duotone" sx={{ fontSize: "5rem" }} />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )

    return isResponsive ? (
        <Box>
            <List disablePadding {...listProps}>
                {data.map((row, index) => (
                    <ListItem
                        key={index}
                        disablePadding
                        divider
                        secondaryAction={(
                            <IconButton edge="end" onClick={(e) => {
                                e.stopPropagation();
                                setBottomSheetProps({ open: true, row })
                            }}>
                                <InfoOutlined />
                            </IconButton>
                        )}
                        {...listItemProps}
                    >
                        {renderListItemChildren(row, index)}
                    </ListItem>
                ))}
                {(infiniteScrollProps.loading || infiniteScrollProps.hasNextPage) && (
                    <ListItem ref={sentryRef}>
                        <ListItemText
                            primary={<Skeleton variant="text" width="50%" />}
                            secondary={<Skeleton variant="text" />}
                        />
                    </ListItem>
                )}
            </List>

            <DetailsBottomSheet
                {...bottomSheetProps}
                actions={actions}
                definitions={definitions}
                renderTitle={renderSheetTitle}
                onClose={() => setBottomSheetProps({ open: false, row: {} })}
            />
        </Box >
    ) : (
        <Box>
            <TableContainer>
                <TableContainer>
                    <Table stickyHeader {...tableProps}>
                        <TableHead>
                            <TableRow>
                                {definitions.map((def, index) => {

                                    if (!def.render && !def.renderCell && !def.field)
                                        return null;

                                    return (
                                        <TableCell key={index} {...def.headerCellProps}>
                                            {def.title}
                                        </TableCell>
                                    )
                                })}
                                {actions.length > 0 && <TableCell />}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <StyledTableRow
                                    key={index}
                                    {...(typeof tableRowProps === "function" ? tableRowProps(row) : tableRowProps)}
                                >
                                    {definitions.map((def, defIndex) => {

                                        if (!def.render && !def.renderCell && !def.field)
                                            return null;

                                        return (
                                            <TableCell key={defIndex} {...def.rowCellProps}>
                                                {def.renderCell ? def.renderCell(row, index) : (def.render ? def.render(row, index) : row[def.field])}
                                            </TableCell>
                                        )
                                    })}
                                    {actions.length > 0 && (
                                        <TableCell align="center">
                                            <Stack 
                                                justifyContent="center" 
                                                alignItems="center" 
                                                flexWrap="wrap" 
                                                flexDirection="row" 
                                                gap={0.5}
                                            >
                                                {map(actions, (action, index) => {
                                                    action = isFunction(action) ? action(row) : action;

                                                    if(!action) return undefined;
                                                    
                                                    return (
                                                        <Authorized key={index} authorize={action.authorize}>
                                                                {action.variant === "icon" ? (
                                                                    <Tooltip arrow placement="top" title={action.title} key={index}>
                                                                        <IconButton onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            action.onClick(row);
                                                                        }}>
                                                                            {action.icon}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : (  
                                                                    <Button 
                                                                        key={index}
                                                                        startIcon={action.icon}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            action.onClick(row);
                                                                        }}
                                                                        {...({
                                                                            size: "small",
                                                                            variant: "contained",
                                                                            color: "inherit",
                                                                            ...action.componentProps,
                                                                        })}
                                                                    >
                                                                        {action.title}
                                                                    </Button>
                                                                )}
                                                        </Authorized>
                                                    )
                                                })}
                                            </Stack>
                                        </TableCell>
                                    )}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {paginationProps && (
                    <TablePagination
                        component="div"
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => `${from}–${to} از مجموع ${count !== -1 ? count : `بیشتر از ${to}`} ردیف`}
                        showFirstButton
                        showLastButton
                        {...paginationProps}
                    />
                )}
            </TableContainer>
        </Box>
    )
}

ResponsiveTable.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    definitions: PropTypes.array.isRequired,
    renderListItemChildren: PropTypes.func.isRequired,
    infiniteScrollProps: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        hasNextPage: PropTypes.bool.isRequired,
        onLoadMore: PropTypes.func.isRequired
    })
}

export default ResponsiveTable;