import { lazy } from "react";
import Loadable from "../../components/Loadable";


const Accounts = Loadable(lazy(() => import('./pages/Accounts/Accounts')));
const Timeline = Loadable(lazy(() => import('./pages/Timeline/Timeline')));
const Categories = Loadable(lazy(() => import('./pages/Categories/Categories')));
const NewTransaction = Loadable(lazy(() => import('./pages/NewTransaction/NewTransaction')));


const routes = [
	{
		variant: "dashboard",
		path: '/accounts',
		element: <Accounts />,
		private: true,
	},
	{
		variant: "dashboard",
		path: '/categories',
		element: <Categories />,
		private: true,
	},
	{
		variant: "dashboard",
		path: '/transactions/new',
		element: <NewTransaction />,
		private: true,
	},
	{
		variant: "dashboard",
		path: '/transactions',
		element: <Timeline />,
		private: true,
	},
	{
		variant: "dashboard",
		path: '/transactions/:class',
		element: <Timeline />,
		private: true,
	},
];

export default routes;