import { Grid } from "@mui/material";
import sumBy from "lodash/sumBy";
import InvoiceShipping from "../components/InvoiceShipping";

const calculateDeliveryFactor = (factors, shipments) => {

    const factor = {
        title: 'هزینه باربری',
        type: 'ENHANCER',
        amount: sumBy(shipments, s => Number(s.price)),
        unit: 'PRICE'
    };
    if (factors.filter(f => f.title === 'هزینه باربری').length > 0) {
        return factors.map((f) => f.title === 'هزینه باربری' ? ({
            ...f,
            amount: Number(factor.amount)
        }) : f)
    } else {
        return [...factors, factor];
    }
}

export const InvoiceFormShipments = ({ formData, setFormData }) => {

    return (
        <InvoiceShipping
            value={{
                shippingAddress: formData.shippingAddress,
                shipments: formData.shipments
            }}
            onChange={data => {
                setFormData({
                    ...formData,
                    ...data,
                    factors: calculateDeliveryFactor(formData.factors, data.shipments)
                })
            }}
        />
    )
}