import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class AuthenticatedMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Authenticated';

    /**
     * The middleware name
     *
     * @var string
     */
    basePath = '/self';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        access: '/access',
        workspaces: '/workspaces',
        setAsCurrent: '/workspaces/:workspaceId/setAsCurrent',
    }

    /**
     * Get a list of user access roles and persmissions
     * 
     * @param {*} args 
     * @return Promise 
     */
    async getAccess() {
        try {
            const response = await axios(this.paths.access);

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get a list of user workspaces
     * 
     * @param {*} args 
     * @return Promise 
     */
    async workspaces() {
        try {
            const response = await axios(this.paths.workspaces);

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Change workspace
     * 
     * @param {*} args 
     * @return Promise 
     */
    async setAsCurrent(workspaceId) {
        try {
            const response = await axios({
                method: 'post',
                url: this.replaceParams(this.paths.setAsCurrent, { workspaceId })
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default AuthenticatedMiddleware;