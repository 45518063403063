import { useMediaQuery, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import BottomSheet from "./BottomSheet";


const ResponsiveDialog = ({
    open,
    onClose,
    children,
    dialogProps = {},
    drawerProps = {},
}) => {

    const theme = useTheme()
    const isResponsive = useMediaQuery(theme.breakpoints.down('sm'));


    return isResponsive ? (
        <BottomSheet
            open={open}
            onClose={onClose}
            {...drawerProps}
        >
            {children}
        </BottomSheet>
    ) : (
        <Dialog
            open={open}
            onClose={onClose}
            transitionDuration={{
                exit: 0,
                enter: theme.transitions.duration.enteringScreen
            }}
            {...dialogProps}
        >
            {children}
        </Dialog>
    )
}

export default ResponsiveDialog;