/* eslint-disable react-hooks/rules-of-hooks */
import CategoryOutlined from "@mui/icons-material/CategoryOutlined";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment } from "react";
import Text from "../../../components/Text";
import useDialogProps from "../../../utils/useDialogProps";
import CategoriesDialog from "../components/CategoriesDialog";

const People = {
    'PeopleList.Hooks': injectedParams => ({
        'categoriesFormHook': useDialogProps("categories")
    }),
    'PeopleList.ActionsList': ({ categoriesFormHook }) => {
        const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesFormHook;

        return {
            title: 'مدیریت گروه‌ها',
            icon: <CategoryOutlined fontSize="inherit" />,
            onClick: () => openCategoriesDialog()
        }
    },
    'PeopleList.Footer': ({ categoriesFormHook, key }) => {
        const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesFormHook;

        return (
            <Fragment key={key}>
                <CategoriesDialog
                    {...categoriesDialogProps}
                    label="client"
                />
            </Fragment>
        )
    },
    'PersonForm.Hooks': injectedParams => ({
        'categoriesDialogHook': useDialogProps()
    }),
    'PersonForm.PostForm': ({ getValue, categoriesDialogHook, key }) => {
        const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesDialogHook;

        return (
            <Fragment key={key}>
                <Divider />
                <List disablePadding dense>
                    <ListItem disablePadding dense>
                        <ListItemButton onClick={() => openCategoriesDialog()}>
                            <ListItemIcon>
                                <CategoryOutlined />
                            </ListItemIcon>
                            <ListItemText
                                primary={getValue('categories') && getValue('categories').length > 0 ? getValue('categories').map(r => r.name).join(', ') : <small>برای انتخاب کلیک کنید</small>}
                                secondary="گروه‌ها"
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Fragment>
        )
    },
    'PersonForm.initialValues': () => ({
        categories: []
    }),
    'PersonForm.Footer': ({ getValue, setValue, categoriesDialogHook, key }) => {
        const [categoriesDialogProps, openCategoriesDialog, closeCategoriesDialog] = categoriesDialogHook;

        return (
            <Fragment key={key}>
                <CategoriesDialog
                    {...categoriesDialogProps}
                    selectable
                    multiple
                    selected={getValue("categories") || []}
                    onClear={() => setValue("categories", [])}
                    label="client"
                    onSelect={cat => setValue(
                        "categories",
                        getValue("categories").filter(c => c.id === cat.id).length > 0 ?
                            getValue("categories").filter(c => c.id !== cat.id) :
                            [...getValue("categories"), cat]
                    )}
                />
            </Fragment>
        )
    },
    'PersonDetails.Overview.DataList': ({ person, loading }) => ({
        title: 'گروه‌ها',
        description: person.categories ? <Text>{person.categories.map(c => c.name).join(', ')}</Text> : '-'
    }),

}

export default People;