import forOwn from "lodash/forOwn";

class Module {

    /**
     * The module application
     * 
     * @var Application
     */
    app;

    /**
     * The module name
     * 
     * @var string
     */
    name;

    /**
     * The module version
     *
     * @var string
     */
    version;

    /**
     * The module base path
     * 
     * use for routing 
     * 
     * @var string
     */
    basePath;

    /**
     * All of the registered routes
     *
     * @var array
     */
    routes = [];

    /**
     * The module drawer items
     * 
     * @var array
     */
    drawerItems = [];

    /**
     * The module tasks
     * 
     * @var array
     */
    tasks = {};

    /**
     * The module middlewares
     * 
     * @var array
     */
    middlewares = [];

    /**
     * The module injections
     * 
     * @var array
     */
    injections = {};

    /**
     * Create the module instance
     *
     * @var array
     */
    constructor(app) {
        this.app = app;
    }

    /**
     * Register the module
     *
     * @var array
     */
    register() {
        //
    }

    /**
     * Boot the module
     *
     * @var array
     */
    boot() {
        //
    }

    /**
     * Register the module middlewares
     * 
     * @return void
     */
    registerMiddlewares(middlewares) {
        middlewares.forEach(Middleware => {
            const middleware = new Middleware(this);
            middleware.registerPaths();
            this.middlewares = [...this.middlewares, middleware];
        })
    }

    /**
     * Register the module tasks
     * 
     * @return void
     */
    registerTasks(tasks) {
        const self = this;
        forOwn(tasks, (value, key) => {
            this.tasks[key] = typeof (value) === 'function' ? (args) => ({
                ...value(args),
                path: self.joinPaths(this.basePath, value(args).path)
            }) : {
                ...value,
                path: self.joinPaths(this.basePath, value.path)
            }
        })
    }

    /**
     * Register the module drawer items
     * 
     * @return void
     */
    registerRoutes(routes) {
        const _routes = typeof (routes) == 'function' ? routes(this) : routes;
        this.routes = _routes.map(item => ({
            ...item,
            path: this.joinPaths(this.basePath, item.path)
        }))
    }

    /**
     * Register the module injections
     * 
     * @return void
     */
    registerInjections(injections) {
        const self = this;
        forOwn(injections, (value, key) => {
            self.injections = {
                ...self.injections,
                [key]: [...(self.injections[key] || []), value]
            }
        })
    }

    /**
     * join multiple paths
     * 
     * @param string[] paths
     * @return string
     */
    joinPaths(...paths) {
        function resolve(path) {
            if (path === '') return path;

            let modified = path;

            if (modified.charAt(0) === '/') {
                modified = modified.slice(1)
            }
            if (modified.charAt(path.length - 1) === '/') {
                modified = modified.slice(0, -1)
            }

            return modified;
        }

        return '/'.concat(paths.map(resolve).filter(Boolean).join('/'));
    }

    /**
     * Inject a function in specific place of module
     * 
     * @param {String} hookName hook name
     * @param {Object} args extra arguments
     * @returns Function
     */
    inject(hookName, args) {
        if (
            Object.keys(this.injections).length === 0 ||
            Object.keys(this.injections).map(
                hook => String(hook).toLowerCase() === String(hookName).toLowerCase()
            ).filter(Boolean).length < 1
        ) return null;

        return this.injections[hookName].map(
            (render, index) => render({ ...args, key: index })
        );
    }

    /**
     * Get the module specific middleware
     *
     * @param string middlewareName
     * @return Middleware
     */
    middleware(middlewareName) {
        return this.middlewares.filter(
            middleware => String(middleware.name).toLowerCase() === String(middlewareName).toLowerCase()
        )[0]
    }

    /**
     * Get the module specific task
     *
     * @param string taskKey
     * @return Object task object
     */
    task(taskKey) {
        return this.tasks[taskKey];
    }
}

export default Module;