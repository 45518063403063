import { Icon as Iconify } from "@iconify/react";

const tasks = {
    manage: {
        title: `مدیریت محصولات`,
        Icon: props => <Iconify {...props} icon='solar:box-bold-duotone'/>,
        path: `/products`,
        keywords: ['تستی'],
        default: true,
        subtasks: [{
            title: 'حذف محصول',
            Icon: null,
            path: '/products',
            keywords: []
        }, {
            title: 'افزودن محصول',
            Icon: null,
            path: '/products',
            keywords: ['ثبت محصول', 'ثبت مشخصات محصول', 'افزودن مشخصات محصول'],
            state: { productFormDialogOpen: true }
        }, {
            title: 'ویرایش محصول',
            Icon: null,
            path: '/products',
            keywords: ['اصلاح محصول', 'ویرایش مشخصات محصول', 'اصلاح مشخصات محصول']
        }, {
            title: 'مدیریت دسته‌ها',
            Icon: null,
            path: '/products',
            keywords: ['مدیریت دسته ها', 'مدیریت گروه ها', 'دسته‌بندی', 'دسته بندی ها'],
            state: { categoriesDialogOpen: true }
        }]
    },
    details: (product) => ({
        title: product?.name,
        Icon: props => <Iconify {...props} icon='solar:box-bold-duotone'/>,
        path: '/products/'.concat(product?.id)
    })
}

export default tasks;