import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class CategoriesMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Categories';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        totalTransactions: '/category/:categoryId/total-transactions',
        categoryCountRatio: '/category/:categoryId/category-count-ratio',
        totalCountRatio: '/category/:categoryId/total-count-ratio',
        totalIncome: '/category/:categoryId/total-income',
        totalIncomeRatio: '/category/:categoryId/total-income-ratio',
        categoryIncomeRatio: '/category/:categoryId/category-income-ratio',
        totalExpense: '/category/:categoryId/total-expense',
        totalExpenseRatio: '/category/:categoryId/total-expense-ratio',
        categoryExpenseRatio: '/category/:categoryId/category-expense-ratio',
    }

    /**
     * Get category total transactions
     *  
     * @return Promise
     */
    async totalTransactions(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalTransactions, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category count ratio
     *  
     * @return Promise
     */
    async categoryCountRatio(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.categoryCountRatio, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category total transactions
     *  
     * @return Promise
     */
    async totalCountRatio(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalCountRatio, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category total income
     *  
     * @return Promise
     */
    async totalIncome(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalIncome, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category category income ratio
     *  
     * @return Promise
     */
    async categoryIncomeRatio(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.categoryIncomeRatio, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category total income ratio
     *  
     * @return Promise
     */
    async totalIncomeRatio(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalIncomeRatio, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category total expense
     *  
     * @return Promise
     */
    async totalExpense(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalExpense, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category category expense ratio
     *  
     * @return Promise
     */
    async categoryExpenseRatio(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.categoryExpenseRatio, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get category total expense ratio
     *  
     * @return Promise
     */
    async totalExpenseRatio(categoryId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalExpenseRatio, { categoryId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default CategoriesMiddleware;