import { lazy } from "react";
import Loadable from "../../components/Loadable";


const Products = Loadable(lazy(() => import('./pages/Products/Products')));
const ProductDetails = Loadable(lazy(() => import('./pages/ProductDetails/ProductDetails')));
const ProductOverview = lazy(() => import('./pages/ProductDetails/ProductOverview'));


const routes = module => {
	return [
		{
			variant: "dashboard",
			path: '/products',
			element: <Products />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("products:view");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/products/:productId',
			element: <ProductDetails />,
			children: [
				{ variant: "dashboard", private: true, index: true, element: <ProductOverview /> },
				...(module.inject('routes.products.details') || [])
			],
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("product:view");
			}
		},
	]
};

export default routes;