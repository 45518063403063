import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class AuthenticatedMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Authenticated';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/self';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/tickets',
        // create: '/auth/tickets',
    }

    /**
     * Get list of categories match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args, config) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args), config);
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Create or update an existing category
     *  
     * @return Promise
     */
    // async createOrUpdate(data) {
    //     try {
    //         const response = await axios({
    //             method: 'post',
    //             url: this.paths.create,
    //             data
    //         });

    //         return {
    //             ...response.data,
    //         };
    //     } catch (error) {
    //         if (error.response.status === 422) {
    //             const { status } = error.response;
    //             return {
    //                 ...error.response.data,
    //                 status
    //             }
    //         }
    //         throw new AxiosError(error)
    //     }
    // }

    // /**
    //  * Delete an existing category from db
    //  *  
    //  * @return Promise
    //  */
    // async details(ticketId) {
    //     try {
    //         const response = await axios(this.replaceParams(this.paths.details, { ticketId }));
    //         return response.data;
    //     } catch (error) {
    //         throw new AxiosError(error)
    //     }
    // }
}

export default AuthenticatedMiddleware;