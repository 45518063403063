import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMiddleware } from "../../../Application";
import DataListBox from "../../../components/DataListBox";
import LoadableData from "../../../components/LoadableData";
import Text from "../../../components/Text";
import pricify from "../../../utils/pricify";
import SettleConfirmation from "../components/ClientSettleConfirmation";
import TransactionFormDialog from "../components/TransactionFormDialog";


export const ClientDetailsFooter = ({ person, loading, reload, transactionFormHook, settleFormHook }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [transactionFormDialogProps, openTransactionFormDialog, closeTransactionformDialog] = transactionFormHook;
    const handleAddTransaction = (transaction) => {
        enqueueSnackbar('رویداد مالی ثبت گردید.');
        closeTransactionformDialog();
        reload();
    }

    const [settleFormDialogProps, openSettleFormDialog, closeSettleformDialog] = settleFormHook;
    const handleSettle = () => {
        enqueueSnackbar('تسویه حساب انجام گردید.');
        closeSettleformDialog();
        reload();
    }


    return (
        <>
            <TransactionFormDialog
                {...transactionFormDialogProps}
                defaultValues={{ person }}
                onCreateOrUpdate={handleAddTransaction}
            />

            <SettleConfirmation
                {...settleFormDialogProps}
                person={person}
                onSettle={handleSettle}
            />
        </>
    )
}


export const ClientDetailsOverview = ({ person, loading }) => {
    const middleware = useMiddleware('Transactions.People');

    return (
        <>
            <DataListBox
                title="امور مالی"
                loading={loading}
                data={[
                    {
                        title: 'مجموع بدهکار',
                        description: (
                            <Box>
                                <LoadableData
                                    loadData={() => middleware.totalDept(person.id)}
                                    getData={response => response.totalDept}
                                    renderData={data => <Text>{pricify(data)}</Text>}
                                />
                                {` `}
                                <small>ریال</small>
                            </Box>
                        )
                    },
                    {
                        title: 'مجموع بستانکار',
                        description: (
                            <Box>
                                <LoadableData
                                    loadData={() => middleware.totalCredit(person.id)}
                                    getData={response => response.totalCredit}
                                    renderData={data => <Text>{pricify(data)}</Text>}
                                />
                                {` `}
                                <small>ریال</small>
                            </Box>
                        )
                    },
                    {
                        title: 'وضعیت حساب',
                        description: (
                            <Box>
                                <LoadableData
                                    loadData={() => middleware.totalBalance(person.id)}
                                    getData={response => response.totalBalance}
                                    renderData={data => <Text>{pricify(Math.abs(data))}</Text>}
                                />
                                {` `}
                                <small>ریال</small>
                                {` `}
                                {person.totalBalance > 0 ? 'بدهکار' : 'بستانکار'}
                            </Box>
                        )
                    }
                ]}
            />
        </>
    )
}