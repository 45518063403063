import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box, DialogActions,
    DialogTitle, Divider, Grid, IconButton
} from "@mui/material";
import { useEffect } from "react";
import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import TextField from "../../../components/TextField";
import useForm from "../../../utils/useForm";


const ProductFormDialog = ({
    open,
    onClose,
    product,
    onCreateOrUpdate,
    defaultValues = {},
}) => {

    const { inject } = useAuth();

    const middleware = useMiddleware('Products.Main');

    const initialValues = {
        code: '',
        name: '',
        description: '',
        ...Object.assign({}, ...(inject('Products', 'ProductForm.initialValues') || [])),
        ...defaultValues
    }

    const {
        register,
        errors,
        handleSubmit,
        loading,
        reset,
        getValue,
        setValue
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate(data),
        onSubmit: onCreateOrUpdate
    })

    const initializeFormAsync = async () => {
        let code = defaultValues.code;
        if (!defaultValues.id) {
            const response = await middleware.getNewCode();
            code = response.code;
        }
        reset({ ...initialValues, code });
    }

    useEffect(() => {
        if (open) initializeFormAsync();
    }, [open]);

    const injectedHooks = Object.assign({}, ...(inject('Products', 'ProductForm.Hooks') || []));


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <form noValidate onSubmit={handleSubmit}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>{product ? 'بروزرسانی مشخصات محصول' : 'ثبت مشخصات محصول'}</Box>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Box sx={{ p: 0 }}>
                    {inject('Products', 'ProductForm.PreForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={2}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    fullWidth={true}
                                    size="small"
                                    label="کد محصول"
                                    type="number"
                                    variant="filled"
                                    dir="ltr"
                                    {...register("code")}
                                />
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    label="شرح محصول"
                                    variant="filled"
                                    required
                                    {...register("name")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    label="توضیحات"
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    {...register("description")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {inject('Products', 'ProductForm.PostForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                    <Divider />
                    <Grid container>
                        {inject('Products', 'ProductForm.PostFormGrid', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                    </Grid>
                </Box>
                <Divider />
                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                    >
                        ثبت مشخصات
                    </LoadingButton>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
                </DialogActions>
            </form>

            {inject('Products', 'ProductForm.Footer', { register, loading, getValue, setValue, reset, ...injectedHooks })}
        </ResponsiveDialog>
    )
}

export default ProductFormDialog