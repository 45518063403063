import flatten from "lodash/flatten";
import map from "lodash/map";
import { useEffect, useState } from "react";
import { matchPath } from "react-router";
import { useApplication, useMiddleware } from "../Application";
import { useAuth } from "../auth";
import TaskbarContext from "./TaskbarContext";


const TaskbarProvider = ({ children }) => {

    const { application } = useApplication();
    const auth = useAuth();
    const middleware = useMiddleware('Core.membership');

    const [tasks, setTasks] = useState([]);


    useEffect(() => {
        const resolve = module => {
            const identifier = String(module.name).toLowerCase();
            if (identifier === 'core') {
                return true;
            }
            return auth.workspace.subscriptions.map(s => s.subscribed.identifier).includes(identifier)
        }

        setTasks(
            flatten(
                map(
                    application.modules.filter(resolve),
                    module => Object.values(module.tasks).filter(task => {
                        return typeof (task) !== 'function' && task.path
                    }).map(task => ({ ...task, module: module.name }))
                )
            ).filter(task => {
                let route = application.routes.filter(
                    r => r && r.path && matchPath({ path: r.path }, task.path)
                )[0];

                if (!route)
                    return false;

                if (route.authorize) {
                    return route.authorize(auth);
                }

                return true;
            })
        )
    }, [auth]);

    const toggle = async path => {
        const { workspace, update } = auth;

        const tabBarPins = workspace.membership.tabBarPins.includes(path) ?
            workspace.membership.tabBarPins.filter(t => t !== path) :
            [...workspace.membership.tabBarPins, path];

        await middleware.taskbar({ tabBarPins });

        update('workspace.membership.tabBarPins', tabBarPins);
    }
    

    return (
        <TaskbarContext.Provider value={{ tasks, toggle }}>
            {children}
        </TaskbarContext.Provider>
    )
}

export default TaskbarProvider;