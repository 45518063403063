import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class AccountsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Accounts';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/accounts',
        createOrUpdate: '/accounts',
        delete: '/accounts/:accountId',
    }

    /**
     * Get list of accounts match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(
                this.serializePath(this.paths.list, args)
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Create or update an existing account
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            const err = new AxiosError(error);
            if (err.response.status === 422) {
                const { status, statusText } = err.response;
                return {
                    ...err.response.data,
                    status,
                    statusText
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing account from db
     *  
     * @return Promise
     */
    async delete(accountId) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { accountId })
            });
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default AccountsMiddleware;