import CloseIcon from "@mui/icons-material/CloseOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box, Button, DialogActions,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useComponent, useMiddleware } from "../../../../Application";
import { useAuth } from "../../../../auth";
import ResponsiveDialog from "../../../../components/ResponsiveDialog";
import TextField from "../../../../components/TextField";
import useForm from "../../../../utils/useForm";


const CaseFormDialog = ({
    open,
    onClose,
    defaultValues = {},
    onCreateOrUpdate = () => { }
}) => {

    const { inject } = useAuth();

    const middleware = useMiddleware('Lawyers/Case-Management.Main');

    const initialValues = {
        code: '',
        subject: '',
        client: '',
        description: '',
        categories: [],
        ...defaultValues
    }

    const {
        register,
        loading,
        handleSubmit,
        reset,
        getValue,
        setValue
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate(data),
        onSubmit: onCreateOrUpdate
    })

    const initializeFormAsync = async () => {
        let code = defaultValues.code;
        if (!defaultValues.id) {
            const response = await middleware.getNewCode();
            code = response.code;
        }
        reset({ ...initialValues, code });
    }

    useEffect(() => {
        if (open) initializeFormAsync();
    }, [open]);


    const PersonSelectDialog = useComponent('People.PersonSelectDialog');
    const [personSelectDialogProps, setPersonSelectDialogProps] = useState({ open: false });

    const injectedHooks = Object.assign({}, ...(inject('lawyers/case-management', 'CaseForm.Hooks') || []));


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm",
                transitionDuration: 0
            }}
        >
            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>{defaultValues.id ? 'بروزرسانی مشخصات پرونده' : 'ثبت مشخصات پرونده'}</Box>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Box sx={{ p: 0 }}>
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    variant="filled"
                                    size="small"
                                    label="شماره"
                                    type="number"
                                    dir="ltr"
                                    {...register("code")}
                                />
                            </Grid>
                            <Grid item xs={8} md={10}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    variant="filled"
                                    label="موضوع"
                                    required
                                    {...register("subject")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <List disablePadding dense>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => setPersonSelectDialogProps({ open: true })}>
                                <ListItemIcon>
                                    <PersonOutline />
                                </ListItemIcon>
                                <ListItemText
                                    primary={getValue("client") ? getValue("client")?.fullName : <small>برای انتخاب کلیک کنید.</small>}
                                    secondary="موکل"
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    {inject('lawyers/case-management', 'CaseForm.PostForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            size="small"
                            label="شرح پرونده"
                            multiline
                            variant="filled"
                            rows={3}
                            {...register("description")}
                        />
                    </Box>
                </Box>
                <Divider />
                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                    >
                        ثبت مشخصات
                    </LoadingButton>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Button type="button" onClick={onClose}>بستن</Button>
                </DialogActions>
            </form>

            <PersonSelectDialog
                {...personSelectDialogProps}
                roles={["client"]}
                onClose={() => setPersonSelectDialogProps({ open: false })}
                selected={getValue("client")}
                onSelect={client => {
                    setValue("client", client);
                    setPersonSelectDialogProps({ open: false });
                }}
                onClear={() => setValue("client", "")}
            />

            {inject('lawyers/case-management', 'CaseForm.Footer', { register, loading, getValue, setValue, reset, ...injectedHooks })}
        </ResponsiveDialog>
    )
}

CaseFormDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultValues: PropTypes.object
}

export default CaseFormDialog