import { styled, TextField as MuiTextField } from "@mui/material";
import { forwardRef } from "react";


const TextField = styled(
    forwardRef(
        ({ InputProps, ...props }, ref) => (
            <MuiTextField
                ref={ref}
                InputProps={{
                    ...InputProps,
                    ...(props.variant === "filled" ? {
                        disableUnderline: true
                    } : {})
                }}
                {...props}
            />
        )
    )
    , {
        shouldForwardProp: prop => prop !== 'error'
    })(({ theme, error }) => ({
        '& .MuiFilledInput-root': {
            border: '1px solid '.concat(error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)'),
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
            outline: '1px solid transparent',
            transition: theme.transitions.create([
                'outline',
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                outlineColor: error ? theme.palette.error.main : theme.palette.primary.main,
                // boxShadow: `${error ? theme.palette.error.main : theme.palette.primary.main} 0 0 0 1px`,
                borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
            },
        },
    }));

export default TextField;