import HandshakeIcon from "@mui/icons-material/Handshake";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useComponent } from "../../../../Application";
import useDialog from "../../../../utils/useDialog";


export const InvoiceFormAddProductPreField = ({ control, register, setValue }) => {

    const ClientSelectDialog = useComponent('Clients.ClientSelectDialog');

    const {
        isOpen: isSupplierSelectDialogOpen,
        isOpening: isSupplierSelectDialogOpening,
        open: openSupplierSelectDialog,
        close: closeSupplierSelectDialog
    } = useDialog();

    const bailer = useWatch({ control, name: "bailer" })

    return (
        <>
            <Grid item>
                <input
                    type="hidden"
                    value={bailer}
                    onChange={() => { }}
                />
                <Tooltip
                    title="محصولی امانی"
                    placement="top"
                >
                    <IconButton
                        onClick={() => openSupplierSelectDialog()}
                        disabled={isSupplierSelectDialogOpening}
                    >
                        {bailer ? <HandshakeIcon /> : <HandshakeOutlinedIcon />}
                    </IconButton>
                </Tooltip>
            </Grid>

            <ClientSelectDialog
                open={isSupplierSelectDialogOpen}
                onClose={closeSupplierSelectDialog}
                selected={bailer}
                roles={["bailer"]}
                onSelect={bailer => {
                    setValue("bailer", bailer);
                    closeSupplierSelectDialog();
                }}
                onClear={() => {
                    setValue("bailer", '');
                    closeSupplierSelectDialog();
                }}
            />
        </>
    )
}