import SettleIcon from "@mui/icons-material/CreditScoreOutlined";
import Warning from "@mui/icons-material/Warning";
import {
    Box, Button, Link, Stack, styled, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import format from "date-fns-jalali/format";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMiddleware } from "../../../../Application";
import TableLoader from "../../../../components/TableLoader";
import pricify from "../../../../utils/pricify";
import useDialog from "../../../../utils/useDialog";
import useGetRowNumber from "../../../../utils/useGetRowNumber";
import TransactionSettleConfirmation from "../../components/TransactionSettleConfirmation";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
}));


const InvoiceTransactions = ({
    invoice,
    onSettleTransaction
}) => {

    const middleware = useMiddleware('transactions.invoices')

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [isLoading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState({ data: [], total: '' });
    const [page, setPage] = useState(0);

    const _loadTransactionsAsync = async () => {
        if (!isLoading) setLoading(true);
        const { ok, data, total } = await middleware.transactions(params.invoiceId, { page });
        if (ok) {
            setTransactions({ data, total });
            setLoading(false);
        }
    }

    useEffect(() => {
        _loadTransactionsAsync();
    }, [params.clientId])

    const {
        isOpen: isSettleDialogOpen,
        isOpening: isSettleDialogOpening,
        open: openSettleDialog,
        close: closeSettleDialog,
        data: settleDialogTransaction
    } = useDialog();

    const getRowNumber = useGetRowNumber();


    if (isLoading) {
        return (
            <TableLoader size="small" />
        )
    }

    if (transactions.data.length < 1) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <Warning fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )
    }


    return (
        <TableContainer>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ردیف</TableCell>
                        <TableCell align="center">تاریخ ثبت</TableCell>
                        <TableCell>شخص</TableCell>
                        <TableCell align="center">مبلغ</TableCell>
                        <TableCell>بابت</TableCell>
                        <TableCell align="center">تاریخ پرداخت</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.data.map((transaction, index) => (
                        <StyledTableRow key={index}>
                            <TableCell align="center">{getRowNumber(index)}</TableCell>
                            <TableCell align="center">{transaction.createdAt && format(new Date(transaction.createdAt), 'yyyy/MM/dd')}</TableCell>
                            <TableCell>
                                <Link
                                    color="inherit"
                                    onClick={() => navigate(
                                        '/client/'.concat(transaction.client.id),
                                        { state: { prev: location } }
                                    )}
                                >
                                    {transaction.client.fullName}
                                </Link>
                            </TableCell>
                            <TableCell align="center">{pricify(transaction.amount)}</TableCell>
                            <TableCell>{transaction.note}</TableCell>
                            <TableCell align="center">{transaction.isPaid ? format(new Date(transaction.paidAt), 'yyyy/MM/dd') : '-'}</TableCell>
                            <TableCell>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="center"
                                >
                                    {!transaction.isPaid && (
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            startIcon={<SettleIcon fontSize="small" />}
                                            onClick={() => openSettleDialog(transaction)}
                                        >
                                            تسویه
                                        </Button>
                                    )}
                                </Stack>
                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>

            <TransactionSettleConfirmation
                open={isSettleDialogOpen}
                onClose={closeSettleDialog}
                transaction={settleDialogTransaction}
                onSettle={onSettleTransaction}
            />
        </TableContainer>
    )
}

export default InvoiceTransactions