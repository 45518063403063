import { lazy } from 'react';
import Loadable from '../../components/Loadable';
import ContractForm from "./pages/ContractForm/Main";

const ContractSamplesList = Loadable(lazy(() => import('./pages/ContractSamplesList/Main')));
const ContractSampleForm = Loadable(lazy(() => import('./pages/ContractSampleForm/Main')));
// const ContractSampleDetails = lazy(() => import('./pages/ContractSampleDetails/Main'));

const ContractsList = Loadable(lazy(() => import('./pages/ContractsList/Main')));
// const ContractForm = Loadable(lazy(() => import('./pages/ContractForm/Main')));
const ContractDetails = Loadable(lazy(() => import('./pages/ContractDetails/Main')));
const ContractDetailsContract = Loadable(lazy(() => import('./pages/ContractDetails/Contract')));



const routes = module => {

	const injections = module.inject('routes.contracts.details');

	return [
		{
			variant: "dashboard",
			private: true,
			path: '/contracts',
			element: <ContractsList />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract:view");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/contracts/new',
			element: <ContractForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract:edit");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/contract/:contractId',
			element: <ContractDetails />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract:view");
			},
			children: [
				{
					variant: "dashboard",
					private: true,
					index: true,
					element: <ContractDetailsContract />
				},
				...(injections ? injections : [])
			]
		},
		{
			variant: "dashboard",
			private: true,
			path: '/contract/:contractId/edit',
			element: <ContractForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract:edit");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/contract-samples',
			element: <ContractSamplesList />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract-sample:view");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/contract-samples/new',
			element: <ContractSampleForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract-sample:edit");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/contract-sample/:sampleId/edit',
			element: <ContractSampleForm />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("contract-sample:edit");
			}
		},
		//
	];
}

export default routes;