import { useState } from "react";
import { useMiddleware } from "../../../Application";


const useStorageDownload = ({ onComplete, onError } = {
    onComplete: file => { },
    onError: error => { }
}) => {

    const middleware = useMiddleware('Storage.Main');

    const [downloading, setDownloading] = useState(false);
    const [progress, setProgress] = useState(0);

    const download = async (file) => {

        // start loading
        setDownloading(true);

        // fetch blob from server
        const blob = await middleware.download(file.id, {
            responseType: "blob",
            onDownloadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        });

        // create file link in browser's memory
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        // stop loading
        setDownloading(false);

        // dispatch onComplete event
        onComplete(file);
    }

    return { download, progress, downloading };
}

export default useStorageDownload