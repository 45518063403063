import BirthdayMiddleware from "./BirthdayMiddleware";
import CampaignsMiddleware from "./CampaignsMiddleware";
import LoyaltiesMiddleware from "./LoyaltiesMiddleware";
import MainMiddleware from "./MainMiddleware";
import ReviewsMiddleware from "./ReviewsMiddleware";
import RewardsMiddleware from "./RewardsMiddleware";
import VisitMiddleware from "./VisitMiddleware";
import WelcomeMiddleware from "./WelcomeMiddleware";

const middlewares = [
	MainMiddleware,
	VisitMiddleware,
	WelcomeMiddleware,
	CampaignsMiddleware,
	BirthdayMiddleware,
	LoyaltiesMiddleware,
	ReviewsMiddleware,
	RewardsMiddleware,
];

export default middlewares;
