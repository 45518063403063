import CasesOutlined from "@mui/icons-material/CasesOutlined";
import WorkOutline from "@mui/icons-material/WorkOutline";


const tasks = {
    manage: {
        title: `مدیریت پرونده‌ها`,
        Icon: CasesOutlined,
        path: `/cases`,
        keywords: [],
        default: true,
    },
    details: (_case) => ({
        title: _case?.name,
        Icon: WorkOutline,
        path: '/case/'.concat(_case?.id)
    })
}

export default tasks;