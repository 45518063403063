import SettleIcon from "@mui/icons-material/CreditScoreOutlined";
import NoProductIcon from "@mui/icons-material/Warning";
import {
    Box, Button, Link, Stack,
    styled,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useMiddleware, useTask } from "../../../../Application";
import DetailedDate from "../../../../components/DetailedDate";
import TableLoader from "../../../../components/TableLoader";
import pricify from "../../../../utils/pricify";
import useDialog from "../../../../utils/useDialog";
import useGetRowNumber from "../../../../utils/useGetRowNumber";
import TransactionSettleConfirmation from "../../components/TransactionSettleConfirmation";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        color: 'red'
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const TransactionsTable = () => {

    const middleware = useMiddleware('Transactions.People');

    const { person } = useOutletContext();

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [isLoading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState({ data: [], total: 0 });
    const [page, setPage] = useState(0);

    const loadTransactionsAsync = async () => {
        if (!isLoading) setLoading(true);
        const { ok, data, total } = await middleware.transactions(params.personId, { page });
        if (ok) {
            setTransactions({ data, total });
            setLoading(false);
        }
    }

    useEffect(() => {
        loadTransactionsAsync();
    }, [params.personId, person])

    const {
        isOpen: isSettleDialogOpen,
        isOpening: isSettleDialogOpening,
        open: openSettleDialog,
        close: closeSettleDialog,
        data: settleDialogTransaction
    } = useDialog();

    const taskInvoiceDetails = useTask('invoices.details');

    const getRowNumber = useGetRowNumber();

    const renderReference = (ref, refModel) => {
        switch (refModel) {
            case 'Invoice':
                return (
                    <Link
                        color="inherit"
                        onClick={() => navigate(
                            '/invoice/'.concat(ref.id),
                            { state: { prev: location } }
                        )}
                    >
                        صورتحساب {ref.number}
                    </Link>
                )
            default:
                return '-';
        }
    }


    if (isLoading) {
        return (
            <TableLoader size="small" />
        )
    }

    if (transactions.data.length < 1) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <NoProductIcon fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )
    }

    return (
        <Box>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ردیف</TableCell>
                            <TableCell align="center">تاریخ ثبت</TableCell>
                            <TableCell>نوع</TableCell>
                            <TableCell align="center">مبلغ</TableCell>
                            <TableCell>بابت</TableCell>
                            <TableCell align="center">مرجع</TableCell>
                            <TableCell align="center">تاریخ تسویه</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.data.map((transaction, index) => (
                            <StyledTableRow key={index}>
                                <TableCell align="center">{getRowNumber(index)}</TableCell>
                                <TableCell align="center"><DetailedDate date={transaction.createdAt} /></TableCell>
                                <TableCell>{transaction.type === 'RECEIVEABLE' ? 'پرداختنی' : 'دریافتنی'}</TableCell>
                                <TableCell align="center">{pricify(transaction.amount)}</TableCell>
                                <TableCell>{transaction.note}</TableCell>
                                <TableCell align="center">
                                    {renderReference(transaction.reference, transaction.referenceModel)}
                                </TableCell>
                                <TableCell align="center">
                                    {transaction.isPaid ? <DetailedDate date={transaction.paidAt} /> : '-'}
                                </TableCell>
                                <TableCell>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="center"
                                    >
                                        {!transaction.isPaid && (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                startIcon={<SettleIcon fontSize="small" />}
                                                onClick={() => openSettleDialog(transaction)}
                                            >
                                                تسویه
                                            </Button>
                                        )}
                                    </Stack>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={transactions.total}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={setPage}
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} از مجموع ${count !== -1 ? count : `بیشتر از ${to}`} ردیف`}
            />

            <TransactionSettleConfirmation
                open={isSettleDialogOpen}
                onClose={closeSettleDialog}
                transaction={settleDialogTransaction}
                onSettle={() => loadTransactionsAsync()}
            />
        </Box>
    )
}

export default TransactionsTable