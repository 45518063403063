import { Skeleton, styled, Table, TableBody, TableCell, TableRow } from "@mui/material"


const DLTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-of-type .MuiTableCell-root': {
        [theme.breakpoints.up('md')]: {
            paddingBottom: 0
        },
    }
}))

const DTTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    verticalAlign: 'top',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    color: theme.palette.text.secondary,
    fontWeight: 500
}))


const DDTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    paddingTop: 0,
    borderBottom: 'none',
    fontWeight: 500
}))


const DataList = ({ data, ...props }) => {
    return (
        <Table {...props}>
            <TableBody>
                {data.map((itm, idx) => (
                    <DLTableRow key={idx}>
                        <DTTableCell width='50%'>{itm.title}</DTTableCell>
                        <DDTableCell>{itm.loading ? <Skeleton variant="text" /> : itm.description}</DDTableCell>
                    </DLTableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default DataList;