import { SvgIcon } from "@mui/material";

const IconLogo = (props) => {
    return (
        <SvgIcon viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" stroke="currentColor" {...props}>
            <g transform="translate(-3072,-4096)">
                <g transform="translate(3339.32,4267.43)">
                    <path d="M0.2,169.1c-45.6,0-82.6-36.5-82.6-81.3S-45.4,6.6,0.2,6.6c45.6,0,82.6,36.5,82.6,81.3
			S45.7,169.1,0.2,169.1 M-151.5,310.6c-25,0-45.3-20-45.3-44.6s20.3-44.6,45.3-44.6s45.3,20,45.3,44.6S-126.5,310.6-151.5,310.6
			 M-236.8-87.9c0-29.5,24.4-53.5,54.4-53.5c30,0,54.4,24,54.4,53.5c0,29.5-24.4,53.5-54.4,53.5C-212.4-34.4-236.8-58.4-236.8-87.9
			 M182.2-50.9c17.7,0,32,14.1,32,31.5c0,17.4-14.4,31.5-32,31.5c-17.7,0-32-14.1-32-31.5C150.1-36.8,164.5-50.9,182.2-50.9 M137,23
			c11.4,11.7,27.4,19.1,45.2,19.1c34.5,0,62.5-27.6,62.5-61.5c0-33.9-28-61.5-62.5-61.5c-34.5,0-62.5,27.6-62.5,61.5
			c0,5.6,0.8,11.1,2.2,16.3L88.7,18.7C68-7,36-23.4,0.2-23.4C-26.5-23.4-51-14.3-70.4,1l-44.8-38c11-14.1,17.6-31.7,17.6-50.9
			c0-46-38.1-83.5-84.9-83.5c-46.8,0-84.9,37.5-84.9,83.5c0,46,38.1,83.5,84.9,83.5c16.3,0,31.5-4.5,44.4-12.4l46.5,39.5
			C-105,41-113,63.5-113,87.9c0,28,10.6,53.6,27.9,73.1l-31.1,39c-10.6-5.5-22.6-8.6-35.4-8.6c-41.8,0-75.8,33.5-75.8,74.6
			s34,74.6,75.8,74.6s75.8-33.5,75.8-74.6c0-17.7-6.3-34.1-16.9-46.9l30.6-38.3c17.9,11.6,39.2,18.4,62.2,18.4
			c62.4,0,113.1-49.9,113.1-111.3c0-15.4-3.2-30-9-43.4L137,23z"/>
                </g>
            </g>
        </SvgIcon>
    )
}

export default IconLogo;