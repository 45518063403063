import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class ManageBoardsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'ManageBoards';

    /**
     * The middleware name
     *
     * @var string
     */
    basePath = '/manage/boards';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        createOrUpdate: '/',
        details: '/:boardId',
    }

    /**
     * Get list of products match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(
                this.serializePath(this.paths.list, args)
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Create or update an existing product
     *  
     * @return Promise
     */
    async createOrUpdate(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.createOrUpdate,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            console.log(error);
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }

    /**
     * Get product details
     * 
     * @param string boardId 
     * @return Promise 
     */
    async details(boardId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { boardId })
            );
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default ManageBoardsMiddleware;