import Delete from "@mui/icons-material/DeleteOutline";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import Edit from "@mui/icons-material/EditOutlined";
import { Box, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import { format } from "date-fns-jalali";
import { useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSearchParams } from "react-router-dom";
import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import ResponsiveTable from "../../../components/ResponsiveTable";
import Text from "../../../components/Text";
import useGetRowNumber from "../../../utils/useGetRowNumber";
import FilePreviwIcon from "../components/FilePreviewIcon";


const FilesTable = ({
    loading,
    files,
    meta,
    infiniteScrollProps
}) => {

    const getRowNumber = useGetRowNumber();

    const [searchParams, setSearchParams] = useSearchParams();

    const middleware = useMiddleware('Storage.Main');
    const { workspace, getAuthToken } = useAuth();
    const handleView = async (file) => {
        setLightBoxProps({
            open: true,
            mainSrc: middleware.getDirectDownloadUrl(file.id, getAuthToken(), workspace.id),
        })
    }

    const [lightBoxProps, setLightBoxProps] = useState({ open: false });
    

    return (
        <>
            <ResponsiveTable
                data={files}
                loading={loading}
                definitions={[
                    {
                        title: 'ردیف',
                        headerCellProps: {
                            align: "center"
                        },
                        rowCellProps: {
                            align: "center"
                        },
                        renderCell: (row, index) => <Text>{getRowNumber(index)}</Text>
                    }, {
                        title: 'فایل',
                        rowCellProps: {
                            width: 200
                        },
                        renderCell: row => (
                            <Box
                                onClick={() => handleView(row)}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <FilePreviwIcon file={row} />
                                <Box sx={{ pl: 2 }}>
                                    <strong dir="ltr">{row.name}</strong>
                                    <br />
                                    <small>{String(row.ext).toUpperCase()}</small>
                                </Box>
                            </Box>
                        )
                    }, {
                        field: 'modifiedAt',
                        title: 'آخرین تغییر',
                        headerCellProps: {
                            align: "center"
                        },
                        rowCellProps: {
                            align: "center"
                        },
                        render: row => row.modifiedAt && <Text>{format(new Date(row.modifiedAt), "dd MMMM yyyy")}</Text>,
                    }, {
                        field: 'sizeFormatted',
                        title: 'حجم',
                        headerCellProps: {
                            align: "center",
                        },
                        rowCellProps: {
                            align: "center"
                        },
                        render: row => <span dir="ltr"><Text>{row.sizeFormatted}</Text></span>
                    }, {
                        title: 'فرمت',
                        renderList: row => <span dir="ltr">{String(row.ext).toUpperCase()}</span>
                    }
                ]}
                renderSheetTitle={row => row.name}
                renderListItemChildren={(row, index) => (
                    <ListItemButton onClick={e => handleView(row)}>
                        <ListItemAvatar>
                            <FilePreviwIcon file={row} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={<strong>{row.name}</strong>}
                            primaryTypographyProps={{
                                sx: {
                                    direction: 'ltr',
                                    overflow: 'hidden'
                                }
                            }}
                            secondary={String(row.ext).toUpperCase()}
                        />
                    </ListItemButton>
                )}

                tableRowProps={row => ({
                    hover: true,
                    striped: true,
                    onClick: e => handleView(row)
                })}

                paginationProps={{
                    count: meta.total,
                    rowsPerPage: searchParams.has('perPage') ? Number(searchParams.get('perPage')) : 25,
                    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
                    onPageChange: (e, page) => setSearchParams({ ...Object.fromEntries([...searchParams]), page }),
                    onRowsPerPageChange: e => setSearchParams({ ...Object.fromEntries([...searchParams]), perPage: e.target.value })
                }}

                infiniteScrollProps={infiniteScrollProps}

                actions={[
                    { title: 'دانلود', icon: <DownloadOutlined />, onClick: row => alert('Download') },
                    { title: 'ویرایش', icon: <Edit />, onClick: row => alert('Rename File') },
                    { title: 'حذف', icon: <Delete />, onClick: () => alert('Deleted') }
                ]}
            />

            {lightBoxProps.open && (
                <Lightbox
                    {...lightBoxProps}
                    // mainSrc={images[photoIndex]}
                    // nextSrc={images[(photoIndex + 1) % images.length]}
                    // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setLightBoxProps({ open: false })}
                    reactModalStyle={{ overlay: { zIndex: 10000 }, content: { direction: 'ltr' } }}
                // reactModalStyle={{
                //     zIndex: 100000
                // }}
                // onMovePrevRequest={() =>
                //     this.setState({
                //         photoIndex: (photoIndex + images.length - 1) % images.length,
                //     })
                // }
                // onMoveNextRequest={() =>
                //     this.setState({
                //         photoIndex: (photoIndex + 1) % images.length,
                //     })
                // }
                />
            )}

        </>
    )
}

export default FilesTable