import Accounts from "./accounts";
import Transactions from "./transactions";
import Categories from "./categories";


const middlewares = [
    Accounts,
    Transactions,
    Categories
];

export default middlewares;