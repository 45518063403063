import ClientShippedInvoices from "../pages/ClientShippedInvoices";
import { InvoiceDetailsShipments } from "./InvoiceDetails";
import { InvoiceFormShipments } from "./InvoiceForm";


const hooks = {
    'Ceremonies/Reservation': {
        'InvoiceForm.formDataState': ({ tab }) => ({
            shipments: tab && tab.state && tab.state.formData ? tab.state.formData.shipments : [],
            shippingAddress: tab && tab.state && tab.state.formData ? tab.state.formData.shippingAddress : ''
        }),
        // 'InvoiceForm.AddProduct.defaultValues': () => {
        //     return {
        //         supplier: null
        //     }
        // },
        'InvoiceForm.Footer.PreSide': props => <InvoiceFormShipments {...props} />,
        // 'InvoiceItemsTable.HeadCells.AfterTitle': props => <InvoiceItemsTableHeadCellsAfterTitle {...props} />,
        // 'InvoiceItemsTable.BodyCells.AfterTitle': props => <InvoiceItemsTableBodyCellsAfterTitle {...props} />,
    },
    'Invoices': {
        'InvoiceDetails.PostSide': props => <InvoiceDetailsShipments {...props} />
    },
    'Clients': {
        'ClientFormDialog.roles': () => {
            return {
                name: 'راننده',
                value: 'driver'
            }
        },
        'ClientDetails.TabsList': ({ clientId }) => {
            return { label: 'تاریخچه سرویس‌ها', value: `/client/${clientId}/shipped` }
        },
        'routes.clients.details': () => {
            return { variant: "dashboard", requireAuth: true, path: 'shipped', element: <ClientShippedInvoices /> }
        }
    }
};

export default hooks;