import Skeleton from "@mui/material/Skeleton";
import { useEffect } from "react";
import { useLocation } from "react-router";
// import { useTour } from "../tour";
import useDebounce from "../utils/useDebounce";
import useTabs from "./useTabs";


const TabView = ({
    title,
    Icon,
    renderIcon,
    loading,
    children,
    state,
    tour
}) => {

    const location = useLocation();

    // const { setTour } = useTour();

    const { currentIndex, currentTab, add, update } = useTabs();

    const debouncedState = useDebounce(state, 500);

    // title = title || <Skeleton variant="text" width={100} animation="wave" />;
    // renderIcon = loading ? (
    //     props => (
    //         <Skeleton
    //             variant="circular"
    //             animation="wave"
    //             width={30}
    //             height={30}
    //             {...props}
    //         />
    //     )
    // ) : renderIcon;

    const getUpdatedPrev = () => {
        if (location.state && location.state.prev) {
            return location.state.prev;
        }

        if (location.pathname === "/")
            return null

        if (currentTab.state && currentTab.state.prev) {
            return currentTab.state.prev;
        }

        return null;
    }

    useEffect(() => {
        if (currentIndex > -1) {
            update(currentIndex, {
                title: title || <Skeleton variant="text" width={100} animation="wave" />,
                renderIcon: loading ? (
                    props => (
                        <Skeleton
                            variant="circular"
                            animation="wave"
                            width={30}
                            height={30}
                            {...props}
                        />
                    )
                ) : renderIcon,
                Icon,
                location,
                state: {
                    // ...currentTab.state,
                    ...debouncedState,
                    prev: getUpdatedPrev()
                }
            })
        }
        else {
            add({ title, Icon, renderIcon, location })
        }

        if (typeof title === "string")
            document.title = [title, "فراهاب"].join(' | ');
    }, [location, title, Icon, renderIcon, debouncedState]);

    // useEffect(() => {
    //     setTour(tour || { key: '', steps: [], options: {} });
    // }, [tour]);

    return (children)
}

export default TabView;