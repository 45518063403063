import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class ConditionsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Conditions';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/conditions';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
    }

    /**
     * Get list of categories match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args, config) {
        try {
            const response = await axios({
                method: 'get',
                url: this.serializePath(this.paths.list, args),
                ...config
            });
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default ConditionsMiddleware;