import DomainOutlined from "@mui/icons-material/DomainOutlined";

const tasks = {
    manageDomains: {
        title: `مدیریت دامنه ها`,
        Icon: DomainOutlined,
        path: `/domains`,
        subtasks: []
    },
    //
}

export default tasks;