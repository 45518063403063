import SettleIcon from "@mui/icons-material/CreditScoreOutlined";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useMiddleware } from "../../../Application";
import DataList from "../../../components/DataList";
import LoadableData from "../../../components/LoadableData";
import pricify from "../../../utils/pricify";


export const InvoiceTransactionsOverview = ({ invoice, state, setState, refetch }) => {

    const middleware = useMiddleware('transactions.invoices');


    const [isSettled, setIsSettled] = useState(false);

    const loadSettleStatus = async () => {
        const response = await middleware.isSettled(invoice.id);
        setIsSettled(response.isSettled);
    }

    useEffect(() => {
        if (invoice && invoice.id)
            loadSettleStatus();
    }, [invoice]);

    if(!invoice || !invoice.id) return null;

    return (
        <Grid item xs={12}>
            <Card variant="outlined" className="invoice-transactions-overview">
                <CardHeader title="امور مالی" titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <DataList
                        data={
                            [{
                                title: 'مجموع پرداختی',
                                description: (
                                    <Box>
                                        <LoadableData
                                            loadData={() => middleware.totalPaid(invoice.id)}
                                            getData={response => response.totalPaid}
                                            renderData={data => pricify(data)}
                                        />
                                        {` `}
                                        <small>ریال</small>
                                    </Box>
                                )
                            }, {
                                title: 'مانده',
                                description: (
                                    <Box>
                                        <LoadableData
                                            loadData={() => middleware.remaining(invoice.id)}
                                            getData={response => response.remaining}
                                            renderData={data => pricify(data)}
                                        />
                                        {` `}
                                        <small>ریال</small>
                                    </Box>
                                )
                            }]
                        }
                    />
                </CardContent>
                <CardActions>
                    <Stack direction="row" spacing={1}>
                        {!isSettled && (
                            <Button
                                variant="outlined"
                                startIcon={<SettleIcon />}
                                onClick={() => setState({ ...state, paymentDialogOpen: true })}
                                size="small"
                            >پرداخت جدید</Button>
                        )}
                        {!isSettled && (
                            <Button
                                variant="outlined"
                                startIcon={<SettleIcon />}
                                onClick={() => setState({ ...state, settleDialogOpen: true })}
                                size="small"
                            >تسویه کامل</Button>
                        )}
                    </Stack>
                </CardActions>
            </Card>
        </Grid>
    )
}