import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const RolesList = Loadable(lazy(() => import('./pages/RolesList/Main')));
// const PersonDetails = Loadable(lazy(() => import('./pages/PersonDetails/PersonDetails')));
// const PersonOverview = lazy(() => import('./pages/PersonDetails/PersonOverview'));


const routes = (module) => {
	return [
		{
			variant: "dashboard",
			private: true,
			path: '/roles',
			element: <RolesList />,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("roles:view");
			}
		},
		// {
		// 	variant: "dashboard",
		// 	private: true,
		// 	path: '/people/:personId',
		// 	element: <PersonDetails />,
		// 	children: [
		// 		{ variant: "dashboard", private: true, index: true, element: <PersonOverview /> },
		// 		...(module.inject('routes.people.details') || [])
		// 	],
		// 	authorize: ({ hasRole, hasPermission }) => {
		// 		return hasRole("owner") || hasPermission("person:view");
		// 	}
		// }
	]
};

export default routes;