import FilePreviewIcon from "./FilePreviewIcon";
import FileSelectDialog from "./FileSelectDialog";
import UploadListItem from "./UploadListItem";

const components = {
    FilePreviewIcon,
    UploadListItem,
    FileSelectDialog,
}

export default components