import Send from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { Box, Paper } from "@mui/material";
import sortBy from "lodash/sortBy";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import TextEditor from "../../../components/TextEditor";
import useForm from "../../../utils/useForm";


const editorIsEmpty = data => data.replace(/<(.|\n)*?>/g, '').trim().length === 0;

const BroadcastForm = ({ model, extractModelId, defaultValues, onCreateOrUpdate }) => {

    const { inject } = useAuth();

    const params = useParams();

    const initialValues = {
        content: '',
        attachments: [],
        ...defaultValues
    }

    const middleware = useMiddleware('Broadcast.Main');

    const {
        register,
        loading,
        handleSubmit,
        reset,
        getValue,
        setValue,
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate(model, extractModelId(params), data),
        onSubmit: onCreateOrUpdate
    })

    useEffect(() => {
        reset(initialValues)
    }, [defaultValues])

    const [focused, setFocused] = useState(false);

    const injectedHooks = Object.assign({}, ...(inject('Broadcast', 'BroadcastForm.Hooks', { register, loading, getValue, setValue, params, reset, focused }) || []));


    return (
        <Paper
            variant="outlined"
            sx={theme => ({
                transition: 'all .16s ease-in-out',
                borderColor: focused && theme.palette.grey[500],
            })}
        >
            <TextEditor
                value={getValue("content")}
                onChange={content => setValue("content", content)}
                placeholder="اینجا شرح دهید..."
                containerProps={{
                    styleOverrides: {
                        editor: {
                            transition: 'all .16s ease-in-out',
                            minHeight: focused ? 150 : 50
                        }
                    }
                }}
                disabled={!focused}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            <Box sx={{ p: 1, display: 'flex' }}>
                {sortBy([
                    ...(inject('broadcast', 'BroadcastForm.Toolbar', { register, loading, getValue, setValue, params, reset, focused, ...injectedHooks }) || [])
                ])}
                <Box sx={{ flexGrow: 1 }} />
                <LoadingButton
                    size="small"
                    variant="contained"
                    endIcon={<Send sx={{ transform: 'rotate(180deg)' }} />}
                    loading={loading}
                    onClick={handleSubmit}
                    disabled={editorIsEmpty(getValue("content")) && getValue("attachments").length < 1}
                >ارسال</LoadingButton>
            </Box>

            {inject('broadcast', 'BroadcastForm.Footer', { register, loading, getValue, setValue, reset, params, focused, ...injectedHooks })}
        </Paper>
    )
}

export default BroadcastForm;
