/* eslint-disable react-hooks/rules-of-hooks */

import { SvgIcon } from "@mui/material";
import Text from "../../../components/Text";
import { ClientDetailsOverview } from "./PersonDetails";
import { Icon } from "@iconify/react";



const People = {
	"PeopleList.definitions": () => [
		{
			title: "شماره موبایل",
			render: (row) => (row.phone ? <Text>{row.phone}</Text> : "-"),
		},
		{
			title: "تعداد مراجعه",
			headerCellProps: {
				align: "center",
			},
			rowCellProps: {
				align: "center",
			},
			render: (row) => <Text>{row.totalReferences}</Text>,
		},
	],
	'PersonDetails.Overview.Grid': injectedParams => <ClientDetailsOverview {...injectedParams} />,
	// 'PersonDetails.ActionsList': ({ client, transactionFormHook, settleFormHook }) => {


	// 	return [{
	// 		title: 'صفر کردن مراجعات',
	// 		icon: <SvgIcon component={Icon} icon="solar:restart-bold" fontSize="inherit" />,
	// 		// onClick: () => openTransactionFormDialog(),
	// 		buttonProps: {
	// 			variant: "contained"
	// 		},
	// 		priority: 110
	// 	}]
	// },
};

export default People;
