/* eslint-disable react-hooks/rules-of-hooks */
import { Icon as Iconify } from "@iconify/react";
import { SvgIcon } from "@mui/material";
import { lazy } from "react";
import { useAuth } from "../../../auth";

const LandingSettings = lazy(() => import("../pages/Settings"));

const hooks = {
    Core: {
        "routes.settings": () => {
            return {
                variant: "dashboard",
                private: true,
                path: "digital-menu",
                element: <LandingSettings />,
            };
        },
        "Settings.TabsList": () => {
            return {
                label: "منو دیجیتال",
                value: `/settings/digital-menu`,
                icon: (
                    <SvgIcon
                        component={Iconify}
                        icon="solar:notebook-bold-duotone"
                    />
                ),
                // priority: 50,
            };
        },
        "Settings.initialValues": () => {
            const { workspace } = useAuth();
            
            return {
                "showcase:name": workspace.options['showcase:name'] || workspace.name,
                "showcase:headline": workspace.options['showcase:headline'],
                "showcase:tel": workspace.options['showcase:tel'],
                "showcase:website": workspace.options['showcase:website'],
                "showcase:instagram": workspace.options['showcase:instagram'],
                "showcase:address": workspace.options['showcase:address'],
            };
        },
    },
};

export default hooks;
