import { styled, Tooltip } from "@mui/material";
import dateFormat from "date-fns-jalali/format";

const Text = styled('span')(({ theme }) => ({
    position: 'relative',
    borderBottom: '1px dashed '.concat(theme.palette.text.primary),
}))

const DetailedDate = ({
    date,
    format = "yyyy/MM/dd",
    detailedFormat = "EEEE dd MMMM yyyy HH:mm",
    prefix = "",
    postfix = "",
    placement = "top",
    ...props
}) => {

    if (!date) return null;

    return (
        <Tooltip
            title={dateFormat(new Date(date), detailedFormat)}
            placement={placement}
            {...props}
        >
            <Text>{[prefix, dateFormat(new Date(date), format), postfix].filter(Boolean).join(' ')}</Text>
        </Tooltip>
    )
}

export default DetailedDate;