import { useAuth } from ".";
import Unauthorized from "../pages/Unauthorized";
import PropTypes from "prop-types";


const Authorized = ({ children, variant = "component", authorize = () => true }) => {

    const auth = useAuth();

    const authorized = authorize(auth);

    if (!(typeof authorized === "boolean" ? authorized : authorized.isAuthorized)) {
        return variant === "route" ? (
            <Unauthorized {...(typeof authorized === "object" && authorized)} />
        ) : null
    }

    return children;
}

Authorized.propTypes = {
    variant: PropTypes.oneOf(['component', 'route']),
    authorize: PropTypes.func
}

export default Authorized;