import NumberFormat from 'react-number-format';
import { Component, forwardRef } from "react";
import TextField from './TextField';


const NumberFormatCustom = forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
});

class NumberTextField extends Component {
    render() {
        return (
            <TextField
                {...this.props}
                InputProps={{
                    ...this.props.InputProps,
                    inputComponent: NumberFormatCustom,
                }}
            />
        )
    }
}

export default NumberTextField