/* eslint-disable react-hooks/rules-of-hooks */
import { Icon as Iconify } from "@iconify/react";
import { SvgIcon } from "@mui/material";
import { lazy } from "react";
import { useAuth } from "../../../auth";

const Settings = lazy(() => import("../pages/Settings/Main"));

const Core = {
	"routes.settings": () => {
		return {
			variant: "dashboard",
			private: true,
			path: "customers-club",
			element: <Settings />,
		};
	},
	"Settings.TabsList": () => {
		return {
			label: "باشگاه مشتریان",
			value: `/settings/customers-club`,
			icon: (
				<SvgIcon
					component={Iconify}
					icon="solar:user-heart-bold-duotone"
				/>
			),
			// priority: 50,
		};
	},
	"Settings.initialValues": () => {
		const { workspace } = useAuth();

		return {
			"showcase:name":
				workspace.options["showcase:name"] || workspace.name,
			"showcase:headline": workspace.options["showcase:headline"],
			"showcase:tel": workspace.options["showcase:tel"],
			"showcase:website": workspace.options["showcase:website"],
			"showcase:instagram": workspace.options["showcase:instagram"],
			"showcase:address": workspace.options["showcase:address"],
		};
	},
};

export default Core;
