import Private from "./Private";
import ChangePasswordDialog from "./ChangePasswordDialog";
import AccountMenu from "./AccountMenu";

const components = {
    Private,
    ChangePasswordDialog,
    AccountMenu
}

export default components