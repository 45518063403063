import { ForumOutlined, HeadsetMicOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useMiddleware } from "../../../Application";
import Picture from "../../../assets/renew-bundle.jpg";


const RenewBundleDialog = ({ open, onClose }) => {

    const middleware = useMiddleware('authentication.main');

    const [formData, setFormData] = useState({
        password: '',
        password_confirmation: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
            scroll="paper"
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)'
                }
            }}
        >
            <DialogContent>
                <Box>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ height: '100%', display: 'flex' }}>
                                <Box
                                    component='img'
                                    src={Picture}
                                    sx={{ width: '100%', height: 'auto', display: 'block', m: 'auto' }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ px: { xs: 3, md: 7 } }}>
                                <Typography variant="h5" sx={{ fontWeight: 700, mt: 5, mb: 3 }}>اشتراک شما به پایان رسیده</Typography>
                                <Typography variant="subtitle1" sx={{ mb: 5 }}>اشتراک فضای کار شما به پایان رسیده، جهت تمدید اشتراک و یا ارتقاء پلن خود با واحد فروش تماس بگیرید.</Typography>
                                <Box component={'strong'} sx={{ mb: 1, display: 'inline-block' }}>شماره‌ واحد فروش‌ :‌</Box>
                                <Typography variant="subtitle1" sx={{ mb: 5 }}>
                                    09130447413
                                </Typography>

                                <Button
                                    variant="contained"
                                    LinkComponent={'a'}
                                    href="tel:+989130447413"
                                    startIcon={<HeadsetMicOutlined />}
                                    sx={{ mr: 1, mb: 1 }}
                                >تماس با واحد فروش</Button>
                                <Button
                                    variant="contained"
                                    LinkComponent={'a'}
                                    href="https://wa.me/+989130447413"
                                    target="_blank"
                                    startIcon={<ForumOutlined />}
                                    sx={{ mb: 1 }}
                                >پیام در واتساپ</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default RenewBundleDialog;