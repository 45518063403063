import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material";
import MuiButton from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MuiPaper from "@mui/material/Paper";
import MuiTypography from "@mui/material/Typography";
import { SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback, useState } from "react";

const Root = styled(SnackbarContent)(({ theme }) => ({
    "@media (min-width:600px)": {
        minWidth: "344px !important"
    }
}));

const Card = styled(MuiCard)(({ theme }) => ({
    width: "100%",
    // backgroundColor: theme.palette.primary.main
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
    // color: theme.palette.common.white
}));

const ActionRoot = styled(CardActions)(({ theme }) => ({
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between"
}));

const Icons = styled('div')(({ theme }) => ({
    marginLeft: "auto"
}));

const ExpandableIconButton = styled(IconButton, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    padding: "8px 8px",
    transform: "rotate(0deg)",
    // color: "#000",
    transition: "all .2s",

    ...(open && {
        transform: "rotate(180deg)"
    })
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
    // backgroundColor: "#fff",
    padding: 16
}));

const CheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
    fontSize: 20,
    paddingRight: 4
}));

const Button = styled(MuiButton)(({ theme }) => ({
    padding: 0,
    textTransform: "none"
}));


const ReportComplete = forwardRef(
    ({ id, ...props }, ref) => {
        
        const { closeSnackbar } = useSnackbar();
        const [expanded, setExpanded] = useState(false);

        const handleExpandClick = useCallback(() => {
            setExpanded((oldExpanded) => !oldExpanded);
        }, []);

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);
        

        return (
            <Root ref={ref}>
                <Card>
                    <ActionRoot>
                        <Typography variant="body2">
                            {props.message}
                        </Typography>
                        <Icons>
                            <ExpandableIconButton
                                aria-label="Show more"
                                size="small"
                                open={expanded}
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon />
                            </ExpandableIconButton>
                            <ExpandableIconButton
                                size="small"
                                onClick={handleDismiss}
                            >
                                <CloseIcon fontSize="small" />
                            </ExpandableIconButton>
                        </Icons>
                    </ActionRoot>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Paper variant="outlined" square>
                            <Typography
                                gutterBottom
                                variant="caption"
                                style={{ color: "#000", display: "block" }}
                            >
                                PDF ready
                            </Typography>
                            <Button size="small" color="primary">
                                <CheckIcon />
                                Download now
                            </Button>
                        </Paper>
                    </Collapse>
                </Card>
            </Root>
        );
    }
);

// ReportComplete.displayName = "ReportComplete";

export default ReportComplete;
