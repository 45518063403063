import { lazy } from "react";
import Loadable from "../../components/Loadable";


const Ads = Loadable(lazy(() => import('./pages/Ads/Ads')));
const AdDetails = Loadable(lazy(() => import('./pages/AdDetails/AdDetails')));
const AdOverview = lazy(() => import('./pages/AdDetails/AdOverview'));

const Boards = Loadable(lazy(() => import('./pages/Boards/Boards')));

const routes = module => {
	return [
		{
			variant: "dashboard",
			path: '/ads',
			element: <Ads />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("ads:view");
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/ads/:adId',
			element: <AdDetails />,
			children: [
				{ variant: "dashboard", private: true, index: true, element: <AdOverview /> },
				...(module.inject('routes.ads.details') || [])
			],
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("ads:view");
			}
		},
		{
			variant: "dashboard",
			path: '/boards',
			element: <Boards />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner") || hasPermission("boards:view");
			}
		},
	]
};

export default routes;