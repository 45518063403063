import { Route, Routes as RRRoutes } from "react-router-dom";
import { useApplication, useComponent } from "./Application";
import { Authorized } from "./auth";
import LayoutContainer from "./layouts/LayoutContainer";
import NotFound from "./pages/NotFound";
import NewTab from "./tabs/NewTab";


const Routes = () => {
    const { application } = useApplication();

    const rootRoutes = application.routes.filter(route => route.variant === "root");
    const dashboardRoutes = application.routes.filter(route => route.variant === "dashboard");

    const layoutRoutes = [{
        path: '/',
        private: true,
        element: <LayoutContainer />,
        children: [
            { variant: "dashboard", index: true, element: <NewTab /> },
            ...dashboardRoutes,
            { variant: "dashboard", path: '*', element: <NotFound variant="dashboard" /> }
        ]
    }]

    // const authModule = application.module('authentication')
    // const authRoutes = authModule ? authModule.routes : [];
    const Private = useComponent('Authentication.Private');

    const renderRoutes = (routesMap) => {
        return routesMap.map(
            (route, index) => {

                let element = route.element;

                if (route.authorize) {
                    element = <Authorized variant="route" authorize={route.authorize}>{element}</Authorized>;
                }

                if (route.private) {
                    element = <Private>{element}</Private>;
                }

                return (
                    <Route
                        {...route}
                        key={index}
                        element={element}
                    >
                        {route.children && route.children.length > 0 && renderRoutes(route.children)}
                    </Route>
                )
            }
        )
    }


    return (
        <RRRoutes>
            {/* {renderRoutes(authRoutes)} */}
            {renderRoutes(rootRoutes)}
            {renderRoutes(layoutRoutes)}
            <Route path="*" element={<NotFound variant="root" />} />
        </RRRoutes>
    )
}

export default Routes;