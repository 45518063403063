import Add from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";
import { AppBar, Box, IconButton, Slide, Tab, Tabs, Toolbar, Typography, alpha, styled } from "@mui/material";
import { useNavigate } from "react-router";
import Tooltip from "../components/Tooltip";
import useHideOnScroll from "../utils/useHideOnScroll";
import useTabs from "./useTabs";


const TabsContainer = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.appBar - 1,
    marginTop: '49px',
    borderTopColor: 'transparent',
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
}))

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
    padding: 0,
    cursor: 'auto',
    position: 'relative',
    color: alpha(theme.palette.text.primary, 0.8),
    borderTopLeftRadius: theme.shape.borderRadius * 3,
    borderTopRightRadius: theme.shape.borderRadius * 3,
    '&:not(:first-of-type):before': {
        content: `''`,
        position: 'absolute',
        left: 0,
        width: 1,
        height: '50%',
        top: '25%',
        backgroundColor: theme.palette.divider
    },
    '&.Mui-selected': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.hover
    },
    '& a': {
        textDecoration: 'none',
    }
}))

const TabBar = () => {

    const navigate = useNavigate();

    const {
        tabs,
        openInNew,
        currentTab,
        currentIndex,
        markCurrent,
        close
    } = useTabs();

    const prev = (currentTab && currentTab.state) && currentTab.state.prev;

    const isHidden = useHideOnScroll();


    return (
        <div id="FH-TabBar">
            <Slide direction="down" in={!isHidden} mountOnEnter unmountOnExit>
                <TabsContainer
                    component="div"
                    position="fixed"
                    variant="outlined"
                    color="inherit"
                    elevation={0}
                >
                    <Box sx={{ pr: '50px' }}>
                        <Tabs
                            value={currentIndex > -1 ? currentIndex : false}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                style: {
                                    transition: "none"
                                }
                            }}
                        >
                            {tabs.map((tab, index) => (
                                <StyledTab
                                    key={index}
                                    value={index}
                                    component='div'
                                    label={(
                                        <Box sx={{
                                            display: "flex",
                                            minWidth: { sm: 150 },
                                            alignItems: "center",
                                            px: 1
                                        }}
                                        >
                                            {tab.renderIcon ? tab.renderIcon({ sx: { mr: 1 } }) : (
                                                tab.Icon ? (
                                                    <Box
                                                        component={tab.Icon}
                                                        sx={{ mr: 1, fontSize: "1.5em" }}
                                                    />
                                                ) : null
                                            )}
                                            {/* {tab.Icon ? (
                                    <tab.Icon
                                        sx={{ mr: 1 }}
                                        fontSize="small"
                                        color="inherit"
                                        size="1em"
                                    />
                                ) : (tab.renderIcon ? tab.renderIcon({ sx: { mr: 1 } }) : null)} */}
                                            <Typography
                                                variant="button"
                                                sx={{ textAlign: 'left', color: 'inherit', cursor: 'pointer' }}
                                                onClick={() => markCurrent(index)}
                                            >
                                                {tab.title}
                                            </Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            {tabs.length < 2 && currentTab && currentTab.location && currentTab.location.pathname === "/" ? null : (
                                                <IconButton
                                                    size="small"
                                                    sx={{ mx: 0.5 }}
                                                    onClick={() => close(index)}
                                                    color="inherit"
                                                >
                                                    <Close fontSize="inherit" />
                                                </IconButton>
                                            )}
                                        </Box>
                                    )}
                                />
                            ))}
                            <StyledTab
                                component="div"
                                // value={-1}
                                sx={{ minWidth: 0 }}
                                label={(
                                    <Box sx={{
                                        px: 1.25
                                    }}
                                    >
                                        <Tooltip title="فرآیند جدید" placement="bottom">
                                            <IconButton
                                                className="new-tab"
                                                onClick={() => openInNew('/')}
                                            >
                                                <Add
                                                    fontSize="small"
                                                    color="inherit"
                                                    size="1em"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            />
                        </Tabs>
                    </Box>
                    <Box sx={{ position: 'absolute', right: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50px' }}>
                        <Tooltip arrow title="صفحه قبل">
                            <span>
                                <IconButton
                                    onClick={() => navigate(prev, { replace: true, state: prev.state })}
                                    disabled={!prev}
                                >
                                    <ArrowBack />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </TabsContainer>
            </Slide>
            <Toolbar variant="dense" />
        </div>
    )
}

export default TabBar;