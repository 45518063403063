import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button, DialogActions,
    DialogTitle, Divider, IconButton, InputAdornment, ListItemButton, ListItemIcon, ListItemText
} from "@mui/material";
import format from "date-fns-jalali/format";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useMiddleware } from "../../../Application";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import ResponsiveTable from "../../../components/ResponsiveTable";
import Text from "../../../components/Text";
import TextField from "../../../components/TextField";
import useDebounce from '../../../utils/useDebounce';
import useGetRowNumber from "../../../utils/useGetRowNumber";
import useResponsiveTableFetch from "../../../utils/useResponsiveTableFetch";
import FilePreviwIcon from "./FilePreviewIcon";


const FilesTable = ({
    loading,
    meta,
    files,
    selected,
    onSelect,
    infiniteScrollProps,
    page,
    perPage,
    onPageChange,
    onPerPageChange
}) => {

    const getRowNumber = useGetRowNumber();

    const isSelected = (category) => {
        if (!selected)
            return false;

        if (Array.isArray(selected))
            return selected.filter(m => m.id === category.id).length > 0;

        return selected.id === category.id;
    }


    return (
        <ResponsiveTable
            data={files}
            loading={loading}
            infiniteScrollProps={infiniteScrollProps}
            definitions={[
                {
                    title: 'ردیف',
                    headerCellProps: {
                        align: "center"
                    },
                    rowCellProps: {
                        align: "center"
                    },
                    renderCell: (row, index) => <Text>{getRowNumber(index)}</Text>
                }, {
                    title: 'فایل',
                    rowCellProps: {
                        width: 300
                    },
                    renderCell: row => (
                        <Box
                            // onClick={() => handleView(row)}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <FilePreviwIcon file={row} />
                            <Box sx={{ pl: 2 }}>
                                <strong dir="ltr">{row.name}</strong>
                                <br />
                                <small>{String(row.ext).toUpperCase()}</small>
                            </Box>
                        </Box>
                    )
                }, {
                    field: 'modifiedAt',
                    title: 'آخرین تغییر',
                    headerCellProps: {
                        align: "center"
                    },
                    rowCellProps: {
                        align: "center"
                    },
                    render: row => row.modifiedAt && <Text>{format(new Date(row.modifiedAt), "dd MMMM yyyy")}</Text>,
                }, {
                    field: 'sizeFormatted',
                    title: 'حجم',
                    headerCellProps: {
                        align: "center"
                    },
                    rowCellProps: {
                        align: "center"
                    },
                    render: row => <span dir="ltr"><Text>{row.sizeFormatted}</Text></span>
                }, {
                    title: 'فرمت',
                    renderList: row => <span dir="ltr">{String(row.ext).toUpperCase()}</span>
                }
            ]}
            renderSheetTitle={row => row.name}
            renderListItemChildren={(row, index) => (
                <ListItemButton
                    key={index}
                    selected={isSelected(row)}
                    onClick={() => onSelect(row)}
                >
                    <ListItemIcon>
                        {isSelected(row) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </ListItemIcon>
                    <ListItemText
                        primary={<strong>{row.fullName}</strong>}
                    />
                </ListItemButton>
            )}
            tableRowProps={row => ({
                hover: true,
                striped: false,
                selected: isSelected(row),
                onClick: e => onSelect(row)
            })}

            paginationProps={{
                count: meta.total,
                rowsPerPage: perPage || 25,
                page: page || 0,
                onPageChange: (e, page) => onPageChange(page),
                onRowsPerPageChange: e => onPerPageChange(e.target.value)
            }}

            listItemProps={{
                disablePadding: selected
            }}

            listProps={{
                disablePadding: !selected
            }}
        />
    )
}


const FileSelectDialog = ({
    open,
    onClose,
    selected,
    onSelect,
    onClear
}) => {

    const middleware = useMiddleware('Storage.Main');

    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 500);

    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        perPage: 25
    })

    const { data, total, loading, loadMore, reload } = useResponsiveTableFetch({
        handleFetch: params => middleware.list({
            ...params,
            ...paginationParams,
            query: debouncedQuery
        }),
        loadDeps: [paginationParams, debouncedQuery],
        infiniteScrollDefaultParams: paginationParams
    });

    const uploadEl = useRef();

    const { enqueueSnackbar } = useSnackbar();

    const uploadFiles = e => {
        let filesToUpload = [];
        for (let i = 0; i < e.target.files.length; i++) {
            filesToUpload = [...filesToUpload, {
                file: e.target.files[i]
            }];
        }

        enqueueSnackbar('Upload files', {
            variant: "uploadFile",
            persist: true,
            files: filesToUpload,
            onComplete: reload
        });
    }


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>انتخاب فایل</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="جستجو فایل..."
                    variant="filled"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    // autoFocus
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }}
                />
            </Box>
            <FilesTable
                loading={loading}
                files={data}
                meta={{ total }}
                selected={selected}
                onSelect={onSelect}
                infiniteScrollProps={{
                    loading: false,
                    hasNextPage: data.length < total,
                    onLoadMore: loadMore
                }}
                page={paginationParams.page}
                perPage={paginationParams.perPage}
                onPageChange={page => setPaginationParams({ ...paginationParams, page })}
                onPerPageChange={perPage => setPaginationParams({ ...paginationParams, perPage })}
            />
            <Divider />
            <DialogActions>
                <Button type="button" variant="contained" onClick={onClose}>تایید و بستن</Button>
                <Button type="button" variant="outlined" onClick={() => uploadEl.current && uploadEl.current.click()}>بارگذاری جدید</Button>
                {onClear && <Button type="button" onClick={onClear}>پاک کردن</Button>}
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>

            <input
                type="file"
                multiple
                ref={uploadEl}
                style={{ display: 'none' }}
                onChange={uploadFiles}
            />
        </ResponsiveDialog>
    )
}

export default FileSelectDialog