import DashboardIconOutlined from "@mui/icons-material/DashboardOutlined";
import AccountsIconOutlined from "@mui/icons-material/BadgeOutlined";
import CategoriesIconOutlined from "@mui/icons-material/AccountTreeOutlined";

const tasks = {
    manageAccounts: {
        title: 'مدیریت حساب‌ها',
        Icon: AccountsIconOutlined,
        path: '/accounts',
        subtasks: []
    },
    manageCategories: {
        title: 'مدیریت دسته‌ها',
        Icon: CategoriesIconOutlined,
        path: '/categories',
        subtasks: []
    },
    history: {
        title: 'تاریخچه رویدادهای مالی',
        Icon: CategoriesIconOutlined,
        path: '/transactions',
        subtasks: []
    },
    newTransaction: {
        title: 'افزودن رویداد مالی',
        Icon: CategoriesIconOutlined,
        path: '/transactions/new',
        subtasks: []
    },
}

export default tasks;