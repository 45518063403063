import Warning from "@mui/icons-material/Warning";
import {
    Box, Link as MuiLink, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination, TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMiddleware } from "../../../../Application";
import DetailedDate from "../../../../components/DetailedDate";
import TableLoader from "../../../../components/TableLoader";


const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.hover : theme.palette.common.white,
    '&:focus': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SuplliedItemsTable = () => {

    const [page, setPage] = useState(0);

    const middleware = useMiddleware('ceremonies/consignment.clients');

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const _loadDataAsync = async () => {
        const response = await middleware.supplied(params.clientId, { page });
        if (response.ok) {
            setItems(response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        _loadDataAsync();
    }, [params.clientId]);


    if (loading) {
        return (
            <TableLoader />
        )
    }

    if (items.length < 1)
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <Warning fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )

    return (
        <Box>
            <TableContainer>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ردیف</TableCell>
                            <TableCell width={150}>محصول</TableCell>
                            <TableCell align="center">تاریخ رزرو</TableCell>
                            <TableCell align="center">صورت‌حساب</TableCell>
                            <TableCell>مشتری</TableCell>
                            <TableCell align="center">رزرو شده</TableCell>
                            <TableCell align="center">برگشتی</TableCell>
                            <TableCell align="center">خسارتی</TableCell>
                            <TableCell align="center">مانده</TableCell>
                            <TableCell align="center">تاریخ برگشت</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <StyledTableRow key={index}>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell>{item.item.title}</TableCell>
                                <TableCell align="center">
                                    <DetailedDate date={item.invoice.reservedAt} />
                                </TableCell>
                                <TableCell align="center">
                                    <MuiLink
                                        color="inherit"
                                        onClick={() => {
                                            navigate(
                                                '/invoice/'.concat(item.invoice.id),
                                                { state: { prev: location } }
                                            )
                                        }}
                                    >
                                        {item.invoice.number}
                                    </MuiLink>
                                </TableCell>
                                <TableCell>
                                    <MuiLink
                                        color="inherit"
                                        onClick={() => {
                                            navigate(
                                                '/client/'.concat(item.invoice.client.id),
                                                { state: { prev: location } }
                                            )
                                        }}
                                    >
                                        {item.invoice.client.fullName}
                                    </MuiLink>
                                </TableCell>
                                <TableCell align="center">{item.reservedQuantity}</TableCell>
                                <TableCell align="center">{item.totalReturned}</TableCell>
                                <TableCell align="center">{item.totalDamaged}</TableCell>
                                <TableCell align="center">{item.currentReserved}</TableCell>
                                <TableCell align="center">
                                    {item.invoice.isReturned ? (
                                        <DetailedDate date={item.invoice.returnedAt} />
                                    ) : '-'}
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={items.length}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={setPage}
                labelDisplayedRows={({ from, to, count }) => `${from}–${to} از مجموع ${count !== -1 ? count : `بیشتر از ${to}`} ردیف`}
            />
        </Box>
    )
}

export default SuplliedItemsTable;