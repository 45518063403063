import CloseIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import NoResultItem from "@mui/icons-material/Warning";
import {
    Box,
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogTitle, Divider, IconButton, InputAdornment, Paper, styled, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import { createRef, forwardRef, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useMiddleware, useModule } from "../../../Application";
import { useAuth } from "../../../auth";
import TextField from "../../../components/TextField";
import useDebounce from '../../../utils/useDebounce';
import useDialog from '../../../utils/useDialog';
import ProductFormDialog from './ProductFormDialog';


const StyledTableRow = styled(TableRow, {
    shouldForwardProp: prop => prop !== 'selected'
})(({ theme, selected }) => ({
    cursor: 'pointer',
    backgroundColor: selected ? theme.palette.action.hover : theme.palette.common.white,
    '&:focus': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ProductsTable = forwardRef(({
    loading,
    products,
    selected,
    onSelect,
    injectionParams,
    onBlurStart,
    onBlurEnd
}, ref) => {

    const { inject } = useAuth();

    const [rowsRefs, setRowsRefs] = useState([]);

    useEffect(() => {
        setRowsRefs((refs) =>
            products.map(
                (_, i) => refs[i] || createRef()
            ),
        )
    }, [products.length]);

    const handleTableNavigation = (e, product, index) => {
        if ([38, 40].indexOf(e.keyCode) > -1) {
            e.preventDefault();
        }
        if (e.keyCode === 38 && index === 0) {
            onBlurStart();
        } else if (e.keyCode === 38 && index === 1) {
            ref.current?.focus();
        } else if (e.keyCode === 38 && index > 1) {
            rowsRefs[index - 1].current?.focus();
        } else if (e.keyCode === 40 && index < products.length - 1) {
            rowsRefs[index + 1].current?.focus();
        } else if (e.keyCode === 40 && index === products.length) {
            onBlurEnd();
        } else if (e.keyCode === 13) {
            onSelect(product);
        }
    }


    if (loading) {
        return (
            <Box sx={{ px: 5, py: 15, textAlign: "center" }}>
                <CircularProgress />
            </Box>
        )
    }

    if (products.length < 1) {
        return (
            <Box sx={{ px: 5, py: 5, textAlign: "center" }}>
                <NoResultItem fontSize="large" />
                <p>موردی جهت نمایش وجود ندارد.</p>
            </Box>
        )
    }

    return (
        <TableContainer sx={{ overflow: "auto", height: 300 }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">کد</TableCell>
                        <TableCell>محصول</TableCell>
                        {inject('products', 'ProductSelectDialog.HeadCells')}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product, index) => (
                        <StyledTableRow
                            key={index}
                            selected={selected && selected.id === product.id}
                            hover
                            onClick={() => onSelect(product)}
                            onKeyDown={e => handleTableNavigation(e, product, index)}
                            tabIndex={0}
                            ref={index === 0 ? ref : rowsRefs[index]}
                        >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{product.code}</TableCell>
                            <TableCell>{product.title}</TableCell>
                            {inject('products', 'ProductSelectDialog.BodyCells', {
                                product,
                                injectionParams
                            })}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
})

const PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


const ProductSelectDialog = ({
    open,
    onClose,
    selected,
    onSelect,
    onClear,
    initialQuery = '',
    searchQueryArgs = {},
    injectionParams = {}
}) => {

    const module = useModule('products');
    const middleware = useMiddleware('products.main');

    const queryRef = useRef();
    const tableRef = useRef();

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    const [query, setQuery] = useState(initialQuery);


    const debouncedQuery = useDebounce(query, 500);

    const _loadProductsAsync = async () => {
        const response = await middleware.list({
            ...searchQueryArgs,
            query: debouncedQuery,
            ...(query === '' ? {
                page: 0,
                perPage: 100
            } : {})
        });
        if (response.ok) {
            setProducts(response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        setQuery(initialQuery);
    }, [initialQuery])

    useEffect(() => {
        if (open) {
            _loadProductsAsync();
        } else {
            const timer = setTimeout(() => {
                setLoading(true);
                if (query !== '') setQuery('');
            }, 500);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [
        open,
        debouncedQuery,
        ...(module.inject('ProductSelectDialog.getEffectDeps', injectionParams))
    ]);

    const {
        isOpen: isAddFormDialogOpen,
        isOpening: isAddFormDialogOpening,
        open: openAddFormDialog,
        close: closeAddFormDialog
    } = useDialog();

    const handleNavigation = (e) => {
        if ([38, 40].indexOf(e.keyCode) > -1) {
            e.preventDefault();
        }
        if (e.keyCode === 40) {
            tableRef.current?.focus();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            PaperComponent={PaperComponent}
            hideBackdrop
            draggable
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>انتخاب محصول</Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    size="small"
                    label="کد یا عنوان محصول"
                    variant="filled"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    autoFocus
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }}
                    inputRef={queryRef}
                    onKeyDown={handleNavigation}
                />
            </Box>
            <ProductsTable
                loading={loading}
                products={products}
                selected={selected}
                onSelect={onSelect}
                ref={tableRef}
                injectionParams={injectionParams}
                onBlurStart={() => queryRef.current?.focus()}
            />
            <Divider />
            <DialogActions>
                <Button
                    type="button"
                    onClick={() => openAddFormDialog()}
                    disabled={isAddFormDialogOpening}
                    variant="contained"
                >
                    ثبت جدید
                </Button>
                {onClear && <Button type="button" onClick={onClear}>پاک کردن</Button>}
                <Box sx={{ flexGrow: 1 }}></Box>
                {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
            </DialogActions>

            <ProductFormDialog
                open={isAddFormDialogOpen}
                onClose={closeAddFormDialog}
                onCreateOrUpdate={product => _loadProductsAsync()}
            />
        </Dialog>
    )
}

export default ProductSelectDialog