import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log('This is fucking error', { error, errorInfo });
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={6} xl={4}>
                        <Card variant="outlined" sx={{ my: 10 }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ textAlign: 'center', mb: 7 }}>مشکلی در پردازش اطلاعات پیش آمده.</Typography>
                                <p>لطفا مشکل پیش آمده را از طریق تیکت پشتیبانی به کارشناسان فنی فراهاب گزارش دهید تا به آن رسیدگی شود.</p>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 4 }}
                                    LinkComponent='a'
                                    href="/support/tickets/new"
                                >ارسال تیکت پشتیبانی</Button>
                                <p>و تا زمان رفع اشکال، موقتا این صفحه در دسترس نخواهد بود و باید مجددا بارگزاری نمایید.</p>
                                <Button
                                    LinkComponent='a'
                                    href="/"
                                    variant="contained"
                                    size="large"
                                >بارگزاری مجدد</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )
        }

        return this.props.children;
    }
}

export default AppErrorBoundary