import { lazy } from "react";

const InvoiceForm = lazy(() => import('./pages/InvoiceForm/InvoiceForm'));
// const InvoiceDetails = lazy(() => import('./pages/InvoiceDetails/InvoiceDetails'));
const Invoices = lazy(() => import('./pages/Invoices/Invoices'));
const Reports = lazy(() => import('./pages/Reports/Reports'));
// const InvoiceTable = lazy(() => import('./pages/InvoiceDetails/InvoiceTable'));


const routes = module => {
	// const injections = module.inject('routes.invoices.details');

	return [
		{
			variant: "dashboard",
			private: true,
			path: '/reports',
			element: <Reports />,
			authorize: ({ membership }) => {

				if (
					membership.roles.map(r => r.identifier).includes('owner') ||
					membership.roles.map(r => r.identifier).includes('admin')
				)
					return true;

				return false;
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/invoices/:label/new',
			element: <InvoiceForm />,
			authorize: ({ membership }) => {

				if (
					membership.roles.map(r => r.identifier).includes('owner') ||
					membership.roles.map(r => r.identifier).includes('admin')
				)
					return true;

				return false;
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/invoices/:label',
			element: <Invoices />,
			authorize: ({ membership }) => {

				if (membership.roles.map(r => r.identifier).includes('owner'))
					return true;

				return false;
			}
		},
		{
			variant: "dashboard",
			private: true,
			path: '/invoice/:invoiceId/edit',
			element: <InvoiceForm />,
			authorize: ({ membership }) => {

				if (membership.roles.map(r => r.identifier).includes('owner'))
					return true;

				return false;
			}
		},
	]
};

export default routes;