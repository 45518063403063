import React from 'react';
import ReactDOM from 'react-dom/client';
import Container from './Container';
import reportWebVitals from './reportWebVitals';
import { ApplicationProvider } from './Application';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.NODE_ENV === "production" && "https://b555c1c3a257839c7f43a1c8c6ffa25c@o4505451996512256.ingest.sentry.io/4506318976909312",
    // integrations: [
    //     new Sentry.BrowserTracing({
    //         tracePropagationTargets: ["https://api.farahub.com"],
    //     }),
    //     new Sentry.Replay(),
    // ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApplicationProvider>
        <Container />
    </ApplicationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
