import { Box, Divider, IconButton, styled } from '@mui/material';
import PropTypes from "prop-types";
import { forwardRef, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import FormatBoldOutlined from '@mui/icons-material/FormatBoldOutlined';
import FormatItalicOutlined from '@mui/icons-material/FormatItalicOutlined';
import FormatStrikethroughOutlined from '@mui/icons-material/FormatStrikethroughOutlined';
import FormatUnderlinedOutlined from '@mui/icons-material/FormatUnderlinedOutlined';
import FormatListBulletedOutlined from '@mui/icons-material/FormatListBulletedOutlined';
import FormatListNumberedOutlined from '@mui/icons-material/FormatListNumberedOutlined';


const Container = styled(Box, {
    shouldForwardProp: prop => !["styleOverrides", "readOnly"].includes(prop)
})(({ theme, styleOverrides, readOnly }) => ({
    '& .ql-container': {
        boxSizing: 'border-box',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        height: '100%',
        margin: 0,
        position: 'relative',
        ...(styleOverrides && styleOverrides.container)
    },
    '& .ql-editor': {
        boxSizing: 'border-box',
        // lineHeight: theme.typography.body1.lineHeight,
        height: '100%',
        outline: 'none',
        overflowY: 'auto',
        padding: readOnly ? '1px 0' : '12px 15px',
        tabSize: 4,
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        '& p, & ol, & ul, & pre, & blockquote, & h1, & h2, & h3, & h4, & h5, & h6': {
            margin: 0,
            padding: 0,
            counterReset: 'list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9'
        },
        '& ol, & ul': {
            listStylePosition: 'inside'
        },
        '> *': {
            cursor: 'text'
        },
        ...(styleOverrides && styleOverrides.editor)
    },
    '.ql-clipboard': {
        left: '-100000px',
        height: '1px',
        overflowY: 'hidden',
        position: 'absolute',
        top: '50%',
        '& p': {
            margin: 0,
            padding: 0
        }
    },
    '.ql-editor.ql-blank': {
        '&::before': {
            color: 'rgba(0,0,0,0.6)',
            content: 'attr(data-placeholder)',
            fontStyle: '',
            left: '15px',
            pointerEvents: 'none',
            position: 'absolute',
            right: '15px'
        }
    }
    // ...(styleOverrides && styleOverrides(theme))
}));

const Toolbar = forwardRef(({ disabled }, ref) => {
    return (
        <Box ref={ref} sx={theme => ({
            p: 1,
            display: 'flex',
            background: theme.palette.grey[100],
            transition: 'all .16s ease-in-out'
        })}
        >
            <span className="">
                <IconButton
                    size="small"
                    color="inherit"
                    className="ql-bold"
                    disabled={disabled}
                    sx={{ transition: 'all .16s ease-in-out' }}
                >
                    <FormatBoldOutlined fontSize="small" />
                </IconButton>

                <IconButton
                    size="small"
                    color="inherit"
                    className="ql-italic"
                    disabled={disabled}
                    sx={{ transition: 'all .16s ease-in-out' }}
                >
                    <FormatItalicOutlined fontSize="small" />
                </IconButton>

                <IconButton
                    size="small"
                    color="inherit"
                    className="ql-underline"
                    disabled={disabled}
                    sx={{ transition: 'all .16s ease-in-out' }}
                >
                    <FormatUnderlinedOutlined fontSize="small" />
                </IconButton>

                <IconButton
                    size="small"
                    color="inherit"
                    className="ql-strike"
                    disabled={disabled}
                    sx={{ transition: 'all .16s ease-in-out' }}
                >
                    <FormatStrikethroughOutlined fontSize="small" />
                </IconButton>
            </span>
            <Divider orientation="vertical" flexItem variant="middle" />
            <span className="">
                <IconButton
                    size="small"
                    color="inherit"
                    className="ql-list"
                    value="ordered"
                    disabled={disabled}
                    sx={{ transition: 'all .16s ease-in-out' }}
                >
                    <FormatListNumberedOutlined fontSize="small" />
                </IconButton>

                <IconButton
                    size="small"
                    color="inherit"
                    className="ql-list"
                    value="bullet"
                    disabled={disabled}
                    sx={{ transition: 'all .16s ease-in-out' }}
                >
                    <FormatListBulletedOutlined fontSize="small" />
                </IconButton>
            </span>
        </Box>
    )
})


const TextEditor = forwardRef(
    ({
        value,
        onChange,
        containerProps,
        modules = {},
        disabled = false,
        readOnly = false,
        toolbarPlacement = "top",
        ...editorProps
    }, ref) => {

        const [toolbarNode, setToolbarNode] = useState()

        const editorModules = useMemo(() => {
            const config = {
                toolbar: {
                    container: toolbarNode,
                    // handlers: toolbarHandlers
                },
                ...modules
            }

            return config
        }, [toolbarNode])

        const Editor = (
            <ReactQuill
                ref={ref}
                theme={false}
                value={value}
                onChange={(newValue, delta, source) => onChange && onChange(newValue)}
                modules={editorModules}
                readOnly={readOnly}
                {...editorProps}
            />
        )


        return (
            <Container readOnly={readOnly} {...containerProps}>
                {readOnly ? Editor : (
                    <>
                        {toolbarPlacement === "top" && <Toolbar ref={setToolbarNode} disabled={disabled} />}
                        {!!toolbarNode && Editor}
                        {toolbarPlacement === "bottom" && <Toolbar ref={setToolbarNode} disabled={disabled} />}
                    </>
                )}
            </Container>
        )
    }
)

TextEditor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    styleOverrides: PropTypes.shape({
        container: PropTypes.object,
        editor: PropTypes.object
    })
}

export default TextEditor;