import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class MainMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Main';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/files',
        upload: '/files',
        download: '/files/:fileId',
        delete: '/files/:fileId',
    }

    /**
     * Get download url for sepecific file
     * 
     * @return string
     */
    getDirectDownloadUrl(fileId, token, ws) {
        return this.serializePath(this.replaceParams(this.paths.download, { fileId }), {
            'auth-token': token,
            'workspace-id': ws
        })
    }

    /**
     * Get list of files match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args, config) {
        try {
            const response = await axios(this.serializePath(this.paths.list, args), config);
            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Upload file
     *  
     * @return Promise
     */
    async upload(data, config) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.upload,
                data,
                ...config
            });

            return {
                ...response.data,
            };
        } catch (error) {
            if (error.response.status === 422) {
                const { status } = error.response;
                return {
                    ...error.response.data,
                    status
                }
            }
            throw new AxiosError(error)
        }
    }

    /**
     * Download file
     *  
     * @return string
     */
    async download(fileId, config) {
        try {
            const response = await axios(this.replaceParams(this.paths.download, { fileId }), config);
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Delete an existing file from db
     *  
     * @return Promise
     */
    async delete(fileId, config) {
        try {
            const response = await axios({
                method: 'delete',
                url: this.replaceParams(this.paths.delete, { fileId })
            }, config);
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default MainMiddleware;