import Products from "./products";
import Invoices from "./invoices";
import Clients from "./clients";

const middlewares = [
    Products,
    Invoices,
    Clients
];

export default middlewares;