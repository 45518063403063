import { Container as MuiContainer, styled } from "@mui/material";


const Container = styled(MuiContainer, {
    shouldForwardProp: prop => !["fullHeight"].includes(prop)
})(({ theme, fullHeight }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
        height: !fullHeight && 'unset',
        padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3)
    }
}))

const ResponsiveContainer = ({
    children,
    toolbarProps = { variant: "dense" },
    ...containerProps
}) => {
    return (
        <Container {...containerProps}>
            {children}
            {/* <Toolbar {...toolbarProps} /> */}
        </Container>
    )
}

export default ResponsiveContainer;