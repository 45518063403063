import { Icon as Iconify } from "@iconify/react";

const tasks = {
	dashboard: {
		title: `داشبورد باشگاه مشتریان`,
		Icon: (props) => (
			<Iconify {...props} icon="solar:presentation-graph-bold-duotone" />
		),
		path: `/dashboard`,
		keywords: [],
		default: true,
		subtasks: [],
	},
	messages: {
		title: `مدیریت پیامک‌ها`,
		Icon: (props) => (
			<Iconify {...props} icon="solar:chat-round-call-bold-duotone" />
		),
		path: `/messages`,
		keywords: [],
		default: true,
		subtasks: [],
	},
};

export default tasks;
