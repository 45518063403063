import AndroidOutlined from '@mui/icons-material/AndroidOutlined';
import LaptopWindowsOutlined from '@mui/icons-material/LaptopWindowsOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlined from '@mui/icons-material/ManageAccountsOutlined';
import { ButtonBase, Divider, ListItem, ListItemAvatar, ListItemText, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Fragment, useState } from 'react';
import { useTask } from '../../../Application';
import { useAuth } from '../../../auth';
import BottomSheet from '../../../components/BottomSheet';
import useNavigateTask from '../../../utils/useNavigateTask';


const AccountButton = styled(ButtonBase)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    gap: 4,
    padding: theme.spacing(0.5, 1),
    [theme.breakpoints.up('sm')]: {
        border: '1px solid '.concat(theme.palette.divider),
    }
}));


const AccountMenu = () => {

    const navigateTask = useNavigateTask();

    const { user, logout } = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null);

    const taskAccountSettings = useTask('Authentication.settings');

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const WrapperComponent = isDesktop ? Menu : BottomSheet;


    return (
        <Fragment>
            <AccountButton onClick={handleClick}>
                <Avatar
                    // alt="Remy Sharp"
                    // src="/static/images/avatar/2.jpg"
                    sx={{
                        width: 28,
                        height: 28
                    }}
                />
                {isDesktop && (
                    <Stack direction="column" alignItems="start">
                        <strong>{user.fullName || user.username}</strong>
                        {/* <span>{user.phone}</span> */}
                    </Stack>
                )}
            </AccountButton>
            <WrapperComponent
                open={open}
                onClose={() => setAnchorEl(null)}
                id="account-menu"
                {...(isDesktop && {
                    anchorEl,
                    onClick: handleClose,
                    transformOrigin: { horizontal: 'right', vertical: 'top' },
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
                })}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ mr: 2 }} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<strong>{user.fullName || user.username}</strong>}
                        secondary={user.phone || user.email}
                    />
                </ListItem>
                <Divider />
                <MenuItem onClick={() => navigateTask(taskAccountSettings)}>
                    <ListItemIcon>
                        <ManageAccountsOutlined fontSize="small" />
                    </ListItemIcon>
                    تنظیمات حساب
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                    <ListItemIcon>
                        <LogoutOutlined fontSize="small" />
                    </ListItemIcon>
                    خروج از حساب
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <LaptopWindowsOutlined fontSize="small" />
                    </ListItemIcon>
                    دانلود نرم‌افزار ویندوز
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <AndroidOutlined fontSize="small" />
                    </ListItemIcon>
                    دانلود نرم‌افزار اندروید
                </MenuItem>
            </WrapperComponent>
        </Fragment>
    );
}

export default AccountMenu;