import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class WorkspaceMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Workspace';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = '/workspace';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        modules: '/modules',
        options: '/options',
        quotes: '/quotes',
        settings: '/settings'
    }

    /**
     * List of workspace modules
     * 
     * @param {*} args 
     * @return Promise 
     */
    async modules(args) {
        try {
            const response = await axios(this.serializePath(this.paths.modules, args));

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Update workspace options
     * 
     * @param {array} keys 
     * @return Promise 
     */
    async getOptions(keys = []) {
        try {
            const response = await axios(this.serializePath(this.paths.options, { keys }));

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Update workspace options
     * 
     * @param {*} args 
     * @return Promise 
     */
    async updateOptions(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.options,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get workspace quotes
     * 
     * @param {*} args 
     * @return Promise 
     */
    async quotes() {
        try {
            const response = await axios(this.paths.quotes);

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Update workspace settings
     * 
     * @param {*} args 
     * @return Promise 
     */
    async updateSettings(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.settings,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default WorkspaceMiddleware;