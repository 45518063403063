import replace from "lodash/replace";
import Authentication from "../modules/authentication/module";
import Automation from "../modules/automation/module";
import Broadcast from "../modules/broadcast/module";
import Categories from "../modules/categories/module";
import CeremoniesConsignment from "../modules/ceremonies/consignment/module";
import CeremoniesReservation from "../modules/ceremonies/reservation/module";
import CeremoniesShipment from "../modules/ceremonies/shipment/module";
import Contract from "../modules/contract/module";
import Core from "../modules/core/module";
import CustomersClub from "../modules/customers-club/module";
import Discount from "../modules/discount/module";
import Documentations from "../modules/documentations/module";
import Invoices from "../modules/invoices/module";
import IranServerCloud from "../modules/iranserver-cloud/module";
import DigitalMenu from "../modules/digital-menu/module";
import LaywersCaseManagement from "../modules/lawyers/case-management/module";
import Metas from "../modules/metas/module";
import Notifications from "../modules/notifications/module";
import OnlineShop from "../modules/online-shop/module";
import People from "../modules/people/module";
import PersonalAccounting from "../modules/personal-accounting/module";
import Pricing from "../modules/pricing/module";
import Products from "../modules/products/module";
import Roles from "../modules/roles/module";
import SMS from "../modules/sms/module";
import Storage from "../modules/storage/module";
import Tasks from "../modules/tasks/module";
import Tickets from "../modules/tickets/module";
import Transaction from "../modules/transactions/module";
import CustomersClubManagement from "../modules/customers-club-management/module";
import Tooboard from "../modules/tooboard/module";

/**
 * host location used for development
 * on production will get from window location as it's not in seperated port
 *  
 * @var string
 */
const location = "http://my.farahub.local";
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const config = {

    /**
     * The application host
     *
     * @var string
     */
    host: replace((new URL(isDev ? location : window.location)).origin, /my./, 'api.'),


    /**
     * The application socket.io host
     *
     * @var string
     */

    hostSocketIO: replace((new URL(isDev ? location : window.location)).origin, /my./, ''),

    /**
     * The application modules
     *
     * @var Module[]
     */
    modules: [
        Authentication,
        Automation,
        Broadcast,
        Categories,
        CeremoniesConsignment,
        CeremoniesReservation,
        CeremoniesShipment,
        Contract,
        Core,
        CustomersClub,
        CustomersClubManagement,
        Discount,
        Documentations,
        Invoices,
        IranServerCloud,
        DigitalMenu,
        LaywersCaseManagement,
        Metas,
        Notifications,
        OnlineShop,
        People,
        PersonalAccounting,
        Pricing,
        Products,
        Roles,
        SMS,
        Storage,
        Tasks,
        Tickets,
        Transaction,
        Tooboard,
    ]
}

export default config;