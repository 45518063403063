import clone from "lodash/clone";
import get from "lodash/get";
import omit from "lodash/omit";
import setWith from "lodash/setWith";
import { useState } from "react";


const useForm = ({ initialValues, onSubmit, handlePost }) => {

    const [formData, setFormData] = useState(initialValues);
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (errors[e.target.name]) {
            setErrors(omit(errors, e.target.name))
        }
    };

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const register = (fieldName, exceptions = []) => omit({
        name: fieldName,
        value: formData[fieldName],
        onChange: handleChange,
        error: Boolean(errors[fieldName]),
        helperText: errors[fieldName] && errors[fieldName].length > 0 ? errors[fieldName][0] : undefined,
        FormHelperTextProps: {
            error: Boolean(errors[fieldName]),
            sx: { fontSize: '0.7rem', mx: 0 }
        }
    }, exceptions);

    const reset = values => setFormData(values)

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        setLoading(true);
        const response = await handlePost(formData);

        if (response.ok) {
            onSubmit(response);
            setLoading(false)
        } else {
            const { status, errors } = response;
            if (status === 422) {
                setErrors(errors);
                setLoading(false);
            }
        }
    }

    const setValue = (name, value) => setFormData(setWith(clone(formData), name, value, clone))
    const getValue = name => get(formData, name);


    return {
        register,
        errors,
        loading,
        handleSubmit,
        reset,
        setValue,
        getValue
    }
}

export default useForm