import Middleware from "../../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class ClientsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Clients';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        supplied: '/client/:clientId/supplied',
    }

    /**
     * Get list of supplied items by the client match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async supplied(clientId, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.supplied, { clientId }),
                    args
                )
            );

            return {
                ...response.data,
            }
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default ClientsMiddleware;