import Error from "@mui/icons-material/ErrorOutline";
import { Container, Paper, Typography } from "@mui/material";
import ResponsiveContainer from "../components/ResponsiveContainer";



const NotFound = ({ variant }) => {

    if (variant == "root") {
        return (
            <div>
                This is {variant} Not Found
            </div>
        )
    }

    return (
        <ResponsiveContainer>
            <Container disableGutters>
                <Paper
                    sx={{
                        py: 15,
                        textAlign: 'center',
                    }}
                >
                    <Error fontSize="inherit" sx={{ mb: 2, fontSize: 50 }} />
                    <Typography variant="h6">صفحه موردنظر یافت نشد</Typography>
                </Paper>
            </Container>
        </ResponsiveContainer>
    )
}

export default NotFound;