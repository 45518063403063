import { lazy } from 'react';
import Loadable from '../../components/Loadable';

const InvoiceDetails = Loadable(lazy(() => import('./pages/InvoiceDetails/InvoiceDetails')));
const InvoiceTable = lazy(() => import('./pages/InvoiceDetails/InvoiceTable'));

const routes = module => {

    const injections = module.inject('routes.invoices.details');

    return [
        {
            variant: "dashboard",
            private: true,
            path: '/invoice/:invoiceId',
            element: <InvoiceDetails />,
            authorize: ({ membership }) => {

                if (
                    membership.roles.map(r => r.identifier).includes('owner') ||
                    membership.roles.map(r => r.identifier).includes('admin')
                )
                    return true;

                return false;
            },
            children: [
                {
                    variant: "dashboard",
                    private: true,
                    index: true,
                    element: <InvoiceTable />
                },
                ...(injections ? injections : [])
            ]
        },
        //
    ]
};

export default routes;