import { lazy } from "react";
import Loadable from "../../components/Loadable";

import Login from "./pages/Login";

const Settings = Loadable(lazy(() => import('./pages/Settings/Settings')));
const GeneralSettings = lazy(() => import('./pages/Settings/General'));
const SecuritySettings = lazy(() => import('./pages/Settings/Security'));


const routes = module => ([
	{
		variant: "dashboard",
		private: true,
		path: '/account/settings',
		element: <Settings />,
		children: [
			{ variant: "dashboard", private: true, index: true, element: <GeneralSettings /> },
			{ variant: "dashboard", private: true, path: 'security', element: <SecuritySettings /> },
			...(module.inject('routes.settings') || [])
		],
		// authorize: ({ hasRole, hasPermission }) => {
		// 	return hasRole("owner") || hasPermission("person:view");
		// }
	},
	{ variant: "root", path: '/login', element: <Login /> },
]);

export default routes;