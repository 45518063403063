import axios, { AxiosError } from "axios";
import Middleware from "../../../foundation/Middleware";

class PeopleMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'People';

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = 'people';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        transactions: '/:personId/trasactions',
        totalDept: '/:personId/totalDept',
        totalCredit: '/:personId/totalCredit',
        totalBalance: '/:personId/totalBalance',
        settle: '/:personId/settle'
        //
    }

    /**
     * Display a list of all transactions of person
     *  
     * @return Promise
     */
    async transactions(personId, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.transactions, { personId }),
                    args
                )
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get person total dept
     *  
     * @return Promise
     */
    async totalDept(personId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalDept, { personId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get person total dept
     *  
     * @return Promise
     */
    async totalCredit(personId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalCredit, { personId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get person total balance
     *  
     * @return Promise
     */
    async totalBalance(personId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.totalBalance, { personId })
            );
            return {
                ...response.data
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Settle all transactions of a person
     *  
     * @return Promise
     */
    async settle(personId) {
        try {
            const response = await axios.post(
                this.replaceParams(this.paths.settle, { personId })
            );
            return { ...response.data };
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default PeopleMiddleware;