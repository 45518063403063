import CloseIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Box, DialogActions,
    DialogTitle,
    Divider,
    Grid,
    IconButton
} from "@mui/material";
import { useEffect } from "react";
import { useMiddleware } from "../../../Application";
import { useAuth } from "../../../auth";
import ResponsiveDialog from '../../../components/ResponsiveDialog';
import TextField from "../../../components/TextField";
import useLocalStorage from "../../../localStorage/useLocalStorage";
import useDialogProps from '../../../utils/useDialogProps';
import useForm from '../../../utils/useForm';
import AddressFormDialog from "./AddressFormDialog";
import PersonRulesSelectDialog from "./PersonRulesSelectDialog";


// const icon = <CheckBoxOutlineBlankIcon />;
// const checkedIcon = <CheckBoxIcon />;

const displayAddress = address => {
    if (!address)
        return '';

    return [
        address.province,
        address.city,
        address.area,
        address.street,
        address.address
    ].filter(Boolean).join(', ');
}


const PersonFormDialog = ({
    open,
    onClose,
    defaultValues = {},
    onCreateOrUpdate,
    hideRulesSelect = false
}) => {

    const { inject } = useAuth();

    const middleware = useMiddleware('People.Main');

    const initialValues = {
        code: '',
        // roles: [],
        // categories: [],
        firstName: '',
        lastName: '',
        phone: '',
        // telephone: '',
        email: '',
        // address: '',
        // note: '',
        ...Object.assign({}, ...(inject('People', 'PersonForm.initialValues') || [])),
        ...defaultValues
    }

    const {
        register,
        loading,
        handleSubmit,
        reset,
        getValue,
        setValue
    } = useForm({
        initialValues,
        handlePost: data => middleware.createOrUpdate(data),
        onSubmit: onCreateOrUpdate
    })

    const initializeFormAsync = async () => {
        let code = defaultValues.code;
        if (!defaultValues.id) {
            const response = await middleware.getNewCode();
            code = response.code;
        }
        reset({ ...initialValues, code });
    }

    useEffect(() => {
        if (open) initializeFormAsync();
    }, [open]);

    const [addressDialogProps, openAddressDialog, closeAddressDialog] = useDialogProps();
    const [rulesDialogProps, openRulesDialog, closeRulesDialog] = useDialogProps();

    const injectedHooks = Object.assign({}, ...(inject('People', 'PersonForm.Hooks') || []));

    const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "sm"
            }}
        >
            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>{defaultValues.id ? 'بروزرسانی مشخصات شخص' : 'ثبت مشخصات شخص'}</Box>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <Box sx={{ p: 0 }}>
                    {getLocalStorageItem("modules.people.warning", true) && (
                        <Alert
                            variant="filled"
                            color="info"
                            sx={{ borderRadius: 0 }}
                            onClose={() => setLocalStorageItem("modules.people.warning", false)}
                        >
                            برای حداکثر بهره‌وری در گزارش‌گیری‌ها و تحلیل‌های سیستم، تا جای امکان، اطلاعات را کامل و دقیق وارد نمایید.
                        </Alert>
                    )}
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    variant="filled"
                                    size="small"
                                    label="کد"
                                    type="number"
                                    dir="ltr"
                                    {...register("code")}
                                />
                            </Grid>
                            <Grid item xs={8} md={5}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    variant="filled"
                                    label="نام"
                                    required
                                    {...register("firstName")}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    label="نام خانوادگی"
                                    variant="filled"
                                    {...register("lastName")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    label="شماره همراه"
                                    dir="ltr"
                                    variant="filled"
                                    InputProps={{
                                        inputProps: {
                                            className: "pl-fw-secondary"
                                        },
                                    }}
                                    placeholder="09XXXXXXXXX"
                                    {...register("phone")}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    label="ایمیل"
                                    dir="ltr"
                                    type="email"
                                    variant="filled"
                                    InputProps={{
                                        inputProps: {
                                            className: "pl-fw-secondary"
                                        },
                                    }}
                                    placeholder="example@gmail.com"
                                    {...register("email")}
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth={true}
                                    size="small"
                                    dir="ltr"
                                    label="شماره تماس"
                                    variant="filled"
                                    {...register("telephone")}
                                />
                            </Grid> */}
                        </Grid>
                    </Box>
                    {inject('People', 'PersonForm.PostForm', { register, loading, getValue, setValue, reset, ...injectedHooks })}
                    {/* <Divider /> */}
                    {/* <List disablePadding dense>
                        <ListItem disablePadding dense>
                            <ListItemButton onClick={() => openAddressDialog()}>
                                <ListItemText
                                    primary={getValue("address") ? displayAddress(getValue("address")) : <small>برای انتخاب کلیک کنید</small>}
                                    secondary="آدرس"
                                />
                            </ListItemButton>
                        </ListItem>
                    </List> */}
                    {/* <Divider /> */}
                    {/* {!hideRulesSelect && (
                        <>
                            <List disablePadding dense>
                                <ListItem disablePadding dense>
                                    <ListItemButton onClick={() => openRulesDialog()}>
                                        <ListItemText
                                            primary={getValue('roles') && getValue('roles').length > 0 ? getValue('roles').map(r => r.name).join(', ') : <small>برای انتخاب کلیک کنید</small>}
                                            secondary="سمت‌ها"
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider />
                        </>
                    )} */}
                    {/* <Box sx={{ p: 2 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            size="small"
                            label="یادداشت"
                            multiline
                            variant="filled"
                            rows={3}
                            {...register("note")}
                        />
                    </Box> */}
                </Box>
                <Divider />
                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                    >
                        ثبت مشخصات
                    </LoadingButton>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    {/* <Button type="button" onClick={onClose}>انصراف</Button> */}
                </DialogActions>
            </form>

            <AddressFormDialog
                {...addressDialogProps}
                defaultValues={getValue('address')}
                onChange={address => {
                    setValue('address', address);
                    closeAddressDialog();
                }}
            />

            <PersonRulesSelectDialog
                {...rulesDialogProps}
                multiple
                defaultValue={getValue('roles')}
                onChange={roles => {
                    setValue('roles', roles);
                    // closeRulesDialog();
                }}
            />

            {inject('People', 'PersonForm.Footer', { register, loading, getValue, setValue, reset, ...injectedHooks })}
        </ResponsiveDialog>
    )
}

export default PersonFormDialog