import { useLocation } from "react-router";


const useLocationHash = () => {

    const location = useLocation();

    const decode = () => !location.hash ? [] : location.hash.replace('#', '').split('&').filter(Boolean)
    const encode = (segments = []) => segments.length < 1 ? '' : '#'.concat(segments.join('&'));
    const addSegment = (segment) => encode([...decode(), segment]);
    const removeSegment = (segment) => encode(decode().filter(seg => seg !== segment));
    const hasSegment = (segment) => decode().filter(seg => seg === segment).length > 0;

    return {
        decode,
        encode,
        addSegment,
        removeSegment,
        hasSegment
    }
}

export default useLocationHash