import { Icon as Iconify } from "@iconify/react";

const tasks = {
    manageAds: {
        title: `آگهی‌ها`,
        Icon: props => <Iconify {...props} icon='solar:chat-round-video-bold-duotone'/>,
        path: `/ads`,
        keywords: [],
        default: true,
        subtasks: []
    },
    adDetails: (ad) => ({
        title: ad?.name,
        Icon: props => <Iconify {...props} icon='solar:chat-round-video-bold-duotone'/>,
        path: '/ads/'.concat(ad?.id)
    }),
    manageBoards: {
        title: `بردها`,
        Icon: props => <Iconify {...props} icon='solar:screencast-2-bold-duotone'/>,
        path: `/boards`,
        keywords: [],
        default: true,
        subtasks: []
    },
    boardDetails: (board) => ({
        title: board?.name,
        Icon: props => <Iconify {...props} icon='solar:screencast-2-bold-duotone'/>,
        path: '/boards/'.concat(board?.id)
    })
}

export default tasks;