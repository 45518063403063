import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import DataList from "../../../../components/DataList"
import InvoiceShipments from "../components/InvoiceShipments"

export const InvoiceDetailsShipments = ({ invoice }) => {
    return (
        <Grid item xs={12}>
            <Card variant="outlined" className="invoice-shipments">
                <CardHeader
                    title="ارسال و باربری"
                    titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                    <DataList
                        data={
                            [{
                                title: 'آدرس ارسال',
                                description: invoice.fullShippingAddress
                            }]
                        }
                    />
                </CardContent>
                {invoice.shipments && invoice.shipments.length > 0 ? (
                    <>
                        <CardHeader
                            title="سرویس‌های باربری"
                            titleTypographyProps={{ variant: "subtitle2" }}
                        />
                        <InvoiceShipments invoice={invoice} />
                    </>
                ) : null}
            </Card>
        </Grid>
    )
}