import { useEffect, useState } from "react";
import LocalStorageContext from "./LocalStorageContext";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import get from "lodash/get";
import AES from "crypto-js/aes";
import ENCUTF8 from "crypto-js/enc-utf8";


const LOCAL_STORAGE_KEY_NAME = "FARAHUB-TOKEN";
const LOCAL_STORAGE_ENCRYPTION_SECRET = "GLOBALS-SECRET";


const LocalStorageProvider = ({ children }) => {

    const getInitialState = () => {

        const value = window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME);

        if (value) {
            const bytes = AES.decrypt(
                localStorage.getItem(LOCAL_STORAGE_KEY_NAME),
                LOCAL_STORAGE_ENCRYPTION_SECRET
            );
            return JSON.parse(bytes.toString(ENCUTF8))
        }

        return {};
    }

    const [state, setState] = useState(getInitialState());

    const setLocalStorageItem = (path, value) => setState(setWith(clone(state), path, value, clone));
    const getLocalStorageItem = (path, defaultValue) => get(state, path, defaultValue);

    useEffect(() => {
        window.localStorage.setItem(
            LOCAL_STORAGE_KEY_NAME,
            AES.encrypt(
                JSON.stringify(state),
                LOCAL_STORAGE_ENCRYPTION_SECRET
            ).toString()
        );
    }, [state]);


    return (
        <LocalStorageContext.Provider value={{ getLocalStorageItem, setLocalStorageItem }}>
            {children}
        </LocalStorageContext.Provider>
    )
}

export default LocalStorageProvider;