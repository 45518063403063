import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useEffect } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import useStorageUpload from "../utilities/useStorageUpload";
import FilePreviewIcon from "./FilePreviewIcon";
import FilePresentOutlined from "@mui/icons-material/FilePresentOutlined";
import formatBytes from "../../../utils/formatBytes";


const UploadListItem = ({
    file,
    attachment,
    onUploadComplete,
    referenceParams,
    ...props
}) => {

    const { upload, progress, uploading } = useStorageUpload({
        extraFormData: {
            ...referenceParams
        },
        onComplete: onUploadComplete,
        onError: error => alert(error)
    });

    useEffect(() => {
        if (file) {
            upload(file);
        }
    }, [file]);


    return (
        <ListItem>
            <ListItemAvatar>
                {uploading ? (
                    <CircularProgressWithLabel value={progress} />
                ) : (
                    <FilePreviewIcon
                        DefaultIcon={<FilePresentOutlined fontSize="large" />}
                        file={attachment || {}}
                    />
                )}
            </ListItemAvatar>
            <ListItemText
                primary={<strong>{file ? file.name : attachment.name}</strong>}
                secondary={file ? formatBytes(file.size) : `${String(attachment.ext).toUpperCase()} ${attachment.sizeFormatted}`}
                primaryTypographyProps={{
                    dir: 'ltr'
                }}
                secondaryTypographyProps={{
                    dir: 'ltr'
                }}
            />
        </ListItem>
    )
}

export default UploadListItem;