import { TableCell } from "@mui/material"
import sumBy from "lodash/sumBy"
import { Fragment, useEffect, useState } from "react"
import LoadableData from "../../../../components/LoadableData";
import { useMiddleware } from "../../../../Application";
import getUnixTime from "date-fns/getUnixTime";


export const ProductSelectDialogHeadCells = () => {
    return (
        <Fragment>
            <TableCell align="center">رزرو شده</TableCell>
            <TableCell align="center">موجود انبار</TableCell>
            <TableCell align="center">موجودی برگشتنی</TableCell>
        </Fragment>
    )
}

export const ProductSelectDialogBodyCells = ({
    product,
    injectionParams
}) => {

    const middleware = useMiddleware('ceremonies/reservation.products');

    const [totalReserved, setTotalReserved] = useState(0);
    const [preReserved, setPreReserved] = useState(0);

    useEffect(() => {
        const loadPreReservedAsync = async () => {
            const response = await middleware.preReserved(product.id, injectionParams.invoice.id);
            if (response.ok) {
                setPreReserved(response.preReserved);
            }
        }

        if (injectionParams.invoice) {
            loadPreReservedAsync()
        }
    }, [product, injectionParams.invoice])

    const getItemInvoiceQuantity = item => sumBy(
        injectionParams.items.filter(i => !i.supplier && i.item.id === item.id),
        i => Number(i.reservedQuantity)
    )

    const availability = product.totalQuantity - totalReserved + preReserved - getItemInvoiceQuantity(product);

    return (
        <Fragment>
            <TableCell align="center">
                <LoadableData
                    loadData={() => middleware.totalReserved(product.id, getUnixTime(new Date(injectionParams.reservedAt)))}
                    getData={response => response.totalReserved}
                    onLoadData={response => setTotalReserved(response.totalReserved)}
                />
            </TableCell>
            <TableCell align="center">
                {availability}
            </TableCell>
            <TableCell align="center">
                <LoadableData
                    loadData={() => middleware.totalReturning(product.id, getUnixTime(new Date(injectionParams.reservedAt)))}
                    getData={response => response.totalReturning}
                />
            </TableCell>
        </Fragment>
    )
}