import { Box, Button, Chip, InputBase, Stack, styled, Typography } from "@mui/material";

const Wrapper = styled((props) => (
	<Stack direction="row" spacing={1} {...props} />
))(({ theme }) => ({
	display: "flex",
	padding: theme.spacing(2, 2, 1, 2),
	borderRadius: theme.shape.borderRadius,
	border: `1px solid `.concat(theme.palette.grey[400]),

	"&:hover": {
		borderColor: theme.palette.grey[600],
	},
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
	flexGrow: 1,
}));

const Field = styled(InputBase)(({ theme }) => ({
	//
}));

const ActionsWrapper = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "stretch",
}));

function containsFarsi(text) {
    const farsiRegex = /[\u0600-\u06FF\u0750-\u077F\uFB8A-\uFB8F\uFB1D-\uFB4F]/;
    return farsiRegex.test(text);
}
	
const messageCount = (text) => {
	if(!text) return 1;

	const FARSI_MESSAGE_LENGTH = 70;
	const ENGLISH_MESSAGE_LENGTH = 170;

	return Math.ceil(containsFarsi(text) ? text.length / FARSI_MESSAGE_LENGTH : text.length / ENGLISH_MESSAGE_LENGTH)
}


const SmsField = ({ size, value, onChange, ...props }) => {

	const APPEND_TEXT = "لغو11";

	return (
		<Wrapper>
			<ContentWrapper>
				<Field
					multiline
					minRows={5}
					margin="none"
					value={value}
					onChange={(e) => onChange(e.target.value)}
					sx={{ height: "100%", padding: 0 }}
					{...props}
				/>
				<Box py={0.5} px={1} ml={-1} sx={{ background: theme => theme.palette.grey[100] }} borderRadius={theme => theme.spacing(0.5)}>
					<Typography variant="caption">متن "<strong>{APPEND_TEXT}</strong>" به پیام شما اضافه خواهد شد.</Typography>
					<Box my={0.25}/>
					<Stack flexDirection="row" alignItems="start" gap={0.25}>
						<Chip size="small" label={<span><strong>{(value?.length || 0) + APPEND_TEXT.length}</strong> کاراکتر</span>}/>
						<Chip size="small" label={<span><strong>{messageCount(value)}</strong> پیام</span>}/>
					</Stack>
				</Box>
			</ContentWrapper>
			<ActionsWrapper spacing={0.5}>
				<Button
					variant="contained"
					size="small"
					onClick={(e) => onChange((value || "").concat("asdasd"))}
					disabled={props.disabled}
				>
					نظرسنجی
				</Button>
				<Button
					variant="contained"
					size="small"
					disabled={props.disabled}
				>
					صورت‌حساب
				</Button>
				<Button
					variant="contained"
					size="small"
					disabled={props.disabled}
				>
					ایموجی
				</Button>
				<Button
					variant="contained"
					size="small"
					disabled={props.disabled}
				>
					لینک دایرکتی
				</Button>
			</ActionsWrapper>
		</Wrapper>
	);
};

export default SmsField;
