import { Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material";


const TableLoader = ({ size, length = 5 }) => {
    return (
        <Table size={size}>
            <TableBody>
                {[...Array(length)].map((a, i) => (
                    <TableRow key={i}>
                        <TableCell width={75}><Skeleton variant="text" /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default TableLoader;