import Core from "./core";
import People from "./people";
import Sms from "./sms";

const hooks = {
	Core,
	People,
	Sms,
};

export default hooks;
