import { Icon as Iconify } from "@iconify/react";

const tasks = {
    manageStorage: {
        title: `فضای ذخیره‌سازی`,
        Icon: props => <Iconify {...props} icon='solar:server-bold-duotone' />,
        path: `/storage/manage`,
        subtasks: []
    },
    fileManager: {
        title: `مدیریت فایل‌ها`,
        Icon: props => <Iconify {...props} icon='solar:folder-with-files-bold-duotone' />,
        path: `/storage/files`,
        subtasks: []
    },
    //
}

export default tasks;