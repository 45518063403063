/* eslint-disable react-hooks/rules-of-hooks */
import Sms from "@mui/icons-material/Sms";
import SmsOutlined from "@mui/icons-material/SmsOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";


const hooks = {
    'Broadcast': {
        'BroadcastForm.Hooks': ({ setValue }) => ({
            'sendSMS': useState(false)
        }),
        'BroadcastForm.Toolbar': ({ sendSMS, focused, key }) => {

            const [sms, setSMS] = sendSMS;

            return (
                <Tooltip title={`ارسال پیامک : ${sms ? 'فعال' : 'غیرفعال'}`} placement="top" key={key}>
                    <span>
                        <IconButton
                            onClick={() => setSMS(!sms)}
                            size="small"
                            color="inherit"
                            sx={{ mr: 1, transition: 'all .16s ease-in-out' }}
                            disabled={!focused}
                        >
                            {sms ? <Sms fontSize="inherit" /> : <SmsOutlined fontSize="inherit" />}
                        </IconButton>
                    </span>
                </Tooltip>
            )
        },
    }
};

export default hooks;