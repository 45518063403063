import { Icon as Iconify } from "@iconify/react";
import ShopOutlined from "@mui/icons-material/ShopOutlined";
import { Avatar, SvgIcon } from "@mui/material";


const tasks = {
    settings: {
        title: `تنظیمات`,
        Icon: props => <Iconify {...props} icon='solar:settings-bold-duotone' />,
        path: `/settings`,
        subtasks: []
    },
    manage: {
        title: `مدیریت اشتراک`,
        Icon: props => <Iconify {...props} icon='solar:notes-bold-duotone' />,
        path: `/subscriptions`,
        subtasks: []
    },
    marketplace: {
        title: `فراهاب مارکت`,
        Icon: ShopOutlined,
        path: `/marketplace`,
        subtasks: []
    },
    manageWorkspaces: {
        title: 'مدیریت فضاهای کار',
        Icon: props => <Iconify {...props} icon='solar:monitor-bold-duotone' />,
        path: `/workspaces`,
        subtasks: []
    },
    workspaceDetails: (workspace) => ({
        title: workspace?.name,
        renderIcon: ({ sx, props }) => (
            <Avatar
                {...props}
                alt={workspace?.name}
                src={workspace?.picture}
                sx={{ ...sx, width: 30, height: 30 }}
            >
                <SvgIcon component={Iconify} icon='solar:monitor-bold-duotone' />
            </Avatar>
        ),
        path: '/workspace/'.concat(workspace?.id)
    }),
    manageModules: {
        title: 'مدیریت برنامه‌ها',
        Icon: props => <Iconify {...props} icon='solar:widget-2-bold-duotone' />,
        path: `/modules`,
        subtasks: []
    },
    // documentations: {
    //     title: `مستندات فراهاب`,
    //     Icon: SettingsOutlined,
    //     path: `/support/docs`,
    //     subtasks: []
    // },
}

export default tasks;