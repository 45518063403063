/**
 * The ceremonies shipment module
 */
import Module from '../../../foundation/Module';
import routes from './routes';
import components from './components';
import middlewares from './middlewares';
import hooks from './hooks';


class ShipmentModule extends Module {

    /**
     * The module name
     * 
     * @var string
     */
    name = 'Ceremonies/Shipment';

    /**
     * The module version
     * 
     * @var string
     */
    version = '1.0.0';

    /**
     * The module base path
     * 
     * use for routing 
     * 
     * @var string
     */
    basePath = '';

    /**
     * The module components
     * 
     * @var array
     */
    components = components;

    /**
     * The module hooks
     * 
     * @var Object
     */
    hooks = hooks;

    /**
     * Register The module
     * 
     * @return void
     */
    register() {
        this.registerMiddlewares(middlewares);
    }

    /**
     * Boot The module
     * 
     * @return void
     */
    boot() {
        this.registerRoutes(routes);
    }
}

export default ShipmentModule;