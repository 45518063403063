import {
    Dialog, DialogContent, useMediaQuery,
    useTheme
} from "@mui/material";
import BundleView from "./BundleView";


const WelcomeDialog = ({
    open,
    onClose
}) => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
            scroll="paper"
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)'
                }
            }}
        >
            <DialogContent sx={{ p: 0 }}>
                <BundleView />
            </DialogContent>
        </Dialog>
    )
}

export default WelcomeDialog;