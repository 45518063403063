import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class MessagesMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Messages';

    /**
     * The middleware base path
     * 
     * @var string
     */
    basePath = '/messages';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        list: '/',
        updateStatus: '/status'
    }

    /**
     * Get list of products match arguments
     * 
     * @param {*} args 
     * @return Promise 
     */
    async list(args) {
        try {
            const response = await axios(
                this.serializePath(this.paths.list, args)
            );

            return {
                ...response.data
            }
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Update message status
     *  
     * @return Promise
     */
    async updateStatus(data) {
        try {
            const response = await axios({
                method: 'post',
                url: this.paths.updateStatus,
                data
            });

            return {
                ...response.data
            };
        } catch (error) {
            const { status } = error.response;
            return {
                ...error.response.data,
                status
            }
        }
    }
}

export default MessagesMiddleware;