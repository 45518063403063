import Middleware from "../../../foundation/Middleware";
import axios, { AxiosError } from "axios";

class WorkspacesMiddleware extends Middleware {
    /**
     * The middleware name
     *
     * @var string
     */
    name = "Workspaces";

    /**
     * The middleware base path
     *
     * @var string
     */
    basePath = "/workspaces";

    /**
     * The middleware paths
     *
     * @var Object
     */
    paths = {
        list: "/",
        create: "/",
        details: "/:workspaceId",
        subscribe: "/:workspaceId/subscribe",
        addOwner: "/:workspaceId/add-owner",
        subscriptions: "/:workspaceId/subscriptions",
    };

    /**
     * Register a new workspace
     *
     * @param {*} args
     * @return Promise
     */
    async list() {
        try {
            const response = await axios(this.paths.list);

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Create a new workspace
     *
     * @param {*} args
     * @return Promise
     */
    async create(data) {
        try {
            const response = await axios({
                method: "post",
                url: this.paths.create,
                data,
            });

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Get workspace details
     *
     * @param string workspaceId
     * @return Promise
     */
    async details(workspaceId) {
        try {
            const response = await axios(
                this.replaceParams(this.paths.details, { workspaceId })
            );
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Subscribe workspace to specified apps
     *
     * @param {*} args
     * @return Promise
     */
    async subscribe(workspaceId, data) {
        try {
            const response = await axios({
                method: "post",
                url: this.replaceParams(this.paths.subscribe, { workspaceId }),
                data,
            });

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Add owner to the workspace
     *
     * @param {*} args
     * @return Promise
     */
    async addOwner(workspaceId, data) {
        try {
            const response = await axios({
                method: "post",
                url: this.replaceParams(this.paths.addOwner, { workspaceId }),
                data,
            });

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error);
        }
    }

    /**
     * Get list of workspace subscriptions
     *
     * @param {*} args
     * @return Promise
     */
    async subscriptions(workspaceId, args) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.subscriptions, {
                        workspaceId,
                    }),
                    args
                )
            );

            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error);
        }
    }
}

export default WorkspacesMiddleware;
