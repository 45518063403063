import { Close } from "@mui/icons-material";
import {
    Box, ButtonBase, Dialog, DialogContent,
    DialogTitle,
    Divider, Grid, IconButton, styled, useMediaQuery,
    useTheme
} from "@mui/material";
import { useState } from "react";
import { useMiddleware } from "../Application";
import { useAuth } from "../auth";


const GRADIENTS = [
    'linear-gradient(to bottom, #24c6dc, #514a9d)',
    'linear-gradient(45deg, #c6ffdd, #fbd786, #f7797d)',
    'linear-gradient(45deg, #d7fe01, #aede0f, #5cb00c, #15661d)',
    'linear-gradient(45deg, #9896f0, #fbc8d5)',
    'linear-gradient(45deg, #1488cc, #2b32b2)',
    'linear-gradient(45deg, #fbee97, #adedd5)',
    'linear-gradient(45deg, #ff7082, #c0e9ff)',
    'linear-gradient(45deg, #bcc5ce, #939fae)',
    'linear-gradient(45deg, #fdfcfb, #e3d2c4)',
    'linear-gradient(45deg, #626262, #9bc5c3)',
    'linear-gradient(45deg, #dbd5ed, #fce7ea)',
];

const PHOTOS = [
    'url(/static/wallpapers/01.jpg)',
    'url(/static/wallpapers/02.jpg)',
];

const GradientButton = styled(ButtonBase, {
    shouldForwardProp: prop => prop !== 'background'
})(({ theme, background }) => ({
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: 150,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    ...(String(background).startsWith('url') ? {
        backgroundImage: background
    } : { background })
}))

const CustomizationDialog = ({
    open,
    onClose
}) => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [activeTab, setActiveTab] = useState('gradient');

    const { workspace, update } = useAuth();

    const middleware = useMiddleware('Core.Membership');

    const updateBackground = async (background) => {
        const response = await middleware.options({ background });

        if (response.ok)
            update('workspace.membership.options.background', background);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            fullScreen={fullScreen}
            scroll="paper"
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>شخصی‌سازی</Box>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ height: { md: 400 }, p: 2 }}>
                <Grid container columns={2} spacing={2}>
                    {GRADIENTS.map((background, index) => (
                        <Grid item xs={1} key={index}>
                            <GradientButton
                                background={background}
                                onClick={() => {
                                    updateBackground(background);
                                    onClose();
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Divider sx={{ my: 4 }}/>
                <Grid container columns={2} spacing={2}>
                    {PHOTOS.map((background, index) => (
                        <Grid item xs={1} key={index}>
                            <GradientButton
                                background={background}
                                onClick={() => {
                                    updateBackground(background);
                                    onClose();
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CustomizationDialog;