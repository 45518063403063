import Middleware from "../../../../foundation/Middleware";
import axios, { AxiosError } from "axios";


class ProductsMiddleware extends Middleware {

    /**
     * The middleware name
     *
     * @var string
     */
    name = 'Products';

    /**
     * The middleware paths
     * 
     * @var Object
     */
    paths = {
        invoices: '/product/:productId/invoices',
        totalReserved: '/product/:productId/totalReserved',
        totalReturning: '/product/:productId/totalReturning',
        preReserved: '/product/:productId/preReserved',
    }

    /**
     * Get the product invoices
     *  
     * @return Promise
     */
    async invoices(productId) {
        try {
            const response = await axios(this.replaceParams(this.paths.invoices, { productId }));
            return {
                ...response.data,
            };
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get the product total reserved on date
     *  
     * @return Promise
     */
    async totalReserved(productId, date) {
        try {
            const response = await axios(
                this.serializePath(this.replaceParams(this.paths.totalReserved, { productId }), { date })
            );
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get the product total returning on date
     *  
     * @return Promise
     */
    async totalReturning(productId, date) {
        try {
            const response = await axios(
                this.serializePath(
                    this.replaceParams(this.paths.totalReturning, { productId }),
                    { date }
                )
            );
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }

    /**
     * Get the product invoice prereserved
     *  
     * @return Promise
     */
    async preReserved(productId, invoiceId) {
        try {
            const response = await axios(
                this.serializePath(this.replaceParams(this.paths.totalReturning, { productId }), { invoiceId })
            );
            return response.data;
        } catch (error) {
            throw new AxiosError(error)
        }
    }
}

export default ProductsMiddleware;