import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, Grid, InputAdornment } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useComponent } from "../../../../Application";
import NumberTextField from "../../../../components/NumberTextField";
import TextField from "../../../../components/TextField";
import useDialog from "../../../../utils/useDialog";


const ShipmentFormDialog = ({ open, initialValue, onClose, onSubmit }) => {

    const defaultValues = {
        driver: '',
        price: ''
    };

    const { control, register, handleSubmit, reset, setValue } = useForm({
        defaultValues
    })

    const driver = useWatch({ control, name: "driver" });

    const ClientSelectDialog = useComponent("clients.ClientSelectDialog");

    useEffect(() => {
        reset(initialValue || defaultValues);
    }, [initialValue])

    const {
        isOpen: isClientDialogOpen,
        isOpening: isClientDialogOpening,
        open: openClientDialog,
        close: closeClientDialog
    } = useDialog();

    const _submit = (data) => {
        reset();
        onClose();
        onSubmit(data);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                ثبت سرویس باربری
            </DialogTitle>
            <Divider />
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            size="small"
                            label="راننده سرویس"
                            variant="filled"
                            value={driver ? driver.fullName : ''}
                            onChange={() => { }}
                            inputProps={{
                                readOnly: true
                            }}
                            onFocus={() => openClientDialog()}
                            onClick={() => openClientDialog()}
                        // onKeyDown={e => e.keyCode === 13 && openClientSelectDialog()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="price"
                            control={control}
                            render={
                                ({ field }) => (
                                    <NumberTextField
                                        fullWidth={true}
                                        size="small"
                                        label="هزینه باربری"
                                        dir="ltr"
                                        variant="filled"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><small>ریال</small></InputAdornment>,
                                        }}
                                        {...field}
                                    />
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit(_submit)}
                >افزودن عامل</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button type="button" onClick={onClose}>انصراف</Button>
            </DialogActions>

            <ClientSelectDialog
                roles={["driver"]}
                open={isClientDialogOpen}
                onClose={closeClientDialog}
                selected={Boolean(driver)}
                onSelect={(driver) => {
                    setValue('driver', driver);
                    closeClientDialog()
                }}
                onClear={() => {
                    setValue('driver', '');
                    closeClientDialog()
                }}
            />
        </Dialog>
    )
}

export default ShipmentFormDialog