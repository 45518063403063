import { Button, Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import BundleImage from "../../../../assets/renew-bundle.jpg";
import TextField from "../../../../components/TextField";


const Transitioned = styled('div', {
    shouldForwardProp: prop => !['out', 'delay', 'duration', 'easing'].includes(prop)
})(({ theme, out, delay, duration, easing }) => ({
    position: 'relative',
    display: 'block',
    transition: theme.transitions.create('all', { delay, duration, easing }),
    top: 0,
    ...(out && {
        top: '-25px',
        visibility: 'hidden',
        opacity: 0,
    })
}));

const BundleView = () => {

    const [goingNext, setGoingNext] = useState(false);

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box sx={{ p: 5 }}>
                        <Transitioned out={goingNext} delay={0} duration={750}>
                            <Typography variant="h6" sx={{ mt: 5 }}>بسته‌ی مورد نیاز خود را انتخاب کنید</Typography>
                        </Transitioned>
                        <Transitioned out={goingNext} delay={0} duration={750}>
                            <Typography variant="subtitle1" sx={{ mb: 10 }}>برای کسب و کار خود یک نام انتخاب کنید تا اعضا و مشتریان برندتان را بهتر بشناسند.</Typography>
                        </Transitioned>
                        <Transitioned out={goingNext} delay={0} duration={750}>
                            <Box sx={{ mb: 15, display: 'flex' }}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    label="عنوان کسب‌و‌کار"
                                    autoFocus
                                />

                            </Box>
                        </Transitioned>
                        <Transitioned out={goingNext} delay={1000} duration={750}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => setGoingNext(true)}
                            >مرحله بعد</Button>
                        </Transitioned>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: '100%', display: 'flex' }}>
                        <Box
                            component='img'
                            src={BundleImage}
                            sx={{ width: '100%', height: 'auto', display: 'block', m: 'auto' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BundleView