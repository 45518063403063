/* eslint-disable react-hooks/rules-of-hooks */
import AttachFileOutlined from "@mui/icons-material/AttachFileOutlined";
import FolderCopyOutlined from "@mui/icons-material/FolderCopyOutlined";
import { Divider, IconButton as MuiIconButton, List, Tooltip } from "@mui/material";
import omit from "lodash/omit";
import { Fragment, useRef } from "react";
import StorageView from "../components/StorageView";
import UploadListItem from "../components/UploadListItem";

import People from "./people";
import Products from "./products";
import Categories from "./categories";


const hooks = {
    People,
    Products,
    Categories,
    'Broadcast': {
        'BroadcastForm.Hooks': ({ setValue }) => ({
            'fileInputRef': useRef(null),
            'uploadFileAsync': async (e) => {

                let attachments = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    attachments = [...attachments, {
                        attachmentModel: 'StorageFile',
                        file: e.target.files[i]
                    }];
                }

                setValue("attachments", attachments);
            }
        }),
        'BroadcastForm.Footer': ({ getValue, setValue, fileInputRef, uploadFileAsync, params, key }) => {
            return (
                <Fragment key={key}>
                    {getValue("attachments").length > 0 && (
                        <>
                            <Divider />
                            <List disablePadding>
                                {getValue("attachments").map((attachment, index) => (
                                    <UploadListItem
                                        key={index}
                                        referenceParams={{
                                            reference: params.caseId,
                                            referenceModel: 'Case',
                                        }}
                                        {...attachment}
                                        onUploadComplete={uploaded => {
                                            setValue("attachments", getValue("attachments").map(
                                                (a, i) => i === index ? ({ ...omit(a, 'file'), attachment: uploaded }) : a
                                            ))
                                        }}
                                    />
                                ))}
                            </List>
                        </>
                    )}
                    <input type="file" multiple ref={fileInputRef} onChange={uploadFileAsync} style={{ display: 'none' }} />
                </Fragment>
            )
        },
        'BroadcastForm.Toolbar': ({ fileInputRef, focused, key }) => {
            return (
                <Tooltip title="افزودن فایل ضمیمه" placement="top" key={key}>
                    <span>
                        <MuiIconButton
                            onClick={() => fileInputRef.current?.click()}
                            size="small"
                            color="inherit"
                            sx={{ mr: 1, transition: 'all .16s ease-in-out' }}
                            disabled={!focused}
                        >
                            <AttachFileOutlined fontSize="inherit" />
                        </MuiIconButton>
                    </span>
                </Tooltip>
            )
        },
    },
    'Lawyers/Case-Management': {
        'routes.cases.details': () => {
            return {
                variant: "dashboard",
                private: true,
                path: 'storage',
                element: <StorageView
                    reference="case"
                    extractReferenceId={params => params.caseId}
                />
            }
        },
        'CaseDetails.TabsList': ({ params }) => {
            return {
                label: 'مدارک و مستندات',
                value: `/case/${params.caseId}/storage`,
                icon: <FolderCopyOutlined />,
                priority: 200,
            }
        },
    }
};

export default hooks;