import { lazy } from "react";
import Loadable from "../../components/Loadable";

const Dashboard = Loadable(lazy(() => import("./pages/Dashboard/Main")));
const Messages = Loadable(lazy(() => import("./pages/Messages/Main")));

const routes = (module) => {
	return [
		{
			variant: "dashboard",
			path: "/dashboard",
			element: <Dashboard />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			},
		},
		{
			variant: "dashboard",
			path: "/messages",
			element: <Messages />,
			private: true,
			authorize: ({ hasRole, hasPermission }) => {
				return hasRole("owner");
			},
		},
	];
};

export default routes;
