import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";


const useDialog = () => {

    const theme = useTheme();

    const [isOpen, setIsOpen] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [data, setData] = useState(null);

    const open = (data) => setData(data);
    const close = () => setIsOpen(false);

    useEffect(() => {
        if (!isOpen) {
            const _timer = setTimeout(
                () => {
                    setData(null);
                    setIsOpening(false);
                }, theme.transitions.duration.leavingScreen
            );

            return () => clearTimeout(_timer);
        }
    }, [isOpen]);

    useEffect(() => {
        if (data !== null) {
            setIsOpening(true);
            const _timer = setTimeout(
                () => {
                    setIsOpen(true);
                }, theme.transitions.duration.enteringScreen
            );

            return () => clearTimeout(_timer);
        }
    }, [data]);

    return {
        isOpen,
        isOpening,
        open,
        close,
        data
    }
}

export default useDialog