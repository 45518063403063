import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import NumberTextField from "../../../components/NumberTextField";
import ResponsiveDialog from "../../../components/ResponsiveDialog";


const DiscountFormDialog = ({
    open,
    onClose,
    defaultValues = {},
    onSubmit
}) => {

    const initialValues = {
        type: 'price',
        amount: '',
        pricings: [],
        ...defaultValues
    }

    const [values, setValues] = useState(initialValues);
    useEffect(() => {
        setValues(initialValues);
    }, [defaultValues])


    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs"
            }}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>تخیف</Box>
                <IconButton onClick={onClose} size="small">
                    <ArrowBack />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 0 }}>
                <Tabs
                    variant="fullWidth"
                    value={values.type}
                    onChange={(e, value) => setValues({ ...values, type: value })}
                >
                    <Tab label="مبلغ" value="price" />
                    <Tab label="درصد" value="percent" />
                </Tabs>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <NumberTextField
                        fullWidth={true}
                        variant="filled"
                        label={values.type === "price" ? "مبلغ تخفیف" : "درصد تخفیف"}
                        dir="ltr"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><small>{values.type === "price" ? "ریال" : "درصد"}</small></InputAdornment>,
                            inputProps: values.type === "percent" ? {
                                min: 1,
                                max: 100
                            } : {},
                            autoFocus: true
                        }}
                        value={values.amount}
                        onChange={e => setValues({ ...values, amount: e.target.value })}
                    />
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                {onSubmit && (
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => onSubmit(values)}
                    >
                        تایید و بستن
                    </Button>
                )}
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button type="button" onClick={onClose}>بستن</Button>
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default DiscountFormDialog;