import has from "lodash/has";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useLocationHash from "./useLocationHash";


const useDialogProps = (hash = undefined, options = { defaultProps: {}, hashDeps: [] }) => {

    const { defaultProps, hashDeps } = options;

    const location = useLocation();
    const navigate = useNavigate();
    const { hasSegment, addSegment, removeSegment } = useLocationHash();

    const [dialogProps, setDialogProps] = useState({ ...(!hash && { open: false }), ...defaultProps });

    const open = (props = {}) => {
        setDialogProps({ ...dialogProps, ...(!hash && { open: true }), ...props })
        hash && navigate({ ...location, hash: addSegment(hash) }, { state: location.state });
    };
    const close = () => {
        setDialogProps({ ...defaultProps, ...(!hash && { open: false }) })
        hash && navigate({ ...location, hash: removeSegment(hash) }, { replace: true, state: location.state });
    };
    const props = {
        ...dialogProps,
        ...(hash && { open: hasSegment(hash) }),
        onClose: close
    }

    useEffect(() => {
        if (hash && hashDeps && hashDeps.length > 0) {
            if (hasSegment(hash) && !has(dialogProps, hashDeps)) {
                navigate({ ...location, hash: removeSegment(hash) }, { replace: true, state: location.state });
            }
        }
    }, [location]);


    return [props, open, close];
}

export default useDialogProps;