import Middleware from "../../../foundation/Middleware";

class MainMiddleware extends Middleware {
	/**
	 * The middleware name
	 *
	 * @var string
	 */
	name = "Main";

	/**
	 * The middleware name
	 *
	 * @var string
	 */
	basePath = "/";

	/**
	 * The middleware paths
	 *
	 * @var Object
	 */
	paths = {
		//
	};

	//
}

export default MainMiddleware;
